import React from 'react';
import autobind from 'react-autobind';
import ApButton from 'common/ApButton/ApButton.js';
import { tr, errorPopper } from 'services/Helpers/Helpers.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import AssignmentsTable from './AssignmentsTable';
import AssignmentRequestsTable from './AssignmentRequestsTable';
import api from 'services/Api/Api.js';

class Assignments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            statusOptions: [{ value: "", label: tr("all") }],

            selectedTab: 0,

            assignmentStatuses: [],

            waitingApproval: [],
            draft: [],
            active: [],
            closed: [],
            all: [],
        }

        autobind(this);
    }

    componentDidMount() {
        if (sessionStorage.getItem('assignment-selected-tab') && !isNaN(sessionStorage.getItem('assignment-selected-tab'))) {
            this.setState({selectedTab: Number(sessionStorage.getItem('assignment-selected-tab'))})
        }
        this.setState({loading:true});

        api({
            method: 'get',
            url: 'extranet/assignments/get/all',
        }).then(response => {
            // console.log('RES', response);
            let statusOptions = [{ value: "", label: tr("all") }];
            for (const key in response.assignmentStatuses) {
                const element = response.assignmentStatuses[key];
                statusOptions.push({label: tr(element), value: key})
            }
            
            this.setState({
                loading: false, 
                waitingApproval: response.assignmentRequests || [],
                assignmentStatuses: response.assignmentStatuses,
                statusOptions
            });
            this.handleAssignments(response.assignments);
        }).catch(err => {
            this.setState({loading:false});
            console.error(err);
            errorPopper(err, tr('get_error'));
        })
    }

    handleAssignments(data) {
        let draft = [];
        let active = [];
        let closed = [];
        let all = data;

        data.forEach(item => {
            if (item.status_id < 3) {
                draft.push(item);
            }
            else if (item.status_id > 7) {
                closed.push(item);
            }
            else {
                active.push(item);
            }
        });

        this.setState({
            draft,
            active,
            closed,
            all
        })
    }

    handleTabChange(val) {
        this.setState({selectedTab: val})
        sessionStorage.setItem('assignment-selected-tab', val);
    }

    handleBadge(key) {
        const list = this.state[key];
        if (!Array.isArray(list) || list.length === 0) {
            return false;
        }
        if (key === 'waitingApproval') {
            const count = list.filter(item => item.status === 'pending').length;
            return count > 0 ? count : false;
        }
        return list.length > 0 ? list.length : false;
    }

    render() {
        return (
            <div id='assignmentRequests' className='apBox'>
                <div className='apBoxHeader'>
                    <h1>{tr('assignments')}</h1>
                    <p>{tr('assignment_requests_info')}</p>
                    <div className='apBoxCorner'>
                        <ApButton onClick={() => this.props.history.push("/assignment-requests/new/assignment")} color='green'>
                            {tr('new_ext_assignment_request')}
                        </ApButton>
                    </div>
                </div>
                <ApTabs selected={this.state.selectedTab} onChange={this.handleTabChange}>
                    <ApTab 
                        icon='hourglass-half' 
                        label={tr('waiting_for_approval1')} 
                        mountOnSelect
                        badge={ this.handleBadge('waitingApproval') }
                        >
                        <AssignmentRequestsTable history={this.props.history} data={this.state.waitingApproval} selectedTab={this.state.selectedTab} />
                    </ApTab>

                    <ApTab
                        icon='calculator'
                        label={tr('drafts')}
                        mountOnSelect
                        badge={ this.handleBadge('draft') }
                    >
                        <AssignmentsTable 
                            statusOptions={this.state.statusOptions}
                            history={this.props.history}
                            data={this.state.draft}
                            selectedTab={this.state.selectedTab} />
                    </ApTab>

                    <ApTab 
                        icon='folder' 
                        label={tr('active_count')} 
                        mountOnSelect
                        badge={ this.handleBadge('active') }
                    >
                        <AssignmentsTable 
                            statusOptions={this.state.statusOptions}
                            history={this.props.history}
                            data={this.state.active}
                            selectedTab={this.state.selectedTab} />
                    </ApTab>

                    <ApTab icon='folder-minus' label={tr('closed_count')} mountOnSelect>
                        <AssignmentsTable 
                            statusOptions={this.state.statusOptions}
                            history={this.props.history}
                            data={this.state.closed}
                            selectedTab={this.state.selectedTab} />
                    </ApTab>

                    <ApTab icon='archive' label={tr('all')} mountOnSelect>
                        <AssignmentsTable 
                            statusOptions={this.state.statusOptions}
                            history={this.props.history}
                            data={this.state.all}
                            selectedTab={this.state.selectedTab} />
                    </ApTab>
                </ApTabs>
            </div>
        )
    }
}

export default Assignments;