/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';

import './ApModalInput.css';

import ApModal from 'common/ApModal/ApModal.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput } from 'common/ApInput/ApInput.js';

import { validateDate } from 'services/Helpers/Helpers.js';
/*
 *  Basic example:
 *  
 *    <ApModalInput 
 *        show={ this.state.showing }
 *        value={ this.state.valueNow }
 *        onSave={ ( value ) => this.saveValue( value ) }
 *        onCancel={ () => console.log('no change') }
 *        onClose={ () => this.setState({ showing: false }) }
 *        title="Popup title here"
 *        label="Input label here"
 *        info="Info text here (will be shown bellow input field)"
 *        required
 *    />
 *
 *  Available props:
 *    show:     is popup modal showing (boolean)
 *    value:    default input value when popup is opened (string)
 *    onSave:   function that triggers when save is clicked (or enter key pressed). 
 *    onCancel: function that triggers when cancel is clicked (or esc key pressed or clicked outside the popup). 
 *    onClose:  function that triggers when popup closes (anyway after onSave or onCancel)
 *    title:    if available, shows title text at the top of the popup box
 *    label:    if available, shows label text inside input element
 *    info:     if available, shows info text bellow input element (with info icon)
 *    required: if true, save button (and function) is disabled when input is empty
 *
 *
 *  If you need multiple inputs, you can pass array to value prop. Then onSave function
 *  will return array of values (in same order). Remember that also label and
 *  required props should to be arrays if used!
 *
 *  Multi input example: 
 *
 *     <ApModalInput 
 *        show={ this.state.showing }
 *        value={[ 
 *            "Value A",
 *            "Value B",
 *            "Value C",
 *        ]}
 *        label={[ 
 *            "First (*)",
 *            "Second",
 *            "Third",
 *        ]}
 *        required={[
 *            true,
 *            false,
 *            false,
 *        ]}
 *        onSave={ ( values ) => this.save( values[0], values[1], values[2] ) }
 *        onClose={ () => this.setState({ showing: false }) }
 *    />
 *
 */

class ApModalInput extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            value: ( this.props.value ? this.props.value : '' )
        };
        autoBind(this); 
    } 

    UNSAFE_componentWillReceiveProps( nextProps )
    {    
        // Each time popup opened    
        if( nextProps.show && !this.props.show )
        {
            this.setState({ value: ( nextProps.value ? nextProps.value : '' ) });

            if( this.input )
                this.input.select();
        }
    }

    close()
    {
        if( typeof( this.props.onCancel ) == "function" )
            this.props.onCancel();

        if( typeof( this.props.onClose ) == "function" )
            this.props.onClose();
    }
    
    save()
    {
        if( !this.isValid() )
            return false;

        if( typeof( this.props.onSave ) == "function" )
            this.props.onSave( this.state.value );

        if( typeof( this.props.onClose ) == "function" )
            this.props.onClose();
    }

    handleKeyUp( e )
    {
        if( e.key == 'Enter' )
            this.save();

        else if ( e.key == 'Escape' )
            this.close();
    }

    handleChange( newValue, index )
    {

        let value = newValue;

        if( Array.isArray( this.state.value ) )
        {
            value = this.state.value.slice();
            value[ index ] = newValue;
        }

        this.setState({ value });
    }

    validateDate( value, index )
    {
        if( Array.isArray( this.props.required ) )
        {
            if( this.props.required[ index ] )
                return validateDate( value );
        }
        else if( this.props.required )
        {
            return validateDate( value );
        }

        if( !value || value.length == 0 ) return true;
        return validateDate( value );
    }

    isValid()
    {
        const value = this.state.value;
        const type = this.props.type;

        if( Array.isArray( type ) )
        {
            for( let i = 0; i < type.length; i++ )
                if( type[i] === 'datetime' )
                    if( !this.validateDate( value[i], i ) )
                        return false;
        }
        else if( this.props.type === 'datetime' )
            return this.validateDate( value );

        if( !this.props.required )
            return true;

        if( Array.isArray( value ) )
        {
            for( let i = 0; i < value.length; i++ )
                if( Array.isArray( this.props.required ) && this.props.required[i] && value[i].length == 0 )
                    return false;
        }
        else if( !value )
        {
            return false;
        }

        return true;
    }

    renderInput( index )
    {
        const value = ( Array.isArray( this.state.value ) ? this.state.value[ index ] : this.state.value );
        const label = ( Array.isArray( this.props.label ) ? this.props.label[ index ] : this.props.label );
        const type  = ( Array.isArray( this.props.type )  ? this.props.type[ index ] : this.props.type );

        if( type === 'textarea' )
        {
            return <ApInput 
                key={ index }
                type="textarea"
                id="apModalInput" 
                name="apModalInput" 
                label={ label }
                value={ value }
                rows={ 10 }

                onChange={ (e) => this.handleChange( e.target.value, index ) }
                inputRef={ index == 0 ? node => this.input = node : undefined } 
            />
        }
        if( type === 'datetime' )
        {
            return <ApInput 
                key={ index }
                type="date" 
                id="apModalInput" 
                name="apModalInput" 
                label={ label }
                value={ value }
                onChange={ (e) => this.handleChange( e.target.value, index ) }
                onKeyUp={ this.handleKeyUp }
                inputRef={ index == 0 ? node => this.input = node : undefined } 
            />
        }

        return <ApInput 
            key={ index }
            type="text" 
            id="apModalInput" 
            name="apModalInput" 
            label={ label }
            value={ value }
            onChange={ (e) => this.handleChange( e.target.value, index ) }
            onKeyUp={ this.handleKeyUp }
            inputRef={ index == 0 ? node => this.input = node : undefined } 
        />
    }

    render()
    {
        let header = null;
        if( this.props.title )
        {
            header = <div className="header">
                <h4>{ this.props.title }</h4>
            </div>
        }

        const inputs = ( Array.isArray( this.props.value ) ? this.props.value : [ this.props.value ] );

        return (
            <ApModal
                className="apInputModal"
                show={ this.props.show }
                handleClose={ this.close }
                hideCross
                closeFromBg
                header={ header }
                body={ 
                    <div className="padding">
                        
                        { inputs.map( (input, index ) =>
                            this.renderInput( index )
                        )}

                        { this.props.info && 
                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                { this.props.info }
                            </div>
                        }

                    </div>
                }
                footer={
                    <div className="footer">

                        <ApButton onClick={ this.close }>
                            <SvgIcon icon="times" type="solid" />
                            Peruuta
                        </ApButton>

                        <ApButton color="green" onClick={ this.save } disabled={ !this.isValid() }>
                            <SvgIcon icon="check" type="solid" />
                            Jatka
                        </ApButton>

                    </div>
                }

            />
        );
    }
};


ApModalInput.propTypes = {
    show:       PropTypes.bool.isRequired,
    value:      PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]),
    label:      PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]),
    required:   PropTypes.oneOfType([ PropTypes.bool, PropTypes.array ]),
    title:      PropTypes.string,
    info:       PropTypes.string,
    type:       PropTypes.string,
    onClose:    PropTypes.func,
    onCancel:   PropTypes.func,
    onSave:     PropTypes.func,
};

export default ApModalInput;

