import React from 'react';

import {
    Alert,
    Collapse,
} from 'react-bootstrap';


/**
 * Used to display alerts at the top right corner
 *
 * Poppers can be displayed by calling using window.emitter with 'popper'
 * second argument should be object containing data related to popper
 *
 * Possible values for popper:
 *   content: text or html to be dislayed within a popper
 *   type:    bootstrap alert type one of the: ['success', 'warning', 'danger', 'info']
 *   time:    how long will be the alert be dislplayed in milliseconds, default: 2000
 *   style:   custom styles for the popper
 *
 *
 * Examples:
 *
 * window.emitter.emit('popper', {
 *     content: 'info',
 * });
 *
 * window.emitter.emit('popper', {
 *     content: <strong>Tallennettu</strong>,
 *     type: 'success',
 *     time: 200,
 *     style: {color: 'red'},
 * });
 *
 */
class Popper extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            poppers: [],
        }
        this.validTypes = ['success', 'warning', 'danger', 'info'];
        this.defaultStyle = {boxShadow: '0 0 1em gray'};
        this.dangerStyle = {boxShadow: '0 0 1em gray', backgroundColor: 'red', color: 'white'};
        this.warningStyle = {boxShadow: '0 0 1em gray', backgroundColor: 'orange', color: 'white'};
        this.infoStyle = {boxShadow: '0 0 1em gray', backgroundColor: 'blue', color: 'white'};
        this.successStyle = {boxShadow: '0 0 1em gray', backgroundColor: 'green', color: 'white'};

        this.addPopper = this.addPopper.bind(this);
        this.removePopper = this.removePopper.bind(this);
        this.removeActualPopper = this.removeActualPopper.bind(this);

        this.setPopperIndex = this.setPopperIndex.bind(this);
        this.setPopperType = this.setPopperType.bind(this);
        this.setPopperStyle = this.setPopperStyle.bind(this);
        this.setPopperTimer = this.setPopperTimer.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    UNSAFE_componentWillMount()
    {
        window.emitter.removeAllListeners('popper');
        window.emitter.addListener('popper', args => {
            let data = Object.assign({}, args);
            this.addPopper(data);
        });
    }

    addPopper(data = {})
    {
        data = this.setPopperIndex(data);
        data = this.setPopperType(data);
        data = this.setPopperStyle(data);
        data = this.setPopperTimer(data);
        data.open = true;

        let poppers = this.state.poppers.slice(0);

        poppers.push(data);
        this.setState({poppers});
    }

    // Collapses popper so it is hidden
    removePopper(id)
    {
        let poppers = this.state.poppers.slice(0);
        const removeIndex = poppers.findIndex(popper => {
            return popper.index === id;
        });

        if(poppers[removeIndex])
        {
            poppers[removeIndex].open = false;
            window.clearTimeout(poppers[removeIndex].timer);

            this.setState({poppers});
        }
    }

    // Popper elements are actually removed once all the popers are collapsed.
    // This prevents html elements from making some funky dance moves
    removeActualPopper(element)
    {
        const allHidden = this.state.poppers.every(popper => {
            return !popper.open;
        });
        if(allHidden)
        {
            this.setState({poppers: []});
        }
    }

    setPopperIndex(data)
    {
        const poppers = this.state.poppers;
        let index = 1;
        if(poppers.length > 0)
        {
            index = poppers[poppers.length-1].index + 1;
        }

        data.index = index;
        return data;
    }

    setPopperType(data)
    {
        if(!data.type)
        {
            data.type = 'info';
        }
        else if(!this.validTypes.includes(data.type))
        {
            console.error(`Popper type should be one of: ["${this.validTypes.join('", "')}"]`)
            data.type = 'info';
        }
        return data;
    }

    setPopperStyle(data)
    {
        if(data.type)
        {
            switch (data.type) {
                case 'danger':
                    data.style = this.dangerStyle;
                    break;
                case 'warning':
                    data.style = this.warningStyle;
                    break;
                case 'info':
                    data.style = this.infoStyle;
                    break;
                case 'success':
                    data.style = this.successStyle;
                    break;
                default:
                    data.style = this.dangerStyle;
                    break;
            }
        }
        else
        {
            data.style = this.defaultStyle;
        }
        return data;
    }

    setPopperTimer(data)
    {
        const duration = (data.time) ? data.time : 2000;
        data.timer = window.setTimeout(() => {
            this.removePopper(data.index);
        }, duration);
        return data;
    }


    handleClick(e)
    {
        const index = parseInt(e.currentTarget.getAttribute('data-index'),10);
        this.removePopper(index);
    }

    render()
    {
        return (
            <div style={{
                position: 'fixed',
                zIndex: 5001, // Above modals
                top: 52,
                right: 10,
            }}>
                {this.state.poppers.map((popper, index) => (
                    <Collapse  key={index} in={popper.open}  appear={true} onExited={this.removeActualPopper} data-index={popper.index}>
                        <div> {/*Removes jumpy collapse transition*/}
                            <Alert
                                data-index={popper.index}
                                onClick={this.handleClick}
                                bsStyle={popper.type}
                                style={popper.style}
                            >{popper.content}</Alert>
                        </div>
                    </Collapse>
                ))}
            </div>
        );
    }
}
export default Popper
