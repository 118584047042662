import React from 'react';
import autobind from 'react-autobind';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import { tr, capitalize } from 'services/Helpers/Helpers.js';
import moment from 'moment';

const statusOptions = [
    {value: '', label: tr('all')},
    {value: 'pending', label: tr('waiting_for_approval1')},
    {value: 'rejected', label: tr('rejected')},
    {value: 'approved', label: tr('approved')},
]

class AssignmentRequestsTable extends React.Component {
    constructor(props) {
        super(props);

        autobind(this);
    }

    showAssignment(assignment) {
        this.props.history.push(`/assignment-requests/${assignment.id}`);
    }

    renderStatus(status) {
        let statusText = '';
        switch (status) {
            case 'pending':
                statusText = tr('waiting_for_approval1');
                break;
            case 'approved':
                statusText = tr('approved');
                break;
            case 'rejected':
                statusText = tr('rejected');
                break;
            default:
                break;
        }
        return statusText;
    }

    render() {
        return(
            <div className='padding'>
                <ApReactTable
                    // rememberId={`extranet_assignmentsTable_${this.props.tabSelected}`}
                    loading={this.props.loading}
                    data={this.props.data}
                    columns={[
                        {
                            id: 'name',
                            Header: tr('header'),
                            accessor: 'assignment_name',
                            onClick: this.showAssignment,
                            // customizable: true,
                            showInitially: true,
                            customFilter: {
                                type: "text",
                                placeholder: tr('header'),
                            },
                        },
                        {
                            id: 'description',
                            Header: tr('description'),
                            accessor: 'assignment_description',
                            onClick: this.showAssignment,
                            customizable: true,
                            showInitially: true,
                            customFilter: {
                                type: "text",
                                placeholder: tr('description'),
                            },
                        },
                        {
                            id: 'begins',
                            Header: capitalize(tr('begins')),
                            accessor: item => item.assignment_begin_at ? moment(item.assignment_begin_at).format("L HH:mm") : null,
                            onClick: this.showAssignment,
                            customizable: true,
                            showInitially: true,
                            filterable: false,
                        },
                        {
                            id: 'ends',
                            Header: capitalize(tr('ends')),
                            accessor: item => item.assignment_end_at ? moment(item.assignment_end_at).format("L HH:mm") : null,
                            onClick: this.showAssignment,
                            customizable: true,
                            showInitially: true,
                            filterable: false,
                        },
                        {
                            id: 'status',
                            Header: tr('status'),
                            accessor: 'status',
                            onClick: this.showAssignment,
                            customizable: true,
                            showInitially: true,
                            Cell: props => this.renderStatus(props.value),
                            customFilter: {
                                type: "select",
                                options: statusOptions,
                            },
                        },
                    ]}
                    showFiltersInitially={true}
                    filterable
                    defaultFiltered={[{id: 'status', value: 'pending'}]}
                    minRows={10}
                    rowActions={ (row) => 
                        <div className="apSimpleButton" onClick={ () => console.log('Goto', row) }> <SvgIcon icon="arrow-right" type="solid" /> </div>
                    }
                />
            </div>
        )
    }
}

export default AssignmentRequestsTable;