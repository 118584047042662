import React from 'react';
import autoBind from 'react-autobind';

import './Help.css';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import VerticalScroller from 'common/VerticalScroller/VerticalScroller.js';

class Help extends React.Component {
	
    constructor( props ) {
		super(props);
        this.state = {
            location: null,
            pages: [
                { 
                    url: "GENERAL",
                    name: "Yleiset ohjeet",
                },
                { 
                    url: "/",
                    name: "Aloitussivu",
                },
                { 
                    url: "/foobar",
                    name: "TODO",
                },
            ]
        };
        autoBind(this); 
	}

    clickOutsideHandler(event) {
    	if( !this.node.contains(event.target) ) {
    		this.props.closeEvent();
    	}
    }

    changeLocation( url )
    {
        this.setState({ location: url });
    }

    clearLocation()
    {
        this.changeLocation( null );
    }

    getUrl()
    {
        return this.state.location ? this.state.location : this.props.url ? this.props.url : window.location.pathname;
    }

    renderPageHelp( url )
    {
        switch( url )
        {
            case "GENERAL":
                return (
                    <div className="padding">
                        <h3>Yleiset ohjeet</h3>
                        <p className="ingress">Tervetuloa käyttämään Easy-Pro-järjestelmää.</p>

                        <h5>Yläpalkki</h5>
                        <p>Yläpalkista löytyy seuraavat toiminnot:
                            <ul>
                                <li>
                                    <SvgIcon className="inline topbar" icon="user-circle" type="solid" />
                                    <strong>Oma profiili</strong> näyttää kirjautuneen henkilön perustiedot. Salasanan vaihtaminen ja ulos kirjautuminen löytyvät myös oman profiilin alta.   
                                </li>
                                <li>
                                    <SvgIcon className="inline topbar" icon="question-circle" />
                                    <strong>Ohje</strong>-sivu tarjoaa vinkkejä ja opastusta järjestelmän käyttöön.  
                                </li>
                            </ul>
                        </p>

                        <h5>Sivupalkki</h5>
                        <p>Vasemmassa reunassa olevasta sivupalkista pääset siirtymään eri osioiden välillä. Sinulle näkyy vain ne osiot joihin sinulla on käyttöoikeus. </p>
                    
                        <p>
                            <SvgIcon className="inline" icon="search" type="solid" />
                            <strong>Haku</strong>-ominaisuudella voit hakea projekteja, yrityksiä ja henkilöitä järjestelmän sisältä. 
                        </p>

                        <p>
                            <SvgIcon className="inline" icon="angle-double-left" type="solid" />
                            <strong>Tuplanuolilla</strong> voit tarvittaessa pienentää sivupalkin, jolloin saat enemmän tilaa itse sisällölle. Vastaavasti tuplanuolilla voit myös laajentaa sivupalkin, kun se on pienennettynä. 
                        </p>

                        <h5>Yleisiä kuvakkeita</h5>

                        <p>
                            Järjestelmässä usein käytössä olevia kuvakkeita:
                            <ul>
                                <li>
                                    <SvgIcon className="inline noBg" icon="info-circle" type="solid" />
                                    <strong>Lisätieto</strong>-kuvake antaa lisää tietoa tai ilmaisee, että lisää tietoa on saatavilla.
                                </li>
                                <li>
                                    <SvgIcon className="inline noBg warning" icon="exclamation-circle" type="solid" />
                                    <strong>Huomio</strong>-kuvake ilmaisee, että käyttäjän tulee huomioida jotain. Esimerkiksi jos jokin tehty muutos ei vielä ole tallennettu. 
                                </li>

                                <li>
                                    <SvgIcon className="inline" icon="ellipsis-h" type="solid" />
                                    <strong>Lisää</strong>-kuvake antaa lisää vaihtoehtoja tai toimintoja.
                                </li>

                                <li>
                                    <SvgIcon className="inline" icon="filter" type="solid" />
                                    <strong>Suodata</strong>-kuvake mahdollistaa tiedon rajaamisen tietyin hakuehdoin.
                                </li>
                            </ul>
                        </p>

                    </div>
                );
                break;

            case "/":
                return (
                    <div className="padding">
                        <h3>Aloitussivu</h3>
                        <p className="ingress">Aloitussivu kokoaa sinulle yhteenvedon käytössäsi olevista osioista ja näet tilastotietoja järjestelmästä.</p>

                        {/*
                        <p>Voit itse määrittää mitä tilastoja haluat nähdä ja missä järjestyksessä. <SvgIcon className="inline" icon="th-list" type="solid" />-kuvakkeesta pääset valitsemaan mitkä osiot haluat nähdä aloitussivulla. </p>

                        <h5>Yhteenvedot</h5>

                        <p>
                            <SvgIcon className="inline" icon="arrow-right" type="solid" />
                            <strong>Nuolella</strong> pääset siirtymään kyseiseen osioon ja saat tarkemmat tiedot kohteesta.  
                        </p>
                        */}
                    </div>
                );
                break;

            default:
                return (
                    <div className="padding">
                        <h3>Tulossa pian</h3>
                        <p>Valitettavasti tämän sivun ohje ei vielä ole saatavilla.</p>
                    </div>
                );
                break;
        }
    }

    render()
    {
        const url = this.getUrl();
        const currentUrl = this.props.url || window.location.pathname;
        const page = this.state.pages.find( p => p.url == currentUrl );

        return (
        	<div id="help" className={ (this.props.open ? "open" : "" ) } onClick={this.clickOutsideHandler}>
        		<div ref={node => this.node = node} className="help">
        			<div className="top">
        				<h3 className="title">Ohjeet ja vinkit</h3>
        				<div className="close" onClick={ this.props.closeEvent }>
        					<SvgIcon type="solid" icon="times" />
        				</div>
        			</div>

        			<VerticalScroller offsetTop={50} srcollShadows hideScrollbar>
                        <div className="navigation">
                            <div className={ "section" + ( this.state.location == "GENERAL" ? " selected" : "" ) } onClick={ () => this.changeLocation( "GENERAL" ) }>
                                <SvgIcon icon="globe" type="solid" />
                                <div className="text">
                                    Yleiset ohjeet
                                </div>
                            </div>
                            <div className={ "section" + ( this.state.location != "GENERAL" ? " selected" : "" ) } onClick={ this.clearLocation }>
                                <SvgIcon icon="compass" type="solid" />
                                <div className="text">
                                    { page ? page.name : currentUrl }<br />
                                    <small>Sivukohtainen ohje</small>
                                </div>
                            </div>

                        </div>
        				{ this.renderPageHelp( url ) }
        			</VerticalScroller>
        		</div>
        	</div>	
        );
    }
};


export default Help;
