import React from 'react';
import autoBind from 'react-autobind';
import './UserMenu.css';
import auth from 'services/Authed/Authed.js';
import ApUserImage from 'common/ApUserImage/ApUserImage.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';

class UserMenu extends React.Component {

    constructor( props ) 
    {
        super( props );
        this.state = {
            changePasswordShowing: false,
        }
        autoBind(this); 
    }

    logout()
    {
        auth.logout();
    }

    saveNewPassword( values )
    {
        console.log( 'save new password', values );
    }

    render()
    {
        const user = auth.getUser();
        
        return (
            <div className="block user">
                <div className="button" onClick={() => { this.props.toggleModal("user"); } }>
                    <span className="text">{ user.name }</span>      
                    <SvgIcon type="solid" icon="user-circle" />
                </div>
                <div className={"topModal" + (this.props.open ? " open" : "" ) }>
                    <div className="padding-small">
                    
                        <div className="profile">

                            <ApUserImage user={ user } block withShadow size="huge" style={{ borderColor: "#fff", marginBottom: "10px" }} />
                            <div className="name">{ user.name }</div>
                            { user.title && <div className="title">{ user.title }</div> }
                        
                        </div>

                        {/*
                        <button 
                            className="apButton small full-width"
                            onClick={ () => window.emitter.emit('goTo', {pathname: '/user/changepassword'}) }
                        >
                            <SvgIcon icon="key" type="solid" />
                            Vaihda salasana
                        </button>
                        */}

                        <button 
                            className="apButton small full-width no-margin"
                            onClick={ () => window.emitter.emit('goTo', {pathname: '/user'}) }
                        >
                            <SvgIcon icon="user" type="solid" />
                            Omat sivut
                        </button>

                        <hr />

                        <button className="apButton small full-width red no-margin" onClick={ this.logout }>
                            <SvgIcon icon="lock" type="solid" />
                            Kirjaudu ulos
                        </button>
                    </div>

                </div>

            </div>
        );
    }
};


export default UserMenu;

