import React from 'react';
import PropTypes from 'prop-types';

import './ApSwitch.css';

class ApSwitch extends React.Component {

    constructor( props )
    {
        super( props );
        this.changeHandler = this.changeHandler.bind(this);
    }

    changeHandler(e) {
    	if( typeof( this.props.onChange ) === "function" ) {
    		this.props.onChange(e);
    	};
    }

    render()
    {

    	let classNames = ["apSwitch"];
        let customBgColor = {};

    	if( this.props.on ) classNames.push( "on");
    	if( this.props.disabled ) classNames.push("disabled");
        if( this.props.small ) classNames.push("small");
    
        if( this.props.colorOn && this.props.on ) customBgColor = { background: this.props.colorOn };
        if( this.props.colorOff && !this.props.on ) customBgColor = { background: this.props.colorOff };

        return (
            <div className={ "apSwitchWrapper" + ( this.props.inline ? " inline" : "" ) }>
                <input 
                    type="checkbox" 
                    checked={ this.props.on ? true : false }
                    name={this.props.name} 
                    id={this.props.id} 
                    disabled={this.props.disabled}
                    onChange={this.changeHandler}
                    tabIndex={this.props.tabIndex}
                />
                <label htmlFor={this.props.id} className={ classNames.join(" ")} style={customBgColor} >
                    { this.props.labels && <div className="apSwitchLabel on">On</div> }
                    { this.props.labels && <div className="apSwitchLabel off">Off</div> }
                    <div className="apSwitchHandler"></div>
                </label>
            </div>
        );
    }
};

ApSwitch.propTypes = {
    name:       PropTypes.string,
    id:         PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
    on:         PropTypes.bool,
    disabled:   PropTypes.bool,
	onChange:   PropTypes.func,
    labels:     PropTypes.bool,
    colorOn:    PropTypes.string,
    colorOff:   PropTypes.string,
    small:      PropTypes.bool,
    inline:     PropTypes.bool
};

export default ApSwitch;

