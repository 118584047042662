import React from 'react';
import autoBind from 'react-autobind';
import { throttle } from 'throttle-debounce';

import auth from   'services/Authed/Authed.js';
import config from 'env.js';


import './IdleScreen.css';

class IdleScreen extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            warningShowing: false,
            secsRemaining: null,
        }
        autoBind(this); 

        // How many seconds user can be idle before automatic logout 
        this.logoutTimeout = config.api.idleAutoLogoutTime ? config.api.idleAutoLogoutTime : 900;

        // How many seconds user is shown warning popup before logout 
        this.warningTime = config.api.idleWarningTime ? config.api.idleWarningTime : 30;


        this.timeout = false;
        this.interval = false;

        this.resetThrottled = throttle( 500, this.reset );
    }

    componentDidMount() {
        document.addEventListener('mousemove', this.resetThrottled);
        document.addEventListener('keydown', this.resetThrottled);
        document.addEventListener('wheel', this.resetThrottled);
        document.addEventListener('DOMMouseScroll', this.resetThrottled);
        document.addEventListener('mouseWheel', this.resetThrottled);
        document.addEventListener('mousedown', this.resetThrottled);
        document.addEventListener('touchstart', this.resetThrottled);
        document.addEventListener('touchmove', this.resetThrottled);
        document.addEventListener('MSPointerDown', this.resetThrottled);
        document.addEventListener('MSPointerMove', this.resetThrottled);

        // Each time window (or tab) gets focus back we check that login 
        // have NOT been expired while window (or tab) have been inactive
        window.addEventListener('focus', this.checkLoginToken );

        this.reset();
    }
    componentWillUnmount() {
        document.removeEventListener('mousemove', this.resetThrottled);
        document.removeEventListener('keydown', this.resetThrottled);
        document.removeEventListener('wheel', this.resetThrottled);
        document.removeEventListener('DOMMouseScroll', this.resetThrottled);
        document.removeEventListener('mouseWheel', this.resetThrottled);
        document.removeEventListener('mousedown', this.resetThrottled);
        document.removeEventListener('touchstart', this.resetThrottled);
        document.removeEventListener('touchmove', this.resetThrottled);
        document.removeEventListener('MSPointerDown', this.resetThrottled);
        document.removeEventListener('MSPointerMove', this.resetThrottled);

        window.removeEventListener('focus', this.checkLoginToken );
    }

    checkLoginToken()
    {
        const tokenData = auth.getTokenData();

        if( !tokenData || ( tokenData.exp * 1000 ) < Date.now() )
            auth.logout();
    }

    reset()
    {
        if( this.state.warningShowing )
            this.setState({ warningShowing: false });

        const delay = (this.logoutTimeout - this.warningTime) * 1000;
        
        clearTimeout( this.timeout );
        clearInterval( this.interval );
        this.timeout = setTimeout( this.warning, delay );
    }

    warning()
    {
        this.setState({ 
            warningShowing: true,
            secsRemaining: this.warningTime,
        });

        clearTimeout( this.timeout );
        this.timeout = setTimeout( this.idle, ( this.warningTime * 1000 ) );

        clearInterval( this.interval );
        this.interval = setInterval( this.countdown, 1000 );
    }

    countdown()
    {
        let secsRemaining = this.state.secsRemaining - 1;
        this.setState({ secsRemaining });

        if( secsRemaining <= 0 )
            clearInterval( this.interval );
    }

    idle()
    {
        // Prevent possible page leave confirmation
        window.onbeforeunload = undefined;

        auth.logout();
        window.location.reload();
    }

    render()
    {
        const remain = this.state.secsRemaining ? this.state.secsRemaining + " sekunnin" : "hetken";

        return (
            <div className={"idleScreen" + ( this.state.warningShowing ? " showWarning" : "" ) }>
                <div className="message">
                    <h2>Edelleen paikalla? </h2>
                    <p>Ohjelma on ollut pitkään käyttämättömänä ja tietoturvasyistä johtuen kirjautuu automaattisesti ulos { remain } kuluttua. </p>
                    <p><strong>Voit jatkaa käyttöä, niin pysyt kirjautuneena. </strong></p>
                </div>
            </div>
        );
    }
};

export default IdleScreen;

