/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import api from 'services/Api/Api.js';
import Highlighter from "react-highlight-words";
//import matchSorter from 'match-sorter';

import auth from 'services/Authed/Authed.js';
import nextId           from 'services/NextId/NextId.js';
import SvgIcon          from 'common/SvgIcon/SvgIcon.js';
import { ApInput }      from 'common/ApInput/ApInput.js';
import ApPath           from 'common/ApPath/ApPath.js';
import ApUserImage      from 'common/ApUserImage/ApUserImage.js';

import { truncate
       , formatMoney
       , sqlToDateInput }  from 'services/Helpers/Helpers.js';

/*
import { orderStatusBoxColor
       , getTypeName
       , getTypeIcon }  from 'modules/Storage/common/StorageHelpers.js';
*/

import './ApSelect.css';

import { Collapse }     from 'react-bootstrap';

class ApSelect extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            focused: false,

            results: null,
            resultsShowing: false,
            resultActive: false,
            
            inputValue: '',

            currencySign: '€' //auth.getCurrencySign(),
        }
        autoBind(this);

        this.inputId = nextId('ApSelect');

        // Default debounce rate is 200ms
        this.getResultsDebounced = debounce( typeof( this.props.debounce ) == "number" ? this.props.debounce : 200 , this.getResults );
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.clickOutsideHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.clickOutsideHandler);
    }

    clickOutsideHandler(event) {

        if( this.parent && this.state.show )
            if( !this.parent.contains( event.target ) )
                this.closeResults();
    
    }

    getResults()
    {
        if( this.props.readOnly )
            return false;
        
        if( this.props.apiUrl )
        {
            let apiData = this.props.apiData ? this.props.apiData : {};
            apiData.search = this.state.inputValue;

            this.setState({ loading: true });
            api({
                method: 'post',
                url: this.props.apiUrl,
                data: apiData,
            }).then(( response ) => {
                this.setState({ 
                    loading: false,
                    results: response,
                    resultActive: response.length > 0 ? 0 : false,
                });
            }).catch((error) => {
                console.error( 'ERROR: ', error );
                this.setState({ loading: false });
            });
        }
        else if ( this.props.options ) {

            let results = [];

            if( typeof( this.props.options[0] ) == "string" )
            {
                results = this.props.options //matchSorter( this.props.options, this.state.inputValue );
            }
            else if ( this.props.objKeySearchable )
            {
                const keys = Array.isArray( this.props.objKeySearchable ) ? this.props.objKeySearchable : [ this.props.objKeySearchable ];
                results = this.props.options // matchSorter( this.props.options, this.state.inputValue, { keys: keys } );
            }
            this.setState({
                results: results,
                resultActive: results.length > 0 ? 0 : false,
            })

        }
    }



    isItemSelected( item, returnIndex = false )
    {
        const selected = this.props.value;
        const key = this.props.objKeyId ? this.props.objKeyId : false;

        if( item && selected )
        {
            if( key )
            {
                if( !item.hasOwnProperty( key ) )
                {
                    console.error( 'Key (' + key + ') does not exists!' );
                    return false;
                }

                if( Array.isArray( selected ) )
                {
                    for( let i = 0; i < selected.length; i++ )
                        if( selected[ i ][ key ] == item[ key ] )
                            return returnIndex ? i : true;
                }
                else if ( selected[ key ] == item[ key ] ) {
                    return returnIndex ? 1 : true;
                }
            }
            else if( typeof( item ) == "string" ) {
                return returnIndex ? selected.indexOf( item ) : ( selected.indexOf( item ) != -1 );
            }
        }
        return returnIndex ? -1 : false;
    }
    
    onChange( e )
    {
        this.setState({ inputValue: e.target.value }, this.getResultsDebounced );
    }

    onKeyUp( e )
    {
        if( this.state.resultsShowing )
        {
            if( e.key == "Enter" )
                this.onSelect();
            
            else if ( e.key == "Escape" )
                this.closeResults();   
            
            else if ( e.key == "ArrowUp" || e.key == "ArrowDown" )
            {
                const max = this.state.results.length;
                let resultActive = typeof( this.state.resultActive ) == "number" ? this.state.resultActive : -1;

                resultActive += ( e.key == "ArrowUp" ? -1 : 1 );

                if( resultActive < 0 )
                    resultActive = max - 1;

                if( resultActive >= max )
                    resultActive = 0;
                
                this.setState({ resultActive }, this.checkResultScrollPosition )
            }
        }
        else if ( e.key == "ArrowDown" )
        {
            this.setState({ resultsShowing: true });
        }
    }

    onFocus()
    {
        this.setState({ 
            focused: true,
            resultsShowing: true,
        }, this.getResults );
    }

    onBlur()
    {
        this.setState({ 
            focused: false,
        });
        this.closeResults();
    }

    // Scroll list so the selected item is allways visible (list have max-height and overflow scroll in CSS)
    checkResultScrollPosition()
    {
        if( this.activeResultRef && this.resultsRef )
        {
            const viewTop = this.resultsRef.scrollTop;
            const viewBottom = this.resultsRef.scrollTop + this.resultsRef.clientHeight;
            const itemTop = this.activeResultRef.offsetTop; 
            const itemBottom = this.activeResultRef.offsetTop + this.activeResultRef.clientHeight; 

            // Item is outside view from top
            if( itemTop < viewTop )
                this.resultsRef.scrollTop = itemTop;

            // Item is outside view from bottom
            if( itemBottom > viewBottom )
                this.resultsRef.scrollTop = itemBottom - this.resultsRef.clientHeight;
        }
    }

    closeResults()
    {   
        this.setState({ resultsShowing: false });
    }

   
    
    onSelect( item = false ) 
    {   
        // Use keyboard selected item
        if( !item && this.state.results && typeof( this.state.resultActive ) == "number" )
            item = this.state.results[ this.state.resultActive ];
        
        if( !item )
            return false;

        let value = item;

        if( this.props.multiselect && Array.isArray( this.props.value ) )
        {
            value = this.props.value.slice();
            const index = this.isItemSelected( item, true );
            if( index == -1 )
                value.push( item );
            else 
                value.splice( index, 1 );
        }
    
        if( typeof( this.props.onChange ) == "function" )
            this.props.onChange( value );


        if( this.inputRef )
        { 
            if( this.props.multiselect )
            {
                if( this.state.inputValue != "" )
                    this.setState({ inputValue: '' }, this.getResults );

                this.inputRef.focus();
            }
            else 
            {
                this.inputRef.blur();
            }
        }
    }

    handleClear()
    {
        this.setState({
            resultsShowing: false,
            inputValue: ''
        });

        if( typeof( this.props.onChange ) == "function" )
            this.props.onChange( this.props.multiselect ? [] : "" );
    }

    removeSelectedItem( index )
    {
        let value = this.props.value;
        value.splice( index, 1 );

        if( typeof( this.props.onChange ) == "function" )
            this.props.onChange( value );
    }

    valueRendererPreset( item, preset )
    {
        if( preset == "label" )
        {
            return <div>
                { item.label ? item.label : "Nimetön" }
            </div>
        }
        else if( preset == "user" )
        {
            return (
                <div className={ "presetUser " + ( item.is_active ? "active" : "inactive" ) }>
                    <ApUserImage className="image" user={ item } size="tiny" />
                    <span>{ item.name }</span>
                </div>
            );
        }
        else if ( preset == "personlist" )
        {
            return (
                <div>
                    {item.full_name}
                </div>
            );
        }
        else if ( preset == "usergroup" )
        {
            return (
                <div className="presetUsergroup">
                    <SvgIcon className="small-inline" icon="users" type="solid" />
                    { item.name ? item.name : "Nimetön" }
                    <span className="modules">+{ item.modules.length }</span>
                </div>
            );
        }
        else if ( preset == "project" )
        {
            return (
                <div className="presetProject">
                    <strong>{ item.project_code }</strong><br />
                    <small>{ this.renderProjectName( item ) }</small>
                </div>
            );
        }
        else if ( preset == "project_works" )
        {
            return (
                <div className="presetProjectWork">{ item.name ? item.name : "Nimetön" }</div>
            );
        }
        else if ( preset == "component" )
        {
            let className = ['presetComponent'];
            if( item.status_name )
                className.push( item.status_name );

            return (
                <div className={ className.join(' ') }>
                    { item.type_name && <div className="icon"><SvgIcon icon={ /* getTypeIcon(*/ item.type_name /*)*/ } type="solid" /></div> }
                    <div>{ item.name ? item.name : "Nimetön" }</div>
                    <div className="code">{ item.code } &nbsp;</div>
                </div>
            );
        }

        return <span>Invalid prop ({ preset })</span>;
    }

    renderProjectName( project )
    {
        let path = [];
        if( project.ancestors && project.ancestors.length > 0 )
            project.ancestors.forEach( a => path.push( a.name ) );
        path.push( project.name );

        return <ApPath items={ path } />

        /*
        let name = project.name;
        let mainProject = false;
        let separator = "";


        if( project.ancestors && project.ancestors.length > 0 )
        {
            mainProject = project.ancestors[0].name;
            separator = ( project.ancestors.length > 1 ? " … " : " / " );
        }
        return <span>{ mainProject && <span className="main">{ mainProject }</span> }{ separator }{ name }</span>;
        */      
    }

    renderProjectCode( project )
    {
        let path = [];
        if( project.ancestors && project.ancestors.length > 0 )
            project.ancestors.forEach( a => path.push( a.code ) );
        path.push( project.code );

        return <ApPath items={ path } />     
    }

    getProjectCustomer( project )
    {
        let customer = false;

        if( project.customer )
            customer = project.customer.name ? project.customer.name : "Tuntematon";

        if( project.ancestors && project.ancestors.length > 0 )
            if( project.ancestors[0].customer )
                customer = project.ancestors[0].customer.name ? project.ancestors[0].customer.name : "Tuntematon";

        return customer ? <span><SvgIcon className="small-inline" icon="building" type="solid" />{ customer }</span> : "";
    }


    optionRendererPreset( item, preset )
    {
        if( preset == "label" )
        {
            return <div>
                { item.label ? item.label : "Nimetön" }
            </div>
        }
        else if( preset == "user" )
        {   
            return (
                <div className={ "presetUser " + ( item.is_active ? "active" : "inactive" ) }>
                    <ApUserImage className="image" user={ item } size="small" />
                    <strong>{ item.name }</strong><br />
                    <small>{ item.title ? item.title : "Ei toimenkuvaa" }</small>
                    { !item.is_active && <div className="inactiveMsg">Ei aktiivisena</div> }
                </div>
            );
        }
        else if ( preset == "personlist" )
        {
            return (
                <div>
                    {item.full_name}
                </div>
            );
        }
        else if ( preset == "usergroup" )
        {
            return (
                <div className="presetUsergroup">
                    <SvgIcon className="icon" icon="users" type="solid" />
                    <strong>{ item.name }</strong><br />
                    <small>{ item.description ? item.description : "Ei kuvausta" }</small>
                    <div className="modules">+{ item.modules.length }</div>
                </div>
            );
        }
        else if ( preset == "project" )
        {
            return (
                <div className="presetProject">
                    <div>
                        <strong>{ item.project_code }</strong><br />
                        <small>{ this.renderProjectName( item ) }</small>
                    </div>
                    <div>
                        <span className="customer">
                            { this.getProjectCustomer( item ) }
                        </span>                        
                    </div>
                </div>
            );
        }
        else if ( preset == "project_po" ) {
            return (
                <div className="presetProject">
                    <div>
                    <strong>{ this.renderProjectCode( item ) }</strong><br />
                    <small>{ this.renderProjectName( item ) }</small>
                    </div>
                    <div>
                        <span className="customer">
                            { this.getProjectCustomer( item ) }
                        </span>                        
                    </div>
                </div>
            );
        }
        else if ( preset == "project_works" )
        {
            
            return (
                <div className="presetProjectWork">
                    <strong>{ item.project.project_code }</strong><br />
                    <small>{ this.renderProjectName( item.project ) }</small>
                    <span className="work">
                        <SvgIcon icon="user-clock" type="solid" />
                        { item.name ? item.name : item.component.name }
                    </span>
                </div>
            );
        }
        else if ( preset == "storage_location" )
        {
            return (
                <div className="presetStorageLocation">
                    <strong>{ item.code }</strong><br />
                    <small>{ item.name }</small><br />
                </div>
            );
        }
        else if ( preset == "storage_location_address" )
        {
            return (
                <div className="presetStorageLocation">
                    <strong>{ item.code }</strong><br />
                    <small>{ item.name }</small><br />

                    <span className="address">{ item.delivery_name }</span><br />
                    <span className="address">{ item.delivery_contact }</span><br />
                    <span className="address">{ item.delivery_address }</span><br />
                    <span className="address">{ item.delivery_zipcode }</span><br />
                    <span className="address">{ item.delivery_country }</span><br />
                </div>
            );
        }
        else if ( preset == "storage_supplier" )
        {
            return (
                <div className="presetStorageSupllier">
                    <strong>{ item.name }</strong>
                </div>
            );
        }

        else if ( preset == "component" )
        {
            return (
                <div className="presetComponent">
                    <strong>{ item.name }</strong><br />
                    <small>{ item.code }</small><br />
                    <span className="unit">{ item.unit }</span>
                </div>
            );
        }

        else if ( preset == "component_detail" )
        {
            return this.componentOptionRendererPreset( item, this.state.inputValue );
        }

        else if ( preset == "crm_company" )
        {
            return (
                <div className="presetCrmCompany">
                    <strong>{ item.name }</strong>
                </div>
            );
        }

        else if ( preset == "storage_order" )
        {
            /*
            return <div className="presetStorageOrder">
                <div className="createdContainer">
                    <span className="title">Tilattu:</span>
                    <span className="value">{ sqlToDateInput( item.date ) }</span>
                </div>
                <div className="status">
                    <div className={`apStatusBox ${ orderStatusBoxColor( item.status_name ) }`}> { item.status_title }</div>
                </div>
                <div className="numberContainer">
                    <span className="title">Numero:</span>
                    <span className="value">{ item.number }</span>
                </div>
                <div className="detailContainer">
                    <SvgIcon icon={ getTypeIcon( item.related_type ) } type="solid" />
                    <span className="title">Tilaaja:</span>
                    <span className="value">{ item.related_title }</span>
                </div>
                <div className="detailContainer">
                    <span className="title">Tilauksen tekijä:</span>
                    <span className="value">{ item.created_by }</span>
                </div>
                <div className="detailContainer">
                    <span className="title">Hinta:</span>
                    <span className="value bold">{ formatMoney( item.price, 2 ) } { this.state.currencySign }</span>
                </div>
                <div className="detailContainer">
                    <span className="title">Nimikkeitä:</span>
                    <span className="value">{ item.components_count }</span>
                </div>
            </div>
            */

            return <div className="presetStorageOrder">
                <div className="createdContainer">
                    <span className="title">Tilattu:</span>
                    <span className="value">{ sqlToDateInput( item.date ) }</span>
                </div>
                <div className="status">
                    <div className={`apStatusBox ${ /*orderStatusBoxColor(*/ item.status_name /*)*/ }`}> { item.status_title }</div>
                </div>
                <div className="typeContainer">
                    <span className="type">{ /*getTypeName(*/ 'order' /*) */}</span>
                    <span className="title">Numero:</span>
                    <span className="value">{ item.number }</span>
                </div>
                <table className="detailTable">
                    <tbody>
                        <tr>
                            <td className="title">Tilaaja</td>
                            <td className="value">
                                <SvgIcon icon={ /*getTypeIcon(*/ item.related_type/* )*/ } type="solid" />
                                { item.related_title }
                            </td>
                        </tr>

                        <tr>
                            <td className="title">Tilauksen tekijä</td>
                            <td className="value">{ item.created_by }</td>
                        </tr>

                        <tr>
                            <td className="title">Arvo</td>
                            <td className="value bold">
                                { formatMoney( item.price, 2 ) } { this.state.currencySign }
                            </td>
                        </tr>
                        <tr>
                            <td className="title">Rivejä</td>
                            <td className="value">{ item.components_count }</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        }


        return <span>Invalid prop ({ preset })</span>;
    }

    componentOptionRendererPreset( item, search )
    {
        const searches = search.toLowerCase().split( ' ' ).filter( s => s.length > 0 );

        let identifiers = [];
        if( !Array.isArray( item.identifiers ) )
            console.warn('identifiers not included, please note you also need to format them on backend');
        else
        {
            item.identifiers.forEach( ( i ) => {
                if ( typeof i.value !== 'string' || i.value.length === 0 )
                    return null;

                const found = searches.some( s => i.value.toLowerCase().includes( s.toLowerCase()) );
                if( found )
                {
                    identifiers.push(
                        <div className="sIdentifier" key={ i.id }>
                            <span className="name">{ i.name }:</span>
                            <span className="value">
                                <Highlighter
                                    searchWords={ searches }
                                    autoEscape={true}
                                    textToHighlight={ i.value ? i.value : '' }
                                />
                            </span>
                        </div>
                    );
                }
            });
        }

        let iconDom = null;
        if( item.type_name )
        {
            iconDom = <div className="componentDetailIcon">
                <SvgIcon icon={ /*getTypeIcon(*/ item.type_name /*)*/ } type="solid" />
            </div>
        }

        return <div className="presetComponentDetail">
            { iconDom }
            <div className="componentDetailText">
                <strong>
                    <Highlighter
                        searchWords={ searches }
                        autoEscape={true}
                        textToHighlight={ item.name ? item.name : '' }
                    />
                </strong><br />
                <small>
                    <Highlighter
                        searchWords={ searches }
                        autoEscape={true}
                        textToHighlight={ item.code ? item.code : '' }
                    />
                </small><br />
                { identifiers }
            </div>
        </div>
    }

    render() 
    {
        let valueEmpty = false;
        let value = this.props.multiselect ? this.state.inputValue : this.props.value;
        let classes = ['apSelect'];
        let valueRenderer = false;
        let optionRenderer = false;

        if( !value || ( value.length && value.length == 0 ) )
            valueEmpty = true;

        if( this.state.loading || this.props.loading )
            classes.push("loading");

        if( this.state.focused && !this.props.readOnly )
        {
            value = this.state.inputValue;
            classes.push("focused");
        }

        if( this.state.resultsShowing )
            classes.push("resultsShowing");

        if( this.props.multiselect )
            classes.push("multiselect");


        if( typeof( this.props.valueRenderer ) == "string" )
            valueRenderer = ( item ) => this.valueRendererPreset( item, this.props.valueRenderer );

        else if ( typeof( this.props.valueRenderer ) == "function" )
            valueRenderer = ( item ) => this.props.valueRenderer( item );


        if( typeof( this.props.optionRenderer ) == "string" )
            optionRenderer = ( item ) => this.optionRendererPreset( item, this.props.optionRenderer );

        else if ( typeof( this.props.optionRenderer ) == "function" )
            optionRenderer = ( item ) => this.props.optionRenderer( item, this.state.inputValue );


        if( value && typeof( value ) == "object" && this.props.objKeyValue )
        {
            if( typeof( this.props.objKeyValue ) == "string" && value.hasOwnProperty( this.props.objKeyValue ) )
                value = value[ this.props.objKeyValue ];

            else if ( typeof( this.props.objKeyValue ) == "function" )
                value = this.props.objKeyValue( value );
        } 

        return (
            <div className={ classes.join(" ") } ref={ node => this.parent = node }>

                { this.props.multiselect && Array.isArray( this.props.value ) &&
                    <Collapse in={ this.props.value.length > 0 }>
                        <div>
                            <div className="selectedItems">
                                { this.props.value.map( ( item, index ) => {

                                    let itemClasses = ['item'];
                                    /*
                                    if( this.isItemSelected( item ) )
                                        itemClasses.push( 'selected' );
                                    */
                                    return (
                                        <div key={ "selectedItem" + index } className={ itemClasses.join(" ") }>
                                            { valueRenderer ? valueRenderer( item ) : item }

                                            { !this.props.readOnly &&
                                                <div className="remove" onClick={ () => this.removeSelectedItem( index ) }>
                                                    <SvgIcon icon="times" type="solid" />
                                                </div>
                                            }
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Collapse>
                }

                

                <ApInput 
                    inputRef={ node => this.inputRef = node }
                    type="text"
                    name={ this.inputId }
                    id={ this.inputId }
                    value={ value }
                    label={ this.props.label }
                    placeholder={ this.props.placeholder }
                    readOnly={ this.props.readOnly }
                    onChange={ this.onChange }
                    onFocus={ this.onFocus }
                    onBlur={ this.onBlur }
                    onKeyUp={ this.onKeyUp }
                    loading={ this.state.loading || this.props.loading }
                    disabled={ this.props.disabled }
                    tooltip={ this.props.tooltip }
                    validationState={ this.props.validationState }
                    autoComplete="off"


                />

                { !this.props.readOnly &&
                    <div>
                        { !this.props.disabled && this.props.clearable && !valueEmpty &&
                            <div className="clear" onClick={ this.handleClear }>
                                <SvgIcon icon="times-circle" type="solid" />
                            </div>
                        }

                        <SvgIcon className="arrow" icon="angle-down" type="solid" />

                        <div className={ "results" + ( this.state.resultsShowing ? " show" : "" ) } ref={ node => this.resultsRef = node }>
                            { this.state.results && this.state.results.map( ( item, index ) => {

                                let itemClasses = ['item'];
                                let ref = undefined;
                                const isSelected = this.isItemSelected( item );

                                if( isSelected )
                                    itemClasses.push( 'selected' );

                                if( this.state.resultActive === index )
                                {
                                    itemClasses.push( 'active' );
                                    ref = ( node ) => this.activeResultRef = node;
                                }

                                // We can NOT use onClick event here because input blur event will fire before click event 
                                // and it will hide results (and disable mouse events from items). Therefore we must 
                                // use onMouseDown event (triggers before blur) instead of onClick event here.
                                
                                if (this.props.filterNonActives && !item.is_active) {
                                    return null
                                }

                                return (
                                    <div key={ "resultItem" + index } ref={ ref } className={ itemClasses.join(" ") } onMouseDown={ () => this.onSelect( item ) }>
                                        { optionRenderer ? optionRenderer( item ) : item }
                                        { isSelected &&
                                            <SvgIcon className="selectedIcon" icon="check" type="solid" />
                                        }
                                    </div>
                                );
                            })}

                            { this.state.results && this.state.results.length == 0 && !this.state.loading &&
                                <div className="noResults">
                                    <SvgIcon className="small-inline" icon="exclamation-triangle" type="solid" />
                                    { this.state.inputValue.length == 0 ? "Ei vaihtoehtoja saatavilla" : "Ei hakutuloksia termille '" + truncate( this.state.inputValue, 30 ) + "'" }
                                </div>
                            }
                        </div>
                    </div>
                }

            </div>
        );
    }
}

ApSelect.propTypes = {
    label:              PropTypes.string,
    onChange:           PropTypes.func,
    value:              PropTypes.oneOfType([ PropTypes.string, PropTypes.array, PropTypes.object ]),
    loading:            PropTypes.bool,
    clearable:          PropTypes.bool, // Allways on in multiselect mode
    multiselect:        PropTypes.bool,
    options:            PropTypes.array,
    filterNonActives:    PropTypes.bool,

    optionRenderer:     PropTypes.oneOfType([ PropTypes.func, PropTypes.string ]),
    valueRenderer:      PropTypes.oneOfType([ PropTypes.func, PropTypes.string ]), // Only in multiselect mode

    // When value is object
    objKeyId:           PropTypes.string, 
    objKeyValue:        PropTypes.oneOfType([ PropTypes.string, PropTypes.func ]), // Only when NOT in multiselect mode
    objKeySearchable:   PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]),

    // When options are fetched from backend
    apiUrl:             PropTypes.string,
    apiData:            PropTypes.object,

    tooltip:            PropTypes.string,
    validationState:    PropTypes.string,
};

export default ApSelect;
