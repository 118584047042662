/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import api from 'services/Api/Api.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import { errorPopper, tr } from 'services/Helpers/Helpers.js'
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ComponentTooltip from 'modules/CustomerPortal/common/ComponentTooltip/ComponentTooltip.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import ApModal from 'common/ApModal/ApModal.js';

import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import './MassImport.css';

import { getComponentStatusNames } from 'modules/CustomerPortal/common/StorageHelpers.js';

// Exclude defines indexes that cannot appear at the same time
const defaultHeaderOptions = {
    type: {
        label: tr('type'),
        icon: 'list-ul',
        headerValues: [tr('type')],
    },
    status: {
        label: tr(''),
        icon: 'cube',
        headerValues: [tr('status')],
    },
    name: {
        label: tr('new_name'),
        icon: 'signature',
        headerValues: [tr('name')],
    },
    unit: {
        label: tr('unit'),
        icon: 'weight-hanging',
        headerValues: [tr('unit')],
    },
    code: {
        label: tr('code'),
        icon: 'list-ol',
        headerValues: [tr('code')],
    },
    price: {
        label: tr('value'),
        icon: 'money-bill',
        headerValues: [tr('value')],
    },
    price_sell: {
        label: tr('selling_price'),
        icon: 'money-bill-wave',
        exclude: [ 'profit_percent' ],
        headerValues: [tr('selling_price')],
    },
    profit_percent: {
        label: tr('gross_profit_percent'),
        icon: 'percent',
        exclude: [ 'price_sell' ],
        headerValues: [tr('gross_profit_percent')],
    },
    conversion_factor: {
        label: tr('conversion_factor'),
        icon: 'arrows-alt-h',
        headerValues: [tr('conversion_factor')],
    },
    conversion_unit: {
        label: tr('conversion_unit'),
        icon: 'text-width',
        headerValues: [tr('conversion_unit')],
    },
    description: {
        label: tr('free_text'),
        icon: 'info',
        headerValues: [tr('free_text')],
    },
    alt_name: {
        label: tr('unifying_component'),
        icon: 'signature',
        multiple: true,
        headerValues: [`${tr('unifying_name')}*`],
    },
}

const filters = [
    { value: 'noComponent', label: tr('unlinked') },
    { value: 'invalid', label: tr('invalid_value') },
    { value: 'change', label: tr('value_changes') },
    { value: 'new', label: tr('new_storage_component') },
];

class MassImport extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            loading: false,
            rows: [],
            selected_file: null,
            code_options: {},

            identifiers: [],

            otherCols: [],

            headerOptions: defaultHeaderOptions,

            filtersSelected: [],

            counts: {},

            rowChangeIds: [],
            rowsInvalidIds: [],
            rowsNoComponenIds: [],
            rowsNewIds: [],

            tooManyRows: false,

            linkModal: {},

        }
        autoBind(this);
    }

    componentDidMount()
    {
        this.getRelated();
    }

    getRelated()
    {
        
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'extranet/storage/masslist/import',
            errorPopper: tr('get_error'),
        }).then((response) => {
            let headerOptions = { ...this.state.headerOptions }

            response.identifiers.forEach( i => {
                headerOptions[`identifier_${ i.id }`] = { icon: 'fingerprint', label: `${tr('identifier')}: ${i.name}`, headerValues: [ `${tr('identifier')}: ${i.name}`] };
            })

            response.calculatables.forEach( i => {
                headerOptions[`calculatable_${ i.id }`] = { icon: 'weight', label: `${tr('meter')}: ${i.name}`, headerValues: [ `${tr('meter')}: ${i.name}`] };
            })

            response.properties.forEach( i => {
                headerOptions[`property_${ i.id }`] = { icon: 'ruler-combined', label: `${tr('property')}: ${i.name}`, headerValues: [ `${tr('property')}: ${i.name}`] };
            })

            this.setState({
                loading: false,
                headerOptions: headerOptions,
                identifiers: response.identifiers,
                calculatables: response.calculatables,
                properties: response.properties,
                code_options: response.code_options,
            });

        }).catch((error) => {
            this.setState({ loading: false });
            console.error(error);
        });
    }

    import()
    {
        this.setState({
            loading: true,
            rows: [],
        });

        let data = new FormData();
        data.append( 'file', this.state.selected_file );

        api({
            method: 'post',
            url: 'extranet/storage/masslist/xlsx',
            data: data,
        }).then(( response ) => {
            let headerOptions = this.state.headerOptions;
            let otherCols = this.state.otherCols;

            // If we have made changes to headers and want to reload file we dont want to loose the changes
            // but if we load another file we want to update that, so we have a loose check that
            // if the column ammount is same then we keep the file
            if( otherCols.length !== response.headers.length )
            {
                let usedCols = [];

                otherCols = response.headers.map( h => {

                    if( !h ) return null;
                    h = `${h}`.toLowerCase();

                    for ( let key in headerOptions )
                    {

                        const fOptions = headerOptions[ key ].headerValues;
                        if( !Array.isArray( fOptions ) ) continue;

                        let found = fOptions.find( f => {
                            f = f.toLowerCase();

                            let tmp = h;

                            if( f.slice(-1) === '*' )
                            {
                                const l = f.length - 1;
                                f = f.substring( 0, l )
                                tmp = h.substring( 0, l )
                            }
                            return ( tmp === f );
                        })
                        if( found )
                        {
                            // Skip so there are no same column dublicated
                            if( usedCols.includes( key ) && !headerOptions[ key ].multiple  )
                                return null;

                            // Also add exclueds as used cols
                            if( headerOptions[ key ].exclude )
                            {
                                headerOptions[ key ].exclude.forEach( e => usedCols.push( e ));
                            }

                            usedCols.push( key );
                            return key;
                        }
                    }

                    return null;
                });
            }

            this.setState({
                loading: false,
                rows: response.rows,
                otherCols: otherCols,
                tooManyRows: response.tooManyRows,
            },() => {
                this.updateRows();
            });
        }).catch( ( error ) => {
            console.error(error );
            errorPopper(error, tr('file_read_error'));
            this.setState({
                loading: false,
            });
        });
    }

    save()
    {
        const idsToSave = [ ...this.state.rowChangeIds, ...this.state.rowsNewIds ];
        let saveData = this.state.rows.filter( f => idsToSave.includes( f.id ) );

        saveData = saveData.map( row => {
            let update = {};
            row.otherDetails.forEach( (detail, index) => {
                if( [ 'newComponent', 'change' ].includes( detail.status ) )
                {
                    const value = row.other[ index ];
                    let column = this.state.otherCols[ index ];

                    // If column can be mulitple times dont override the data
                    if( this.state.headerOptions[ column ].multiple )
                        column = `${ column }_${ index }`

                    update[ column ] = value;
                }
            })
            if( row.component )
            {
                return {
                    id: row.component.id,
                    values: update,
                }
            }
            else
            {
                // New component
                return {
                    name: row.name,
                    values: update,
                };
            }
        })

        api({
            method: 'post',
            url: 'extranet/storage/masslist/save',
            data: { components: saveData },
            errorPopper: tr('file_read_error'),
        }).then(( response ) => {
            this.setState({
                loading: false,
                rows: [],
                columnSettings: [],
            });
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    updateRows()
    {
        let counts = {
            colInvalid: 0,
            colToNull: 0,
            colChange: 0,

            rows: 0,
            rowsInvalid: 0,
            rowsNoComponent: 0,
            rowsChange: 0,
            rowsNew: 0,

            unusedCols: 0,
        }

        this.state.otherCols.forEach( c => {
            if( c === null ) counts.unusedCols++;
        });

        let rowChangeIds = [];
        let rowsInvalidIds = [];
        let rowsNoComponenIds = [];
        let rowsNewIds = [];

        let rows = this.state.rows.slice(0);
        rows = rows.map( ( r, rIndex ) => {

            let rowInvalid = false;
            let rowChange = false;
            let rowToNull = false;

            const typeColIndex = this.state.otherCols.findIndex( c => c === 'type' );
            r.type = null;
            if( r.component ) r.type = r.component.type_name;
            if( typeColIndex >= 0 ) r.type = r.other[ typeColIndex ]

            r.otherDetails = this.state.otherCols.map(( selected, i ) => {
                const details = this.colDetails( r, selected, r.other[ i ] )
                if( details.status === 'invalid' )
                {
                    counts.colInvalid++;
                    rowInvalid = true;
                }
                else if( details.status === 'toNull' )
                {
                    counts.colToNull++;
                    rowToNull = true;
                }
                else if( details.status === 'change' )
                {
                    counts.colChange++;
                    rowChange = true;
                }
                return details;
            });

            counts.rows++;

            if( r.link === 'newComponent' )
            {
                counts.rowsNew++;
                rowsNewIds.push( r.id )
            }
            else if( r.link === 'noLink' )
            {
                counts.rowsNoComponent++;
                rowsNoComponenIds.push( r.id );
            }

            if( rowInvalid )
            {
                counts.rowsInvalid++;
                rowsInvalidIds.push( r.id );
            }
            if( rowChange )
            {
                counts.rowsChange++;
                rowChangeIds.push( r.id );
            }
            if( rowToNull )
            {
                counts.rowsChange++;
                rowChangeIds.push( r.id );
            }

            return r;
        });

        this.setState({
            rows: rows,
            counts: counts,

            rowChangeIds: rowChangeIds,
            rowsInvalidIds: rowsInvalidIds,
            rowsNoComponenIds: rowsNoComponenIds,
            rowsNewIds: rowsNewIds,
        });
    }

    colDetails( row, selected, updateValue )
    {

        const notNumeric = ( v ) => {
            return ( v && isNaN(parseFloat( v )) )
        }

        let component = row.component;
        const link = row.link;
        const type = row.type;

        if( !component ) component = {};

        if( !selected )
        {
            return {
                status: 'noCol',
                tooltip: null,
                oldValue: null,
            }
        }

        let originalValue = null;
        if( selected === 'unit' )
        {
            originalValue = component.unit
        }
        else if( selected === 'name' )
        {
            originalValue = component.name
        }
        else if( selected === 'type' )
        {
            originalValue = component.type_name;
            if( originalValue === 'other') originalValue = 'expense';
            let options = ['item', 'work', 'expense'];
            if( !options.includes( updateValue ) )
            {
                return {
                    status: 'invalid',
                    tooltip: <div><strong>{ tr('invalid_value') }</strong><br /> { tr('acceptable_values_are') }: { options.join(', ') }</div>,
                    oldValue: originalValue,
                }
            }
        }
        else if( selected === 'status' )
        {
            originalValue = component.status_name
            let options = getComponentStatusNames();
            if( !options.includes( updateValue ) )
            {
                return {
                    status: 'invalid',
                    tooltip: <div><strong>{ tr('invalid_value') }</strong><br /> { tr('acceptable_values_are') }: { options.join(', ') }</div>,
                    oldValue: originalValue,
                }
            }
        }
        else if( selected === 'code' )
        {
            originalValue = component.code
            const tmp = this.codeIsInvalid( updateValue );
            if( tmp )
                return {
                    status: 'invalid',
                    tooltip: <div><strong>{ tr('invalid_value') }</strong><br />{ tmp }</div>,
                    oldValue: originalValue,
                }
        }
        else if( selected === 'price' )
        {
            if( notNumeric( updateValue ) )
            {
                return {
                    status: 'invalid',
                    tooltip: <div><strong>{ tr('value_not_numeric') }</strong></div>,
                }
            }
            originalValue = component.price
        }
        else if( selected === 'price_sell' )
        {
            if( notNumeric( updateValue ) )
            {
                return {
                    status: 'invalid',
                    tooltip: <div><strong>{ tr('value_not_numeric') }</strong></div>,
                }
            }
            originalValue = component.price_sell
        }
        else if( selected === 'profit_percent' )
        {
            if( notNumeric( updateValue ) )
            {
                return {
                    status: 'invalid',
                    tooltip: <div><strong>{ tr('value_not_numeric') }</strong></div>,
                }
            }
            if( parseFloat( updateValue ) >= 100 )
            {
                return {
                    status: 'invalid',
                    tooltip: <div><strong>{ tr('gross_profit_cant_be_100') }</strong></div>,
                }
            }


            originalValue = component.profit_percent
        }
        else if( selected === 'description' )
        {
            originalValue = component.description
        }
        else if( selected === 'conversion_factor' )
        {
            if( type !== 'item' )
            {
                return {
                    status: 'noThisType',
                    tooltip: tr('storage_component_no_conversion_factor'),
                    oldValue: null,
                }
            }
            if( notNumeric( updateValue ) )
            {
                return {
                    status: 'invalid',
                    tooltip: <div><strong>{ tr('value_not_numeric') }</strong></div>,
                }
            }

            originalValue = component.conversion_factor
        }
        else if( selected === 'conversion_unit' )
        {
            if( type !== 'item' )
            {
                return {
                    status: 'noThisType',
                    tooltip: tr('storage_component_no_conversion_unit'),
                    oldValue: null,
                }
            }

            originalValue = component.conversion_unit
        }
        else if( selected === 'alt_name' )
        {
            if( component.altnames )
            {
                const tmp = component.altnames.find( f => f.name === updateValue )
                if( tmp ) originalValue = updateValue;
            }
        }

        else if( selected.startsWith('identifier') )
        {
            const id = parseInt( selected.split('_')[ 1 ], 10 )
            if( component.identifiers )
            {
                const tmp = component.identifiers.find( i => i.id === id );
                if( tmp ) originalValue = tmp.pivot.value;
            }
        }
        else if( selected.startsWith('calculatable') )
        {
            if( type !== 'item' )
            {
                return {
                    status: 'noThisType',
                    tooltip: tr('storage_component_no_meters'),
                    oldValue: null,
                }
            }

            if( notNumeric( updateValue ) )
            {
                return {
                    status: 'invalid',
                    tooltip: <div><strong>{ tr('value_not_numeric') }</strong></div>,
                }
            }

            const id = parseInt( selected.split('_')[ 1 ], 10 )
            if( component.calculatables )
            {
                const tmp = component.calculatables.find( i => i.id === id );
                if( tmp ) originalValue = tmp.pivot.value;
            }
        }

        else if( selected.startsWith('property') )
        {
            const id = parseInt( selected.split('_')[ 1 ], 10 )
            if( component.properties )
            {
                const tmp = component.properties.find( i => i.id === id );
                if( tmp ) originalValue = tmp.pivot.value;
            }
        }

        if( link === 'noLink')
        {
            return {
                status: 'noComponent',
                tooltip: tr('storage_component_not_linked'),
                oldValue: null,
            }
        }

        if( !updateValue && originalValue )
        {
            return {
                status: 'toNull',
                tooltip: tr('old_value_will_be_deleted'),
                oldValue: originalValue,
            }
        }
        if( originalValue == updateValue )
        {
            return {
                status: 'noChange',
                tooltip: tr('no_change'),
                oldValue: null,
            }
        }

        if( link === 'newComponent')
        {
            return {
                status: 'newComponent',
                tooltip: tr('storage_component_new_value'),
                oldValue: null,
            }
        }
        return {
            status: 'change',
            tooltip: tr('value_update'),
            oldValue: originalValue,
        }
    }

    codeIsInvalid( value = null )
    {
        if( value === null ) return false;

        if( !this.state.code_options.limit )
            return tr('code_settings_missing');

        value = `${value}`;

        const limit = this.state.code_options.limit;

        if( limit.is_free ) return false;

        const levelCount = this.state.code_options.level_count;
        const separator = this.state.code_options.separator;
        const tailLength = this.state.code_options.tail_length;
        const validParents = this.state.code_options.parents;

        let tail = null;
        let head = null;

        if( limit.need_separator )
        {
            let parts = value.split( separator );
            if( parts.length !== parseInt( levelCount, 10 ) + 1 )
                return tr('code_invalid_delimiters', [`"${separator}"`, levelCount, parts.length - 1]);
            tail = parts.pop();
            head = parts.join( separator );
        }
        else
        {
            tail = value.slice( -1 * tailLength );
            head = value.slice( 0, -1 * tailLength );
        }
        if( !validParents.includes( head ) )
            return tr('code_level_not_defined', [head]);

        if( limit.need_length && tail.length !== tailLength )
            return tr('code_tail_invalid');

        return false;
        /* */
    }

    getColumns()
    {
        let cols = [];

        cols.push({
            Header: '#',
            id: 'nmb',
            width: 50,
            filterable: false,
            sortable: false,
            accessor: 'id',
            className: "overflowableOnHover",
            Cell: props => {
                let dom = <div className="rowNumber">{ props.value }.</div>;
                if( props.value == 2 )
                return <ApTooltip block text={ tr('excel_first_row_title') } position="topleft">
                    { dom }
                </ApTooltip>
                return dom;
            }
        });

        cols.push({
            Header: tr('link'),
            id: 'link',
            width: 50,
            className: "overflowableOnHover linkTd center",
            filterable: false,
            Cell: props => {

                const row = props.original;

                let color = "#000"
                let tooltip = null;
                let icon = null;
                let onClick = ()  => { this.setState({ linkModal: {
                    show: true,
                    rowId: props.original.id,
                    component: row.component || null,
                    selected: row.link

                }}); }

                if( row.link === 'old' )
                {
                    tooltip = tr('storage_component_link_exists');
                    icon = 'check-circle';
                    color = '#0099CC';
                    onClick = null;
                }
                if( row.link === 'noLink' )
                {
                    tooltip = tr('storage_component_no_link');
                    icon = 'times-circle';
                    color = '#CC0000';
                }
                else if( row.link === 'newLink' )
                {
                    tooltip = tr('storage_component_new_link');
                    icon = 'arrow-alt-circle-left';
                    color = '#388E3C';
                }
                else if( row.link === 'newComponent' )
                {
                    tooltip = tr('new_storage_component');
                    icon = 'plus-circle';
                    color = '#388E3C';
                }

                let tooltipDom = [];
                if( tooltip ) tooltipDom.push(<div key="tt">{ tooltip }</div>);
                if( row.component ) tooltipDom.push(<ComponentTooltip key="c" component={ row.component } />);

                return <div onClick={ onClick } className={ onClick ? 'pointer' : ''}>
                    <ApTooltip block text={ tooltipDom } position="topleft">
                        <SvgIcon icon={ icon } type="solid" fill={ color } />
                    </ApTooltip>
                </div>
            },

        });

        cols.push({
            id: 'name',
            Header: tr('name'),
            // showInitially: true,
            // customizable: true,
            accessor: 'name',
            customFilter: {
                type: "text",
                placeholder: tr('enter_name'),
            },
        });


        this.state.otherCols.forEach(( selected, index ) => {
            const header = row => {
                const actions = this.getHeaderActions( index );

                let title = tr('not_used');
                if( selected )
                    title = this.state.headerOptions[ selected ].label;

                return <ApDropdown
                    fullWidth
                    button={ <div className="input-menu"><div className="headerLabel">{ title }</div><div className="input-menu-button"><SvgIcon icon="caret-down" type="solid" /></div></div> }
                    actions={ actions }
                />
            }


            cols.push({
                id: `othercol_${ index }`,
                //Header: `Sarake ${ index + 1 }`,
                Header: header,
                headerClassName: "overflowable",

                //showInitially: true,
                //customizable: true,
                sortable: false,
                accessor: row => {
                    return row.other[ index ];
                },
                className: 'otherCell overflowableOnHover',
                Cell: props => {

                    if( !props.original.otherDetails ) return null

                    const details = props.original.otherDetails[ index ];
                    let tooltip = [];
                    if( details.tooltip )
                        tooltip.push( <div key="tt">{ details.tooltip }</div> )
                    if( details.oldValue )
                        tooltip.push( <div key="ov">{ tr('old_value') }: <strong>{ details.oldValue }</strong></div> )
                    tooltip = ( tooltip.length ) ? <div>{ tooltip }</div> : null;

                    let className= [ 'other', details.status ];

                    return <div className={ className.join(' ')}>
                        <ApTooltip block text={ tooltip } position="top">
                            { props.value || <span>&nbsp;</span> }
                        </ApTooltip>
                    </div>
                },
            });
        })
        return cols;

    }

    setOtherColSelected( index, value )
    {
        let otherCols = this.state.otherCols.slice(0);
        otherCols = otherCols.map(( col, i ) => {
            if( i === index )
                return value;
            return col;
        });
        this.setState({ otherCols: otherCols }, () => {
            this.updateRows();
        });
    }

    getHeaderActions( index )
    {
        let options = [{
            label: tr('not_used'),
            action: ( id, closeFunc ) => {
                this.setOtherColSelected( index, null );
                closeFunc();
            },
        }];

        let headerOptions = this.state.headerOptions;

        for ( let key in headerOptions )
        {
            let excludes = [];
            if( !headerOptions[ key ].multiple )
                excludes = [ key ];

            const otherExclude = headerOptions[ key ].exclude;
            if( otherExclude )
                excludes = [ ...excludes, ...otherExclude ];

            let skip = excludes.some( e => {
                return this.state.otherCols.includes( e );
            });
            if( skip ) continue;

            options.push( {
                label: headerOptions[ key ].label,
                icon:  headerOptions[ key ].icon,
                action: ( id, closeFunc ) => {
                    this.setOtherColSelected( index, key );
                    closeFunc();
                },
            });
        }
        return options;
    }

    noLinkToNewById( ids )
    {
        let rows = this.state.rows.slice(0);
        rows = rows.map( r => {
            if( ids.includes( r.id ) && r.link === 'noLink' )
            {
                r.link = 'newComponent';
                r.component = null;
            }
            return r;
        })
        this.setState({ rows: rows }, () => {
            this.updateRows();
        });
    }

    renderFileSelect()
    {
        /*
                <ApAddon custom width="250px">
                    <ApButton style={{ margin: "5px" }} size="small" color="blue" onClick={ this.getTemplate }>
                        <SvgIcon icon="download" type="solid" />
                        Lataa Excel-pohja
                    </ApButton>
                </ApAddon>
                */


        return <div className="fileSelectContainer">
            <ApInputStack gap="0">
                <ApAddon width="300px" noRightBorder>
                    { tr('select_excel_file') }
                </ApAddon>
                <ApInput
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    id="selected_file"
                    name="selected_file"
                    label={ `${tr('select_excel_file')}...` }
                    value={ this.state.selected_file }
                    onChange={ ( file ) => { this.setState({ selected_file: file }) } }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
                <ApAddon noLeftBorder width="150px">
                    <ApTooltip block text={ tr('read_data_excel') } position="bottom">
                        <div className="apSimpleButton" onClick={ this.import }>
                            { tr('read_file') }
                        </div>
                    </ApTooltip>
                </ApAddon>
            </ApInputStack>
        </div>
    }

    renderTable()
    {
        let rows = this.state.rows;

        let filters = this.state.filtersSelected.map( f => f.value )
        let filterIds = [];

        if( filters.includes( 'change' ) )
            filterIds = [ ...filterIds,  ...this.state.rowChangeIds ];
        if( filters.includes( 'invalid' ) )
            filterIds = [ ...filterIds,  ...this.state.rowsInvalidIds ];
        if( filters.includes( 'noComponent' ) )
            filterIds = [ ...filterIds,  ...this.state.rowsNoComponenIds ];
        if( filters.includes( 'new' ) )
            filterIds = [ ...filterIds,  ...this.state.rowsNewIds ];

        if( filters.length )
            rows = this.state.rows.filter( r => filterIds.includes( r.id ) );

        return <div className="componentsTable">
            <ApReactTable
                columns={ this.getColumns() }
                data={ rows }
                loading={ this.state.loading }

                defaultPageSize={ 10 }
                noDataText={ tr('no_rows') }

                multiselect={[
                    {
                        icon: "plus-square",
                        label: tr('new_storage_component_from_unlinked'),
                        action: ( indexes ) => { this.noLinkToNewById( indexes.map( i => rows[ i ].id ) ) },
                        unselectAfter: true,
                    },
                ]}
            />
        </div>

    }

    renderFooterInfo()
    {
        const counts = this.state.counts;
        const drawIcon = ( count, color, icon, clickFilter, title ) => {
            let onClick = null;

            let className = ['stat'];
            if( counts[ count ] ) className.push( color );
            else className.push( 'empty' );

            if( clickFilter )
            {
                className.push('pointer');
                onClick = () => {
                    if( clickFilter === 'all')
                        this.setState({ filtersSelected: [] });
                    else
                    {
                        const filter = filters.find( f => f.value === clickFilter)
                        this.setState({ filtersSelected: [ filter ] });
                    }
                }
            }

            return <ApTooltip text={ title } position="topleft">
                <div className={ className.join( ' ' ) } onClick={ onClick }>
                    <SvgIcon icon={ icon } type="solid" />
                    { counts[ count ] }
                </div>
            </ApTooltip>
        };

        return <table>
            <tbody>
            <tr>
                <td>{ drawIcon( 'rows',            '',        'list',              'all',         tr('total_rows') ) }</td>
                <td>{ drawIcon( 'rowsNoComponent', 'error',   'square',            'noComponent', tr('rows_without_linking') ) }</td>
                <td>{ drawIcon( 'rowsChange',      'info',    'caret-square-left', 'change',      tr('rows_that_will_change') ) }</td>
                <td>{ drawIcon( 'rowsNew',         'success', 'plus-square',       'new',         tr('new_rows') ) }</td>
            </tr><tr>
                <td>{ drawIcon( 'unusedCols',      'warning', 'columns',               null,      tr('unspecified_columns') ) }</td>
                <td>{ drawIcon( 'colInvalid',      'error',   'exclamation-circle',    'invalid', tr('incorrect_values') ) }</td>
                <td>{ drawIcon( 'colChange',       'info',    'arrow-alt-circle-left', 'change',  tr('values_that_will_change') ) }</td>
            </tr>
            </tbody>
        </table>
    }

    rowsDublicated()
    {
        let ids = [];

        let dublicateIds = [];

        this.state.rows.forEach( row => {
            if( !row ) return null;
            if( !row.component ) return null;
            if( !row.component.id ) return null;

            if( ids.includes(row.component.id) )
                dublicateIds.push( row.component.id );

            ids.push( row.component.id );
        });

        let dublicateRows = [];

        this.state.rows.forEach( row => {
            if( !row ) return null;
            if( !row.component ) return null;
            if( !row.component.id ) return null;

            if( dublicateIds.includes(row.component.id) )
                dublicateRows.push( row.id );
        });
        return dublicateRows;

    }

    formInvalid()
    {
        const counts = this.state.counts;
        if( !counts.rows ) return tr('no_rows');


        const dublicates = this.rowsDublicated();
        if( dublicates.length )
            return `${tr('same_component_defined_on_rows')}: ${ dublicates.join(', ')}`



        if( counts.colInvalid ) return tr('incorrect_values');
        if( counts.rowsNoComponent ) return tr('all_rows_not_linked');
        if( !counts.rowsChange && !counts.rowsNew ) return tr('no_changes');
        if( counts.rowsNew && !this.state.otherCols.includes('type') )
            return tr('type_column_must_be_defined');

        return false;
    }

    renderFooter()
    {
        if( !this.state.rows.length ) return null;

        const formInvalidMessage = this.formInvalid();

        return <div className="apBox massImportCustomFooter">
            { this.renderFooterInfo() }
            <div className="apButtonGroup">
                <ApTooltip text={ formInvalidMessage } position="topright">
                    <ApButton className="save" color="blue" onClick={ this.save } disabled={ this.state.loading || Boolean( formInvalidMessage ) } loading={ this.state.loading }>
                        <SvgIcon icon="save" type="solid" />
                        { tr('save') }
                    </ApButton>
                </ApTooltip>
            </div>
        </div>
    }

    renderFilters()
    {
        if( !this.state.rows.length ) return null;

        return <ApSelect
            label={ tr('crop_rows') }
            loading={ this.state.loading }
            value={ this.state.filtersSelected }
            onChange={ ( values ) =>  this.setState({ filtersSelected: values }) }
            objKeyId="value"
            multiselect
            options={ filters }
            optionRenderer="label"
            objKeySearchable="label"
            valueRenderer="label"
        />
    }

    linkModalSubmit()
    {
        const data = this.state.linkModal;
        let rows = this.state.rows.slice(0);
        rows = rows.map( r => {

            if( r.id === data.rowId )
            {
                r.link = data.selected;
                r.component = null;
                if( r.link === 'newLink')
                    r.component = data.component;
            }
            return r;
        });

        this.setState({
            linkModal: {},
            rows: rows,
        }, () => {
            this.updateRows();
        });
    }

    linkSelectComponent( component = null )
    {
        let linkModal = { ...this.state.linkModal };
        linkModal.component = component;
        this.setState({ linkModal: linkModal });
    }

    linkSelectType( option )
    {
        if( this.state.linkModal.selected === option ) return null;
        let linkModal = { ...this.state.linkModal };
        linkModal.selected = option;
        this.setState({ linkModal: linkModal });
    }

    renderLinkModal()
    {
        const options = [{
            select: 'noLink',
            content: <div className="optionText noLink">
                { tr('storage_component_no_link') }
            </div>,
        },{
            select: 'newComponent',
            content: <div className="optionText newUserContainer">
                { tr('new_storage_component') }
            </div>,
        },{
            select: 'newLink',
            content: <div className="optionText selectContainer">
                <ApSelect
                    label={ tr('combine_with_storage_component') }
                    loading={ this.state.loading }
                    value={ this.state.linkModal.component }
                    optionRenderer="component_detail"
                    onChange={ this.linkSelectComponent }
                    clearable
                    disabled={ this.state.linkModal.selected !== 'newLink' }

                    objKeyId="id"
                    objKeyValue="name"
                    apiUrl="storage/components"
                    apiData={{
                        formatter: 'management',
                        status: 'all',
                    }}
                />
            </div>
        }];

        const optionDoms = options.map( o => {
            let icon = 'circle';
            let className = ['option']
            if( o.select === this.state.linkModal.selected )
            {
                className.push( 'selected' );
                icon = 'dot-circle';
            }
            return <div className={ className.join( ' ' ) } onClick={ () => { this.linkSelectType( o.select ) }}>
                <div className="iconContainer">
                    <SvgIcon icon={ icon } />
                </div>
                { o.content }
            </div>
        })

        return <ApModal
            className="narrow linkModal"
            show={ Boolean( this.state.linkModal.show ) }
            handleClose={ () => this.setState({ linkModal: {} }) }
            closeFromBg
            header={
                <div className="padding-small">
                    <h3>{ tr('define_component_for_row') }</h3>
                </div>
            }
            body={
                <div className="padding">
                    { optionDoms }
                </div>
            }
            footer={
                <div className="padding-small text-right">
                    <ApButton
                        color="white"
                        onClick={ this.linkModalSubmit }
                        disabled={ !this.state.linkModal.selected || ( this.state.linkModal.selected === 'newLink' && !this.state.linkModal.component ) }
                    >
                        <SvgIcon icon="check" type="solid" />
                        OK
                    </ApButton>
                </div>
            }
            />
    }

    renderTooManyRowsInfo()
    {
        let className = ['apInfo', 'small' ];
        let icon = 'info-circle';

        if( this.state.tooManyRows )
        {
            className.push( 'warning' );
            className.push( 'orange' );
            icon = 'exclamation-triangle';
        }

        return <div className={ className.join(' ') }>
            <SvgIcon icon={ icon } type="solid" />
                { tr('mass_import_too_many_rows_info') }
        </div>
    }

    render()
    {
        let fileSelect    = this.renderFileSelect();
        let table         = this.renderTable();

        return <div id="storageMassImport">
            <ApFormPage
                className="componentsListForm"
                customFooter={ () => this.renderFooter() }
                unsaved={ false }
                onSave={ () => {} }
            >
                <div className="padding">
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        { tr('mass_import_info') }
                    </div>

                    { this.renderTooManyRowsInfo() }

                    { fileSelect }
                    { this.renderFilters() }
                    { table }
                </div>
            </ApFormPage>
            { this.renderLinkModal() }
        </div>
    }
}

export default MassImport;
