/* eslint-disable eqeqeq */

const ENTRY_ICONS = {
	hour: 		"clock",
	travel: 	"car",
	expense: 	"money-bill-wave",
	allowance: 	"suitcase",
	absence: 	"ban",
	notation: 	"trophy",
}

// This should match with DB table 'static_work_hour_statuses'
const WOKRHOUR_STATUSES = {
    suggested: 	"Ehdotettu",
    open: 		"Avoinna",
    submitted: 	"Lähetetty",
    rejected: 	"Hylätty",
    approved: 	"Hyväksytty",
    confirmed: 	"Vahvistettu",
    payrolled: 	"Maksussa",
	paid: 		"Maksettu",
	billed:     "Laskutettu",
	not_billable: "Ei laskuteta",
	archived:   "Arkistoitu",
};


export function entryIcon( entryType )
{
	if( entryType in ENTRY_ICONS )
		return ENTRY_ICONS[ entryType ];
	return false;
}

export function statusText( status )
{
	if( status in WOKRHOUR_STATUSES )
		return WOKRHOUR_STATUSES[ status ];
	return false;
}

