import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
//import Notfound  from 'layout/Notfound/Notfound';
import 'moment/locale/fi';

import auth from 'services/Authed/Authed.js';
import api from 'services/Api/Api.js';

import ApConfirm from 'common/ApConfirm/ApConfirm.js'
import ApModal from 'common/ApModal/ApModal.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import { ApReactTable, colPreset } from 'common/ApReactTable/ApReactTable.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import { Collapse } from 'react-bootstrap';
import { errorPopper }  from 'services/Helpers/Helpers.js'

import DepthInput from './DepthInput/DepthInput.js';
import './Reports.css';

import { keyExists /*, hasPermissions, validateEmail */} from  'services/Helpers/Helpers.js';
import { EventEmitter } from 'fbemitter';

const permissions = 'reports';

const types = [
    { value: 'none', label: '-- VALITSE --' },
    { value: 'tt', label: 'Tuntikirjaukset',
        splitOptions: [
            { value: 'project', label: 'Projektit', depth: true },
            { value: 'user', label: 'Henkilöt' },
            { value: 'description', label: 'Kuvaus' },
            { value: 'component', label: 'Työt' },
            { value: 'date', label: 'Päivät' },
        ],
        columnOptions: [
            { value: 'hour_rows', label: 'Tunnit' },
        ],
    },
    { value: 'tt_travel', label: 'Matkakirjaukset',
        splitOptions: [
            { value: 'project', label: 'Projektit', depth: true },
            { value: 'user', label: 'Henkilöt' },
            { value: 'date', label: 'Päivät' },
            { value: 'route', label: 'Reitti' },
        ],
        columnOptions: [
            { value: 'hours', label: 'Matkatunnit' },
            { value: 'distance', label: 'Kilometrit' },
            { value: 'compensation_total', label: 'Korvaus' },
        ],
    },

    { value: 'tt_expense', label: 'Kulukorvaukset',
        splitOptions: [
            { value: 'project', label: 'Projektit', depth: true },
            { value: 'user', label: 'Henkilöt' },
            { value: 'date', label: 'Päivät' },
            { value: 'event', label: 'Tapahtuma' },
            { value: 'event_details', label: 'Tapahtuma tiedot' },
        ],
        columnOptions: [
            { value: 'money_expense', label: 'Kustannus' },
        ],
    },


    /*
    { value: 'component', label: 'Varastonimikkeet',
        splitOptions: [
            { value: 'component', label: 'Komponentit' },
        ],
        columnOptions: [
        ],
    },
    */
];

const dateModeOptions = [
    { value: 'none', label: 'Ei aikarajausta' },
    { value: 'dates', label: 'Aikaväli' },
    { value: 'week', label: 'Viikko' },
    { value: 'month', label: 'Kuukausi' },
    { value: 'year', label: 'Vuosi' },
];

class Reports extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            loading: false,

            data: [],
            related: {},

            // Saved forms
            forms: [],

            form: {},
            formSubmitted: null,

            formModal: {},
            formDelete: {},

            currencySign: '€' //auth.getCurrencySign(),
        }

        this.currentYear = new Date().getFullYear();
        this.startYear = this.currentYear - 5;
        this.endYear = this.currentYear + 1;

        this.tabsRef = React.createRef();


        autoBind(this);
    }

    componentDidMount()
    {
        //if( !hasPermissions( permissions ) ) return null;
        this.fetchSavedForms();
    }

    fetchSavedForms()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: `extranet/reports/forms`,
            errorPopper: 'Tallennettujen raporttipohjien haussa tapahtui virhe',
        }).then(( response ) => {

            console.log( 'get', response );

            const forms = response.map( item => {
                const type = types.find( f => f.value === item.options.type );
                item.options.type = type;

                if( item.options.splits )
                    item.options.splits = type.splitOptions.filter( f => item.options.splits.includes( f.value ) )

                if( item.options.columns )
                    item.options.columns = type.columnOptions.filter( f => item.options.columns.includes( f.value ) )

                if( item.options.autoTime )
                {
                    const mode = item.options.autoTime
                    item.options.dateMode = mode;

                    if( mode === 'dates' )
                    {
                        if( item.options.autoTimeDiff )
                            item.options.start = moment().add( item.options.autoTimeDiff, 'days').format('YYYY-MM-DD');
                        if( item.options.autoTimeDiff2 )
                            item.options.end = moment().add( item.options.autoTimeDiff2, 'days').format('YYYY-MM-DD');
                    }
                    else if( mode === 'week' )
                    {
                        const dMoment = moment().add( item.options.autoTimeDiff, 'weeks');
                        item.options.dateModeYear = dMoment.get('year');
                        item.options.dateModeValue = dMoment.get('week');
                        item.options.start = dMoment.startOf('week').format('YYYY-MM-DD');
                        item.options.end = dMoment.endOf('week').format('YYYY-MM-DD');
                    }
                    else if( mode === 'month' )
                    {
                        const dMoment = moment().add( item.options.autoTimeDiff, 'months');
                        item.options.dateModeYear = dMoment.get('year');
                        item.options.dateModeValue = dMoment.get('month');
                        item.options.start = dMoment.startOf('month').format('YYYY-MM-DD');
                        item.options.end = dMoment.endOf('month').format('YYYY-MM-DD');
                    }
                    else if( mode === 'year' )
                    {
                        const dMoment = moment().add( item.options.autoTimeDiff, 'years');
                        item.options.dateModeYear = dMoment.get('year');
                        item.options.start = dMoment.startOf('year').format('YYYY-MM-DD');
                        item.options.end = dMoment.endOf('year').format('YYYY-MM-DD');
                    }
                }
                return item;
            });

            this.setState({
                loading: false,
                forms: forms,
            });
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    getSubmitData()
    {
        let data = {};
        const form = this.state.form;

        console.log( "This is a form: ", form );

        for (let key in form ) {
            switch (key) {
                case 'type':
                    data[ key ] = form[ key ].value;
                    break;
                case 'date':
                case 'start':
                case 'end':
                case 'depths':                
                    if( form[ key ] ) data[ key ] = form[ key ];
                    break;
                case 'ctype':
                    if( form[ key ] && form[ key ].length ) data[ key ] = form[ key ];
                    break;
                case 'splits':
                case 'columns':                
                case 'ttstatus':
                    data[ key ] = form[ key ].map( i => i.value );
                    break;
                case 'users':
                case 'components':
                case 'groups':
                case 'contacts':
                case 'projects':
                    data[ key ] = form[ key ].map( i => i.id );
                    break;
                default: break;
            }
        }

        console.log( "Data: ", data );

        return data;
    }

    selectForm( row )
    {
        let options = row.options;
        console.log('Options', options );
        this.setState({
            form: options,
            formSubmitted: null,
            data: [],
            related: {},
        }, () => {
            if( this.tabsRef.current )
                this.tabsRef.current.changeTab( 1 );
        });
    }

    formSave()
    {

        const formModal = this.state.formModal;

        const data  = {
            name: formModal.name,
            id: this.state.formModal.id || undefined,
        }

        if( !data.id )
        {
            const form = this.state.form;
            data.options = this.getSubmitData();

            if( formModal.autoTime )
            {
                data.options.autoTime = formModal.autoTimeMode;
                data.options.autoTimeDiff = formModal.autoTimeDiff;
                if( formModal.autoTimeMode === 'dates' )
                    data.options.autoTimeDiff2 = formModal.autoTimeDiff2;
            }
            else if( form.dateMode )
            {
                data.options.dateMode = form.dateMode;
                data.options.dateModeYear = form.dateModeYear;
                data.options.dateModeValue = form.dateModeValue;
            }
        }

        // console.log('d', formModal );
        // console.log('Savemodalsave', data.options )

        this.setState({ loading: true });

        api({
            method: 'post',
            url: `extranet/reports/saveReport`,
            errorPopper: 'Raportin tallentamisessa tapahtui virhe',
            data: data,
        }).then(( response ) => {
            console.log( 'Save', response );
            this.setState({
                loading: false,
                formModal: {}
            });
            this.fetchSavedForms();
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    fetchReport()
    {

        this.setState({ loading: true });
        let data = this.getSubmitData();

        api({
            method: 'post',
            url: `extranet/reports/getReport`,
            errorPopper: 'Raportin luomisessa tapahtui virhe',
            data: data,
        }).then(( response ) => {
            console.log( 'Report', response );
            // console.log( 'Data from response', response.data );

            this.setState({
                loading: false,
                data: response.data,
                related: response.related || {},
                formSubmitted: data,
            });

        }).catch( ( error ) => {
            this.setState({ loading: false });
            errorPopper(error, error.message);
        });
    }

    fetchExcel()
    {
        this.setState({ loading: true });
        const data = this.getSubmitData();

        api({
            method: 'post',
            url: 'extranet/reports/getExcel',
            responseType: 'blob',
            errorPopper: 'Tiedoston luomisessa tapahtui virhe',
            data: data,
        }).then(( response ) => {
            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Raportti.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                loading: false,
            });
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    setForm( data, func )
    {
        let form = { ...this.state.form, ...data };
        this.setState({ form: form }, func );
    }

    setType( value )
    {
        if( value === 'none')
            value = null;
        else
        {
            const options = types;
            value = options.find( f => f.value === value );
        }
        // Reset all form data
        this.setState({
            form: { type: value },
            formSubmitted: null,
        });
    }

    setSplits( values )
    {
        let depths = { ...this.state.depths };

        // Add new values to depths
        values.forEach( v => {
            if( v.depth && !depths[ v.value ])
                depths[ v.value ] = 0;
        });

        // Remove old values from depths
        for(let depth in depths )
        {
            let found = values.find( v => v.value === depth );
            if( !found ) delete depths[ depth ];
        }

        this.setForm({
            splits: values,
            depths: depths,
        });
    }

    renderSplitsSelect()
    {
        const options = keyExists( this.state.form, 'type.splitOptions', true, [] );
        if( !options.length ) return null;

        return <ApSelect key="rowsToRead"
            label="Erottelu sarakkeisiin"
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'splits', true, [] ) }
            onChange={ this.setSplits }
            objKeyId="value"
            multiselect
            options={ options }
            optionRenderer="label"
            objKeySearchable="label"
            valueRenderer="label"
        />
    }

    renderColumnSelect()
    {
        const options = keyExists( this.state.form, 'type.columnOptions', true, [] );
        if( !options.length ) return null;

        return <ApSelect key="columnsSelect"
            label="Muut sarakkeet"
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'columns', true, [] ) }
            onChange={ ( values ) => this.setForm({ columns: values }) }
            objKeyId="value"
            multiselect
            options={ options }
            optionRenderer="label"
            objKeySearchable="label"
            valueRenderer="label"
        />
    }

    renderDepthInputs()
    {
        let depths = keyExists( this.state.form, 'depths', true, '' )

        let doms = [];
        const titles = {
            project: 'Projekti syvyys',
        }
        for( let split in depths ) {
            doms.push( <div className="depthInputContainer" key={ split }>
                <ApInputStack gap="0">
                    <ApAddon noRightBorder>
                        { titles[ split ] }
                    </ApAddon>
                    <ApAddon width="50px">
                        <DepthInput depth={ depths[ split ] || 0 } onChange={ ( value ) => this.setDepth( split, value ) } />
                    </ApAddon>
                </ApInputStack>
            </div>);
        }
        return doms;
    }

    setDepth( name, value )
    {
        let depths = { ...this.state.depths };
        depths[ name ] = value;
        this.setForm({ depths: depths });
    }

    // ----------------------------------------
    //  Common options
    // ----------------------------------------
    
    // client group can be choosen
    renderClientGroup()
    {
        return <ApSelect key="clientGroupFilter"
            label="Asiakasryhmän tyyppi" 
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'groups', true, [] ) }
            valueRenderer="group"
            optionRenderer="group"
            onChange={ ( values ) => this.setForm({ groups: values }) }
            objKeyId="id"
            multiselect
            apiUrl="report/groups/find"
            apiData={{ exluded: [] }}         
        />
    }

    // contact group can be choosen
    renderContactGroup()
    {
        return <ApSelect key="contactContactFilter"
            label="Yhteystietoryhmän tyyppi" 
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'contacts', true, [] ) }
            valueRenderer="contact"
            optionRenderer="contact"
            onChange={ ( values ) => this.setForm({ contacts: values }) }
            objKeyId="id"
            multiselect
            apiUrl="report/contacts/find"
            apiData={{ exluded: [] }}
        />
    }
    
    // filters for client reports
    // do not work yets
    renderAccountManager()
    {
        return <ApSelect
            label="Asiakasvastaava"
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'users', true, [] ) }
            valueRenderer="user"
            optionRenderer="user"
            onChange={ ( values ) => this.setForm({ users: values }) }
            objKeyId="id"
            multiselect
            apiUrl="report/users/find"
            apiData={{ exluded: [] }}
        />
    }
    renderLocality()
    {
        return <ApSelect key="locality"
            label="Paikkakunta"
            
        />
    }
    renderIndustry()
    {
        return <ApSelect key="Industry"
            label="Toimialaluokka"
            
        />
    }
    renderTurnover()
    {
        return <ApSelect key="turnover"
            label="Liikevaihto"
            
        />
    }
    renderStaff()
    {
        return <ApSelect key="staff"
            label="Henkilöstö"
            
        />
    }



    renderUserFilter()
    {
        return <ApSelect key="userFilter"
            label="Henkilö"
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'users', true, [] ) }
            valueRenderer="user"
            optionRenderer="user"
            onChange={ ( values ) => this.setForm({ users: values }) }
            objKeyId="id"
            multiselect
            apiUrl="extranet/reports/users"
            apiData={{ exluded: [] }}
        />
    }

    renderComponentFilter()
    {
        return <ApSelect key="componentFilter"
            label="Työ"
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'components', true, [] ) }
            valueRenderer="component"
            optionRenderer="component_detail"
            onChange={ ( values ) => this.setForm({ components: values }) }
            objKeyId="id"
            multiselect
            apiUrl="extranet/reports/components"
            apiData={{
                type: 'work',
                status: 'all',
            }}
        />
    }

    renderProjectFilter()
    {
        return <ApSelect key="projectFilter"
            label="Projekti"
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'projects', true, [] ) }
            valueRenderer="project"
            optionRenderer="project"
            onChange={ ( values ) => this.setForm({ projects: values }) }
            objKeyId="id"
            multiselect
            apiUrl="extranet/reports/projects"
            apiData={{
                // formatter: 'select',
                // type: 'work',
                // status: 'all',
            }}
        />
    }

    setOptionBar( field, value )
    {
        let data = keyExists( this.state.form, field, true, [] );
        if( data.includes( value ))
            data = data.filter( f => f !== value );
        else
            data.push( value );
        this.setForm({ [ field ]: data });
    }

    renderOptionBar( field, options )
    {
        let selected = keyExists( this.state.form, field, true, [] );
        const optionDoms = options.map(( o, i ) => {
            let className = [ 'option' ];
            if( selected.includes( o.value ))
                className.push('selected ');

            return <div className={ className.join(' ') } onClick={ () => this.setOptionBar( field, o.value ) } >
                <ApTooltip text={ o.tooltip ? o.tooltip : null } block position="topright">
                    { o.label }
                </ApTooltip>
            </div>
        })
        return <div>
            <div className={`apOptionBar count-${ options.length }`}>
                { optionDoms }
            </div>
        </div>
    }

    // ----------------------------------------
    //  Date options
    // ----------------------------------------

    setDateMode( mode )
    {
        let year = null;
        let value = null;
        let start = null;
        let end = null;

        if( mode === 'week' )
        {
            year = moment().get('year');
            value = moment().year( year ).get('week');

            start = moment().startOf('week').format('YYYY-MM-DD');
            end = moment().endOf('week').format('YYYY-MM-DD');
        }
        else if( mode === 'month' )
        {
            year = moment().get('year');
            value = moment().year( year ).get('month');

            start = moment().startOf('month').format('YYYY-MM-DD');
            end = moment().endOf('month').format('YYYY-MM-DD');
        }
        else if( mode === 'year' )
        {
            year = moment().get('year');

            start = moment().startOf('year').format('YYYY-MM-DD');
            end = moment().endOf('year').format('YYYY-MM-DD');
        }

        this.setForm({
            dateMode: mode,
            dateModeYear: year,
            dateModeValue: value,

            start: start,
            end: end,
        });
    }

    setDateYear( year )
    {
        const mode = this.state.form.dateMode;
        const yMoment = moment().year( year );

        let start = null;
        let end = null;
        let value = null;

        if( mode === 'week' )
        {
            if( parseInt( year, 10 ) === this.currentYear )
                value = yMoment.get('week');
            else
                value = 1;

            start = yMoment.week( value ).startOf('week').format('YYYY-MM-DD');
            end = yMoment.week( value ).endOf('week').format('YYYY-MM-DD');

        }
        else if( mode === 'month' )
        {
            if( parseInt( year, 10 ) === this.currentYear )
                value = yMoment.get('month');
            else
                value = 0;

            start = yMoment.month( value ).startOf('month').format('YYYY-MM-DD');
            end = yMoment.month( value ).endOf('month').format('YYYY-MM-DD');
        }
        else if( mode === 'year' )
        {
            start = yMoment.startOf('year').format('YYYY-MM-DD');
            end = yMoment.endOf('year').format('YYYY-MM-DD');
        }

        this.setForm({
            dateModeYear: year,
            dateModeValue: value,
            start: start,
            end: end,
        });
    }

    setDateValue( value )
    {
        const mode = this.state.form.dateMode;
        const yMoment = moment().year( this.state.form.dateModeYear );

        let start = null;
        let end = null;

        if( mode === 'week' )
        {
            start = yMoment.week( value ).startOf('week').format('YYYY-MM-DD');
            end = yMoment.week( value ).endOf('week').format('YYYY-MM-DD');

        }
        else if( mode === 'month' )
        {
            start = yMoment.month( value ).startOf('month').format('YYYY-MM-DD');
            end = yMoment.month( value ).endOf('month').format('YYYY-MM-DD');
        }

        this.setForm({
            dateModeValue: value,
            start: start,
            end: end,
        });
    }

    getYearsList()
    {
        let data = [];
        for (let i = this.startYear; i <= this.endYear; i++)
        {
            let label = i;

            if( i === this.currentYear )
                label = `${ label } *`;

            data.push( { value: i, label: label } );
        }
        return data;
    }

    getMonthList()
    {
        let now = moment();
        let yMoment = moment().year( this.state.form.dateModeYear );
        const data = [];
        for (var i = 0; i < 12; i++)
        {
            let month = yMoment.month( i );
            let start = month.startOf('month').format('D.M.');
            let end = month.endOf('month').format('D.M.');

            let label = `${ month.format('MMMM') } ( ${ start } - ${ end })`;
            if( month.isSame(now, 'month') )
                label = `${ label } *`;

            data.push({ value: i, label: label });
        }
        return data;
    }

    getWeekList()
    {
        let now = moment();
        let yMoment = moment().year( this.state.form.dateModeYear );
        const data = [];
        for (var i = 1; i <= yMoment.weeksInYear() ; i++)
        {
            let week = yMoment.week( i );
            let start = week.startOf('week').format('D.M.');
            let end = week.endOf('week').format('D.M.');

            let label = `Viikko ${ i } ( ${ start } - ${ end })`;
            if( week.isSame(now, 'week') )
                label = `${ label } *`;

            data.push({ value: i, label: label });
        }
        return data;
    }

    renderDates()
    {
        let dateModeDom = <ApInput
            className="dateMode"
            type="select"
            id="dateMode"
            name="dateMode"
            value={ keyExists( this.state.form, 'dateMode', true, 'none' ) }
            options={ dateModeOptions }
            onChange={ ( e ) => { this.setDateMode( e.target.value ) } }
            loading={ this.state.loading }
            disabled={ this.state.loading }
            // width="300px"
        />

        const yearOptions = this.getYearsList();
        let dateYearDom  = <ApInput
            type="select"
            id="dateYearSelect"
            name="dateYear"
            value={ keyExists( this.state.form, 'dateModeYear', true, null ) }
            options={ yearOptions }
            onChange={ ( e ) =>{ this.setDateYear( e.target.value ) } }
            loading={ this.state.loading }
            disabled={ this.state.loading }
        />

        switch ( this.state.form.dateMode ) {
            case 'dates':
                return <div className="reportDates" key="dateFilter">
                    <ApInputStack gap="0">
                        { dateModeDom }
                        <ApInput
                            type="dateSafe"
                            name="start"
                            id="start"
                            value={ keyExists( this.state.form, 'start', true, null ) }
                            label="Alkaen"
                            onChange={ ( e ) => { this.setForm({ start: e.target.value }) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                            clearable
                        />
                        <ApAddon width="20" noLeftBorder noRightBorder> - </ApAddon>
                        <ApInput
                            type="dateSafe"
                            name="end"
                            id="end"
                            value={ keyExists( this.state.form, 'end', true, null ) }
                            label="Päättyen"
                            onChange={ ( e ) => { this.setForm({ end: e.target.value }) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                            clearable
                        />
                    </ApInputStack>
                </div>

            case 'week':
                return <div className="reportDates" key="dateFilter">
                    <ApInputStack gap="0">
                        { dateModeDom }
                        { dateYearDom }
                        <ApInput
                            type="select"
                            id="dateWeek"
                            name="dateWeek"
                            value={ keyExists( this.state.form, 'dateModeValue', true, null ) }
                            options={ this.getWeekList() }
                            onChange={ ( e ) =>{ this.setDateValue( e.target.value ) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                    </ApInputStack>
                </div>

            case 'month':
                return <div className="reportDates" key="dateFilter">
                    <ApInputStack gap="0">
                        { dateModeDom }
                        { dateYearDom }
                        <ApInput
                            type="select"
                            id="dateMonth"
                            name="dateMonth"
                            value={ keyExists( this.state.form, 'dateModeValue', true, null ) }
                            options={ this.getMonthList() }
                            onChange={ ( e ) =>{ this.setDateValue( e.target.value ) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                    </ApInputStack>
                </div>
            case 'year':
                return <div className="reportDates" key="dateFilter">
                    <ApInputStack gap="0">
                        { dateModeDom }
                        { dateYearDom }
                    </ApInputStack>
                </div>
            default:
                return <div className="reportDates" key="dateFilter">
                    <ApInputStack gap="0">
                        { dateModeDom }
                    </ApInputStack>
                </div>
        }

    }

    renderDate()
    {
        return <ApInput
            type="dateSafe"
            name="date"
            id="date"
            value={ keyExists( this.state.form, 'date', true, null ) }
            label="Päivään asti"
            onChange={ ( e ) => { this.setForm({ date: e.target.value }) } }
            loading={ this.state.loading }
            disabled={ this.state.loading }
            clearable
            key="date"
        />
    }

    // ----------------------------------------
    //  Table
    // ----------------------------------------

    getColumnsTt()
    {
        const formSubmitted = this.state.formSubmitted;
        const related = this.state.related;

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        let columns = [];

        if( splitIncludes( 'project' ) )
        {
            columns.push( {
                Header: `Projekti`,
                columns: [{
                    id: 'project_name',
                    Header: 'Projekti nimi',
                    accessor: 'project.full_name',
                }, {
                    id: 'project_code',
                    Header: 'Projekti koodi',
                    accessor: 'project.project_code',
                }],
            });
        }

        if( splitIncludes( 'user' ) )
        {
            columns.push( {
                Header: `Henkilö`,
                columns: [{
                    id: 'user_name',
                    Header: 'Nimi',
                    accessor: 'user.name',
                }],
            });
        }

        if( splitIncludes( 'description' ) )
        {
            columns.push( {
                Header: `Kuvaus`,
                columns: [{
                    id: 'description',
                    Header: 'Kuvaus',
                    accessor: 'description'
                }],
            });
        }

        if( splitIncludes( 'component' ) )
        {
            columns.push( {
                Header: `Työnimike`,
                columns: [{
                    id: 'component_name',
                    Header: 'Nimi',
                    accessor: 'component.name',
                }, {
                    id: 'component_code',
                    Header: 'Työn varastokoodi',
                    accessor: 'component.code',
                }],
            });
        }

        if( splitIncludes( 'date' ) )
        {
            columns.push( {
                Header: `Päivä`,
                columns: [{
                    id: 'date',
                    Header: 'Päivä',
                    accessor: 'date',
                    filterable: false,

                    Cell: props => {
                        return <div>{ moment( props.value ).format('DD.MM.YYYY') }</div>
                    },
                }],
            });
        }

        if( splitIncludes( 'customer_work_number' ) )
        {
            columns.push( {
                Header: 'Asiakastyönumero',
                columns: [{
                    id: 'customer_work_number',
                    Header: 'Asiakastyönumero',
                    accessor: 'customer_work_number',
                }],
            });
        }

        if( splitIncludes( 'customer' ) )
        {
            columns.push( {
                Header: 'Asiakas',
                columns: [{
                    id: 'customer',
                    Header: 'Asiakas',
                    accessor: 'customer.name',
                }],
            });
        }

        if( related.hour_rows )
        {
            const sCols = related.hour_rows.map( item => {
                return colPreset({
                    type: "numberNice",
                    decimals: 2,
                    unit: 'h',

                    id: `hour_rows-${ item.id }`,
                    Header: item.name,
                    filterable: false,
                    accessor: ( row ) => {
                        if( row.hour_rows[ item.id ] )
                            return row.hour_rows[ item.id ].hours;
                        return null;
                    }
                })
            })
            columns.push( {
                Header: `Tunnit`,
                columns: sCols,
            });
        }

        if( related.project_expense_chargeables )
        {
            const sCols = related.project_expense_chargeables.map( item => {

                return colPreset({
                    type: "numberNice",
                    decimals: 2,

                    id: `chargeable-${ item.id }`,
                    Header: item.name,
                    filterable: false,
                    accessor: ( row ) => {
                        if( row.project_expense_chargeables[ item.id ] )
                            return row.project_expense_chargeables[ item.id ].quantity;
                        return null;
                    }
                })
            })
            columns.push( {
                Header: 'Laskutettavat kustannukset',
                columns: sCols,
            });
        }

        if( related.project_component_installations )
        {
            related.project_component_installations.forEach( item => {
                let sColumns = [];

                sColumns.push(colPreset({
                    type: "numberNice",
                    decimals: 2,
                    id: `installations-${ item.id }-quantity`,
                    Header: 'Määrä',
                    filterable: false,
                    accessor: ( row ) => {
                        let data = row.project_component_installations[ item.id ];
                        if( data ) return data.quantity;
                        return null;
                    },
                }));

                sColumns.push(colPreset({
                    type: "numberNice",
                    decimals: 2,
                    id: `installations-${ item.id }-hours`,
                    Header: 'Tunnit',
                    filterable: false,
                    accessor: ( row ) => {
                        let data = row.project_component_installations[ item.id ];
                        if( data ) return data.hours;
                        return null;
                    },
                }));

                if( related.trackingunits )
                {
                    related.trackingunits.forEach( tu => {
                        sColumns.push(colPreset({
                            type: "numberNice",
                            decimals: 2,
                            id: `installations-${ item.id }-tu-${ tu.id }`,
                            Header: tu.name,
                            accessor: ( row ) => {
                                let data = row.project_component_installations[ item.id ];
                                if( !data ) return null;
                                if( !data.trackingunits ) return null;
                                if( !data.trackingunits[ tu.id ] ) return null;
                                return data.trackingunits[ tu.id ].value;
                            },
                        }));
                    });
                }
                columns.push( {
                    Header: `"${ item.name }" asennus`,
                    columns: sColumns,
                });
            })
        }

        let absenceCols = [];
        if( splitIncludes( 'absence' ) )
        {
            absenceCols.push({
                id: 'absence_reason',
                Header: 'Poissaolon syy',
                accessor: 'absence.name',
            });
        }
        if( columnsIncludes( 'absence' ) )
        {
            absenceCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: 'h',

                id: 'hours_absence',
                Header: 'Poissaolon määrä',
                accessor: 'hours_absence',
                filterable: false,
            }));
        }
        if( absenceCols.length )
        {
            columns.push( {
                Header: 'Poissaolot',
                columns: absenceCols,
            });
        }

        return columns;
    }

    getColumnsTtTravel()
    {
        const formSubmitted = this.state.formSubmitted;
        const related = this.state.related;

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        let columns = [];

        if( splitIncludes( 'project' ) )
        {
            columns.push( {
                Header: `Projekti`,
                columns: [{
                    id: 'project_name',
                    Header: 'Projekti nimi',
                    accessor: 'project.full_name',
                }, {
                    id: 'project_code',
                    Header: 'Projekti koodi',
                    accessor: 'project.project_code',
                }],
            });
        }

        if( splitIncludes( 'user' ) )
        {
            columns.push( {
                Header: `Henkilö`,
                columns: [{
                    id: 'user_name',
                    Header: 'Nimi',
                    accessor: 'user.name',
                }],
            });
        }

        if( splitIncludes( 'date' ) )
        {
            columns.push( {
                Header: `Päivä`,
                columns: [{
                    id: 'date',
                    Header: 'Päivä',
                    accessor: 'date',
                    filterable: false,

                    Cell: props => {
                        return <div>{ moment( props.value ).format('DD.MM.YYYY') }</div>
                    },
                }],
            });
        }

        if( splitIncludes( 'customer' ) )
        {
            columns.push( {
                Header: 'Asiakas',
                columns: [{
                    id: 'customer',
                    Header: 'Asiakas',
                    accessor: 'customer.name',
                }],
            });
        }

        if( splitIncludes( 'route' ) )
        {
            columns.push( {
                id: 'route',
                Header: 'Reitti',
                accessor: 'route',
            });
        }

        if( columnsIncludes( 'hours' ) )
        {
            columns.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: 'h',

                id: 'travel_hours',
                Header: 'Matka-aika',
                accessor: 'hours',
                filterable: false,
            }));
        }

        if( columnsIncludes( 'distance' ) )
        {
            columns.push(colPreset({
                type: "numberNice",
                decimals: 0,
                unit: 'km',

                id: 'distance',
                Header: 'Kilometrit',
                accessor: 'distance',
                filterable: false,
            }));
        }

        if( columnsIncludes( 'compensation_total' ) )
        {
            columns.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: this.state.currencySign,

                id: 'compensation_total',
                Header: 'Korvaus',
                accessor: 'compensation_total',
                filterable: false,
            }));
        }


        return columns;
    }

    getColumnsTtExpense()
    {
        const formSubmitted = this.state.formSubmitted;
        const related = this.state.related;

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        let columns = [];

        if( splitIncludes( 'project' ) )
        {
            columns.push( {
                Header: `Projekti`,
                columns: [{
                    id: 'project_name',
                    Header: 'Projekti nimi',
                    accessor: 'project.full_name',
                }, {
                    id: 'project_code',
                    Header: 'Projekti koodi',
                    accessor: 'project.project_code',
                }],
            });
        }

        if( splitIncludes( 'user' ) )
        {
            columns.push( {
                Header: `Henkilö`,
                columns: [{
                    id: 'user_name',
                    Header: 'Nimi',
                    accessor: 'user.name',
                }],
            });
        }

        if( splitIncludes( 'date' ) )
        {
            columns.push( {
                Header: `Päivä`,
                columns: [{
                    id: 'date',
                    Header: 'Päivä',
                    accessor: 'date',
                    filterable: false,

                    Cell: props => {
                        return <div>{ moment( props.value ).format('DD.MM.YYYY') }</div>
                    },
                }],
            });
        }

        if( splitIncludes( 'customer' ) )
        {
            columns.push( {
                Header: 'Asiakas',
                columns: [{
                    id: 'customer',
                    Header: 'Asiakas',
                    accessor: 'customer.name',
                }],
            });
        }

        if( splitIncludes( 'event' ) )
        {
            columns.push( {
                id: 'event',
                Header: 'Tapahtuma',
                accessor: 'event',
            });
        }
        if( splitIncludes( 'event_details' ) )
        {
            columns.push( {
                id: 'event_details',
                Header: 'Tapahtuma tiedot',
                accessor: 'event_details',
            });
        }

        if( columnsIncludes( 'money_expense' ) )
        {
            columns.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: this.state.currencySign,

                id: 'money_expense',
                Header: 'Kustannus',
                accessor: 'money_expense',
                filterable: false,
            }));
        }

        return columns;
    }


    getColumnsProject()
    {
        const formSubmitted = this.state.formSubmitted;
        // const related = this.state.related;

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        let columns = [];

        if( splitIncludes( 'project' ) )
        {
            columns.push( {
                Header: `Projekti`,
                columns: [{
                    id: 'project_name',
                    Header: 'Projekti nimi',
                    accessor: 'full_name',
                }, {
                    id: 'project_code',
                    Header: 'Projekti koodi',
                    accessor: 'project_code',
                }],
            });
        }

        let workCols = [];
        if( splitIncludes( 'work' ) )
        {
            workCols.push( {
                id: 'work_name',
                Header: 'Nimi',
                accessor: 'work.name',
            });
        }
        if( splitIncludes( 'work_person' ) )
        {
            workCols.push( {
                id: 'work_person_name',
                Header: 'Kirjaaja',
                accessor: 'work_person.name',
            });
        }

        if( columnsIncludes( 'work_alloc' ) )
        {
            workCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: 'h',
                id: 'work_alloc',
                Header: 'Allokoitu',
                accessor: 'work_alloc',
            }))

        }
        if( columnsIncludes( 'work_goal' ) )
        {
            workCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: 'h',
                id: 'work_goal',
                Header: 'Tavoite',
                accessor: 'work_goal',
            }))
        }
        if( columnsIncludes( 'work_entry' ) )
        {
            workCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: 'h',
                id: 'work_entry',
                Header: 'Kirjattu',
                accessor: 'work_entry',
            }))
        }
        if( columnsIncludes( 'work_remaining' ) )
        {
            workCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: 'h',
                id: 'work_remaining',
                Header: 'Jäljellä',
                accessor: ( row ) => { return row.work_alloc - row.work_entry; }
            }))
        }
        if( columnsIncludes( 'work_alloc_used_percent' ) )
        {
            workCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: 'h',
                id: 'work_alloc_used_percent',
                Header: 'Työ käytetty %',
                accessor: ( row ) => {
                    if( !row.work_alloc ) return null;
                    return row.work_entry / row.work_alloc * 100 ;
                }
            }))
        }
        if( columnsIncludes( 'work_estimate_count' ) )
        {
            workCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: 'h',
                id: 'work_estimate_count',
                Header: 'Arvio tunnit',
                accessor: 'work_estimate_count',
            }))
        }
        if( columnsIncludes( 'work_estimate_percent' ) )
        {
            workCols.push(colPreset({
                type: "percent",
                id: 'work_estimate_percent',
                Header: 'Arvio %',
                accessor: ( row ) => {
                    if( !row.work_alloc ) return null;
                    return row.work_estimate_count / row.work_alloc * 100 ;
                }
            }));
        }

        if( columnsIncludes( 'work_future_difference_percent' ) )
        {
            workCols.push(colPreset({
                type: "percent",
                id: 'work_future_difference_percent',
                Header: 'Ylitys / Alitus %',
                accessor: ( row ) => {
                    if( !row.work_alloc ) return null;
                    let futureCount = row.work_entry / ( row.work_estimate_count / row.work_alloc );
                    return ( row.work_alloc / futureCount -1 ) * 100;
                }
            }));
        }

        if( workCols.length )
        {
            columns.push( {
                Header: `Työt`,
                columns: workCols,
            });
        }

        let itemCols = [];

        if( splitIncludes( 'item' ) )
        {
            itemCols.push( {
                id: 'item_name',
                Header: 'Nimi',
                accessor: 'item.name',
            });
        }

        if( columnsIncludes( 'item_alloc' ) )
        {
            itemCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                id: 'item_alloc',
                Header: 'Allokoitu',
                accessor: 'item_alloc',
            }))
        }

        if( columnsIncludes( 'item_installed_count' ) )
        {
            itemCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                id: 'item_installed_count',
                Header: 'Asennettu',
                accessor: 'item_installed_count',
            }))

        }
        if( columnsIncludes( 'item_installed_percent' ) )
        {
            itemCols.push(colPreset({
                type: "percent",
                id: 'item_installed_percent',
                Header: 'Asennettu %',
                accessor: ( row ) => {
                    if( !row.item_alloc ) return null;
                    return ( row.item_installed_count / row.item_alloc ) * 100;
                }
            }))
        }


        if( itemCols.length )
        {
            columns.push( {
                Header: `Komponentit`,
                columns: itemCols,
            });
        }

        let expenseCols = [];

        if( splitIncludes( 'expense' ) )
        {
            expenseCols.push( {
                id: 'expense_name',
                Header: 'Nimi',
                accessor: 'expense.name',
            });
        }
        if( columnsIncludes( 'expense_alloc' ) )
        {
            expenseCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                id: 'expense_alloc',
                Header: 'Allokoitu',
                accessor: 'expense_alloc',
            }))
        }
        if( columnsIncludes( 'expense_chargeable' ) )
        {
            expenseCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                id: 'expense_chargeable',
                Header: 'Laskutettava',
                accessor: 'expense_chargeable',
            }))
        }
        if( expenseCols.length )
        {
            columns.push( {
                Header: `Kustannukset`,
                columns: expenseCols,
            });
        }
        return columns;
    }

    submitHas( field, value )
    {
        const formSubmitted = this.state.formSubmitted;

        if( field === 'split' )
        {
            const splitIncludes = ( f ) => {
                if( !formSubmitted.splits ) return false;
                return formSubmitted.splits.includes( f );
            }
            return splitIncludes(  value );
        }
        if( field === 'columns' )
        {
            const columnsIncludes = ( f ) => {
                if( !formSubmitted.columns ) return false;
                return formSubmitted.columns.includes( f );
            }
            return  columnsIncludes(  value );
        }
        return false;
    }

    getColumnsComponent()
    {
        // const related = this.state.related;
        let columns = [];

        if( this.submitHas( 'split', 'component' ) )
        {
            columns.push( {
                Header: `Varastonimikkeet`,
                columns: [{
                    id: 'name',
                    Header: 'Nimi',
                    accessor: 'name',
                }, {
                    id: 'code',
                    Header: 'Koodi',
                    accessor: 'code',
                }],
            });
        }
        return columns;
    }

    getColumnsProjectInstallation()
    {
        const formSubmitted = this.state.formSubmitted;
        const related = this.state.related;

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        let columns = [];


        /*
        if( splitIncludes( 'user' ) )
        {
            columns.push( {
                Header: `Henkilö`,
                columns: [{
                    id: 'user_name',
                    Header: 'Nimi',
                    accessor: 'user.name',
                }],
            });
        }

        if( splitIncludes( 'component' ) )
        {
            columns.push( {
                Header: `Työnimike`,
                columns: [{
                    id: 'component_name',
                    Header: 'Nimi',
                    accessor: 'component.name',
                }, {
                    id: 'component_code',
                    Header: 'Työn varastokoodi',
                    accessor: 'component.code',
                }],
            });
        }

        if( splitIncludes( 'date' ) )
        {
            columns.push( {
                Header: `Päivä`,
                columns: [{
                    id: 'date',
                    Header: 'Päivä',
                    accessor: 'date',
                    filterable: false,

                    Cell: props => {
                        return <div>{ moment( props.value ).format('DD.MM.YYYY') }</div>
                    },
                }],
            });
        }

        if( splitIncludes( 'customer_work_number' ) )
        {
            columns.push( {
                Header: 'Asiakastyönumero',
                columns: [{
                    id: 'customer_work_number',
                    Header: 'Asiakastyönumero',
                    accessor: 'customer_work_number',
                }],
            });
        }

        if( splitIncludes( 'customer' ) )
        {
            columns.push( {
                Header: 'Asiakas',
                columns: [{
                    id: 'customer',
                    Header: 'Asiakas',
                    accessor: 'customer.name',
                }],
            });
        }

        if( related.hour_rows )
        {
            const sCols = related.hour_rows.map( item => {
                return colPreset({
                    type: "numberNice",
                    decimals: 2,
                    unit: 'h',

                    id: `hour_rows-${ item.id }`,
                    Header: item.name,
                    filterable: false,
                    accessor: ( row ) => {
                        if( row.hour_rows[ item.id ] )
                            return row.hour_rows[ item.id ].hours;
                        return null;
                    }
                })
            })
            columns.push( {
                Header: `Tunnit`,
                columns: sCols,
            });
        }
        */

        /*
        if( related.project_expense_chargeables )
        {
            const sCols = related.project_expense_chargeables.map( item => {

                return colPreset({
                    type: "numberNice",
                    decimals: 2,

                    id: `chargeable-${ item.id }`,
                    Header: item.name,
                    filterable: false,
                    accessor: ( row ) => {
                        if( row.project_expense_chargeables[ item.id ] )
                            return row.project_expense_chargeables[ item.id ].quantity;
                        return null;
                    }
                })
            })
            columns.push( {
                Header: 'Laskutettavat kustannukset',
                columns: sCols,
            });
        }

        if( related.project_component_installations )
        {
            related.project_component_installations.map( item => {
                let sColumns = [];

                sColumns.push(colPreset({
                    type: "numberNice",
                    decimals: 2,
                    id: `installations-${ item.id }-quantity`,
                    Header: 'Määrä',
                    filterable: false,
                    accessor: ( row ) => {
                        let data = row.project_component_installations[ item.id ];
                        if( data ) return data.quantity;
                        return null;
                    },
                }));

                sColumns.push(colPreset({
                    type: "numberNice",
                    decimals: 2,
                    id: `installations-${ item.id }-hours`,
                    Header: 'Tunnit',
                    filterable: false,
                    accessor: ( row ) => {
                        let data = row.project_component_installations[ item.id ];
                        if( data ) return data.hours;
                        return null;
                    },
                }));

                if( related.trackingunits )
                {
                    related.trackingunits.map( tu => {
                        sColumns.push(colPreset({
                            type: "numberNice",
                            decimals: 2,
                            id: `installations-${ item.id }-tu-${ tu.id }`,
                            Header: tu.name,
                            accessor: ( row ) => {
                                let data = row.project_component_installations[ item.id ];
                                if( !data ) return null;
                                if( !data.trackingunits ) return null;
                                if( !data.trackingunits[ tu.id ] ) return null;
                                return data.trackingunits[ tu.id ].value;
                            },
                        }));
                    });
                }
                columns.push( {
                    Header: `"${ item.name }" asennus`,
                    columns: sColumns,
                });
            })
        }

        let absenceCols = [];
        if( splitIncludes( 'absence' ) )
        {
            absenceCols.push({
                id: 'absence_reason',
                Header: 'Poissaolon syy',
                accessor: 'absence.name',
            });
        }
        if( columnsIncludes( 'absence' ) )
        {
            absenceCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: 'h',

                id: 'hours_absence',
                Header: 'Poissaolon määrä',
                accessor: 'hours_absence',
                filterable: false,
            }));
        }
        if( absenceCols.length )
        {
            columns.push( {
                Header: 'Poissaolot',
                columns: absenceCols,
            });
        }
        */

        if( splitIncludes( 'component' ) )
        {
            columns.push( {
                Header: `Varastonimike`,
                columns: [{
                    id: 'component_name',
                    Header: 'Nimi',
                    accessor: 'component.name',
                }, {
                    id: 'component_code',
                    Header: 'Koodi',
                    accessor: 'component.code',
                }],
            });
        }

        if( splitIncludes( 'project' ) )
        {
            columns.push( {
                Header: `Projekti`,
                columns: [{
                    id: 'project_name',
                    Header: 'Projektin nimi',
                    accessor: 'project.full_name',
                }, {
                    id: 'project_code',
                    Header: 'Projektikoodi',
                    accessor: 'project.project_code',
                }],
            });
        }

        if( splitIncludes( 'user' ) )
        {
            columns.push( {
                Header: `Henkilö`,
                columns: [{
                    id: 'user_name',
                    Header: 'Nimi',
                    accessor: 'user.name',
                }],
            });
        }


        let dataCols = [];
        if( columnsIncludes( 'hours' ) )
        {
            dataCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                id: 'hours_hours',
                Header: 'Aika',
                accessor: 'hours',
                unit: 'h',
            }));
        }

        if( columnsIncludes( 'quantity' ) )
        {
            dataCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                id: 'hours_quantity',
                Header: 'Määrä',
                accessor: 'quantity',
                unit: r => r.unit,
            }));
        }

        if( dataCols.length )
        {
            columns.push({
                Header: 'Asennus',
                columns: dataCols,
            });
        }

        let tuCols = [];
        if( related.trackingunits )
        {
            related.trackingunits.forEach( tu => {
                tuCols.push(colPreset({
                    type: "numberNice",
                    decimals: 2,
                    id: `tu-${ tu.id }`,
                    Header: tu.name,
                    accessor: ( row ) => {
                        if( !row.trackingunits ) return null;
                        if( !row.trackingunits[ tu.id ] ) return null;
                        return row.trackingunits[ tu.id ].value;
                    },
                    unit: tu.unit,

                }));
            });
        }
        columns.push({
            Header: 'Seurantayksiköt',
            columns: tuCols,
        });


        return columns;
    }

    getColumnsClient()
    {
        const formSubmitted = this.state.formSubmitted;
        const related = this.state.related;

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        let columns = [];

        if( splitIncludes( 'clientGroup' ) && !splitIncludes( 'contactGroup' ) )
        {
            columns.push( {
                Header: `Asikasryhmät`,
                columns: [{
                    id: 'name',
                    Header: 'Yrityksen nimi',
                    accessor: 'name',
                }],
            });
        }        

        if( splitIncludes( 'contactGroup' ) )
        {
            columns.push( {
                Header: `Yhteystietoryhmät`,
                columns: [{
                    id: 'first_name',
                    Header: 'Etunimi',
                    accessor: 'first_name',
                },{
                    id: 'last_name',
                    Header: 'Sukunimi',
                    accessor: 'last_name',
                }],
            });
        }

        // Columns for contact details: email, phone, website, zip
        let contactCols = [];
        if( columnsIncludes( 'email' ) )
        {
           contactCols.push({
                id: 'email',
                Header: 'Sähköposti',
                accessor: 'email',
            })

        }
      
        if( contactCols.length )
        {
            columns.push( {
                Header: `Yhteystiedot`,
                columns: contactCols,
            });
        }

        // Columns for group names: vip, CEO
        //console.log(this.state.form.groups[0]['name']);

        // toimitusjohtajat vip-ryhmistä -haku onnistuu
        // mutta ei vip-ryhmiä --- sitä varten pitäisi olla xxxx.groups[0]['name']
        let groupTypeCols = [];
        if( columnsIncludes( 'clientGroupType' ) )
            {
                groupTypeCols.push({
                id: 'clientGroups',
                Header: 'Asiakasryhmän tyyppi',
                accessor: (xxxx) => {
                    console.log('this is accessor: ', xxxx);
                   return xxxx.groups;
                },
            })

        }

        if( columnsIncludes( 'contactGroupType' ) )
            {
                groupTypeCols.push({
                id: 'contactGroups',
                Header: 'Yhteystietoryhmän tyyppi',
                accessor: (rrrr) => {
                    console.log(rrrr);
                    return rrrr.contacts[0]['name'];
                 },
                
                
                
            })

        }
        

        if( groupTypeCols.length )
            {
            columns.push({
                Header: `Valitut ryhmät`,
                columns: groupTypeCols,
            });
        }
        
        return columns; 
    }

    getColumns()
    {
        switch( this.state.formSubmitted.type )
        {
            case 'tt':
                return this.getColumnsTt();
            case 'tt_travel':
                return this.getColumnsTtTravel();
            case 'tt_expense':
                return this.getColumnsTtExpense();
            case 'project':
                return this.getColumnsProject();
            case 'component':
                return this.getColumnsComponent();
            case 'project_installation_entries':
                return this.getColumnsProjectInstallation();
            case 'clients':
                return this.getColumnsClient();
            default:
                return [];
        }
    }

    renderTable()
    {
        if( !this.state.formSubmitted ) return null;
        return <ApReactTable
            columns={ this.getColumns() }
            data={ this.state.data }
            loading={ this.state.loading }

            filterable
        />
    }

    // ----------------------------------------
    //  Saved forms
    // ----------------------------------------


    deleteForm()
    {
        const id = this.state.formDelete.id;
        this.setState({ loading: true });
        api({
            method: 'post',
            url: `extranet/reports/forms/delete/${ id }`,
            errorPopper: 'Tallennetun pohjan poistamisessa tapahtui virhe',
        }).then(( response ) => {
            this.setState({ loading: false });
            this.fetchSavedForms();
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });

    }

    renderTemplateList()
    {
        return <div>
            <ApReactTable
                columns={[{
                    id: 'name',
                    Header: 'Nimi',
                    accessor: 'name',
                    onClick: ( row ) => { this.selectForm( row )}
                },
                colPreset({
                        type:       "date",
                        id:         "date",
                        Header:     "Luotu",
                        accessor:   "date",
                        width: 200,
                }),
                ]}

                data={ this.state.forms }
                loading={ this.state.loading }
                filterable
                rowActions={[
                    {
                        icon: "edit",
                        label: "Nimeä uudelleen",
                        action: (id) => {
                            let row = this.state.forms.find( f => f.id === id );
                            this.setState({ formModal: {
                                show: true,
                                id: row.id,
                                name: row.name,
                            } });
                        },
                    },{
                        icon: "trash-alt",
                        label: "Poista",
                        action: (id) => { this.setState({
                            formDelete: {
                                show: true,
                                id: id,
                            }
                        })},
                    }
                ]}
            />

            <ApConfirm
                show={Boolean( this.state.formDelete.show ) }
                header="Poistetaanko projekti?"
                body="Oletko varma että haluat poistaa tallennetun pohjan?"
                onConfirm={this.deleteForm }
                onClose={ () => this.setState({ formDelete: {} })}
            />
        </div>
    }

    // ----------------------------------------
    //  Form modal
    // ----------------------------------------

    setFormModal( data, func )
    {
        let formModal = { ...this.state.formModal, ...data };
        this.setState({ formModal: formModal }, func );
    }

    setFormModalDiff( field, value )
    {
        let regExp = /[^0-9-]/g;
        value = value.replace(regExp, '');
        this.setFormModal({ [ field ]: value });
    }

    renderFormModal()
    {
        const formModal = this.state.formModal;

        let valid = true;
        let autoTimeDom = null;

        if(formModal.show && !formModal.id && [ 'tt' ].includes( keyExists( this.state, "form.type.value", true, null ) ))
        {
            let timeForm = null;
            const timeOptions = [
                { value: 'none', label: 'Valitse aikarajaus' },
                { value: 'dates', label: 'Aikaväli' },
                { value: 'week', label: 'Viikko' },
                { value: 'month', label: 'Kuukausi' },
                { value: 'year', label: 'Vuosi' },
            ];

            let info = null;
            let diff = null;
            let diff2 = null;

            if( keyExists( formModal, 'autoTimeMode', true, 'none' ) !== 'none' )
            {
                diff = <ApInput
                    id="diff"
                    type="text"
                    name="diff"
                    label={ ( formModal.autoTimeMode === 'dates' ) ? "Alkaen" : '' }
                    value={ keyExists( formModal, "autoTimeDiff", true, 0 ) }
                    onChange={ ( e ) => { this.setFormModalDiff( "autoTimeDiff", e.target.value ) } }
                    // validationState={ keyExists( formModal, "autoTimeDiff", true, 0 ) ? "" : "error" }
                />

                let infos = {
                    dates: 'Aikaraja päivissä. Esimerkiksi: Arvo -7 tarkoittaa 7 päivää aikaisemmin raportin haku päivää. Arvo 0 tarkoittaa tätä päivää. Arvo 1 tarkoittaa huomista.',
                    week: 'Aikaraja viikoissa. Esimerkiksi: Arvo -1 tarkoittaa viimeviikkoa. Arvo 0 tarkoittaa tätä viikkoa. Arvo 1 tarkoittaa ensiviikkoa.',
                    month: 'Aikaraja kuukauksissa. Esimerkiksi Arvo -1 tarkoittaa viimekuukautta. Arvo 0 tarkoitta tätä kuukautta. Arvo 1 ensikuukautta.',
                    year: 'Aikaraja vuosissa. Esimerkiksi Arvo -1 tarkoittaa viimevuotta. Arvo 0 tarkoittaa tätä vuotta. Arvo 1 tarkoittaa ensivuotta.',
                }
                info = infos[ formModal.autoTimeMode ];

                if( keyExists( formModal, 'autoTimeMode', true, 'none' ) === 'dates' )
                {
                    diff2 = <ApInput
                        id="diff2"
                        type="text"
                        name="diff2"
                        label="Päättyen"
                        value={ keyExists( formModal, "autoTimeDiff2", true, 0 ) }
                        onChange={ ( e ) => { this.setFormModalDiff( "autoTimeDiff2", e.target.value ) } }
                        // validationState={ keyExists( formModal, "autoTimeDiff2", true, 0 ) ? "" : "error" }
                    />
                }
            }
            timeForm = <Collapse in={ keyExists( formModal, 'autoTime', true, false) }>
                <div>
                    <ApInputStack gap="0">
                        <ApInput
                            className="dateMode"
                            type="select"
                            id="dateMode"
                            name="dateMode"
                            value={ keyExists( formModal, 'autoTimeMode', true, 'none' ) }
                            options={ timeOptions }
                            onChange={ ( e ) => { this.setFormModal({ 'autoTimeMode': e.target.value }) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                            // width="300px"
                        />
                        { diff }
                        { diff2 }
                    </ApInputStack>

                    { Boolean( info ) && <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        { info }
                    </div> }

                </div>
            </Collapse>

            if( !formModal.name ) valid = false;
            else if( keyExists( formModal, 'autoTime', true, false) )
            {
                let atm = keyExists( formModal, 'autoTimeMode', true, 'none' );
                let value = keyExists( formModal, "autoTimeDiff", true, null );
                let value2 = keyExists( formModal, "autoTimeDiff2", true, null );

                if( atm === 'none' )
                    valid = false;
                else if( atm === 'dates' )
                {
                    if( !value && !value2 )
                        valid = false;
                }
                else
                {
                    if( !value )
                        valid = false;
                }
            }

            autoTimeDom = <div>
                <div className="apSwitchBlock">
                    <label htmlFor="form-modal-automatic-time-switch" className="info">
                        Automaattinen aikarajoitus
                        <small>Määritä aikarajoitus tulemaan suhteessa raportin hakupäivään</small>
                    </label>
                    <ApSwitch
                        id="form-modal-automatic-time-switch"
                        on={ formModal.autoTime || false }
                        onChange={ () => this.setFormModal({ autoTime: !formModal.autoTime }) }
                        disabled={ this.state.loading }
                    />
                </div>
                { timeForm }
            </div>
        }

        return <ApModal
            className="narrow"
            show={ Boolean( formModal.show ) }
            handleClose={ () => this.setState({ formModal: {} }) }
            closeFromBg
            header={
                <div className="padding-small">
                    <h3>Tallenna raporttikriteerit</h3>
                </div>
            }
            body={
                <div className="padding">
                    <ApInput
                        type="text"
                        id="saveModalName"
                        name="name"
                        label="Nimi"
                        value={ formModal.name || '' }
                        onChange={ ( e ) => { this.setFormModal({ name: e.target.value }) } }
                        validationState={ keyExists( formModal, "name", true, '' ) ? "" : "error" }
                    />
                    { autoTimeDom }
                </div>
            }
            footer={
                <div className="padding-small">
                    <ApButton onClick={ () => this.setState({ formModal: {} }) }>
                        <SvgIcon icon="times" type="solid" />
                        Peruuta
                    </ApButton>
                    <ApButton className="applyButton" color="green" onClick={ this.formSave } disabled={ !valid }>
                        <SvgIcon icon="save" type="solid" />
                       Tallenna
                    </ApButton>
                </div>
            }
            />

    }

    // ----------------------------------------
    //  Render
    // ----------------------------------------

    renderTypeSelect()
    {
        return <ApInputStack gap="0">
            <ApAddon noRightBorder>
                Kohde
            </ApAddon>
            <ApInput
                type="select"
                id="target_type"
                name="target_type"
                value={ keyExists( this.state.form, 'type.value', true, '' ) }
                options={ types }
                onChange={  ( e ) => { this.setType( e.target.value ) } }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />
        </ApInputStack>
    }

    renderButtonBar()
    {
        if( !this.state.form.type ) return null;
        return <div className="buttonBar">
            <ApButton color="green" size="small" onClick={ () => this.fetchReport() }  key="playButton">
                Luo raportti
                <SvgIcon icon="play" type="solid" />
            </ApButton>

            <ApButton color="blue" size="small" onClick={ () => this.fetchExcel() }  key="excelButton">
                Luo Excel
                <SvgIcon icon="file-excel" type="solid" />
            </ApButton>

            <div className="rightButton">
                <ApButton color="blue" size="small" onClick={ () => this.setState({ formModal: { show: true } })} key="saveButton">
                    <SvgIcon icon="save" type="solid" />
                    Tallenna hakukriteerit
                </ApButton>
            </div>
        </div>

    }

    renderFilters()
    {
        const type = keyExists( this.state, "form.type.value", true, null );
        let doms = [];
        if( type === 'tt' )
        {
            doms.push( this.renderDates() );
            doms.push( this.renderUserFilter() );
            doms.push( this.renderComponentFilter() );
            doms.push( this.renderProjectFilter() );
            //doms.push( this.renderTimetrackingTypeSelect() );
        }
        else if( type === 'tt_travel' )
        {
            doms.push( this.renderDates() );
            doms.push( this.renderUserFilter() );
            doms.push( this.renderProjectFilter() );
            //doms.push( this.renderTimetrackingTypeSelect() );
        }
        else if( type === 'tt_expense' )
        {
            doms.push( this.renderDates() );
            doms.push( this.renderUserFilter() );
            doms.push( this.renderProjectFilter() );
            //doms.push( this.renderTimetrackingTypeSelect() );
        }
        else if( type === 'project' )
        {
            doms.push( this.renderDate() );
            doms.push( this.renderProjectFilter() );
        }
        else if( type === 'component' )
        {
            doms.push( this.renderComponentTypeSelect() );
        }
        else if( type === 'project_installation_entries' )
        {
            doms.push( this.renderDates() );
            doms.push( this.renderProjectFilter() );
        }
        else if( type === 'clients' )
        {
            
            doms.push( this.renderContactGroup() );
            doms.push( this.renderClientGroup() );
            doms.push( this.renderAccountManager() );
            doms.push( this.renderLocality() );
            doms.push( this.renderIndustry() );
            doms.push( this.renderTurnover() );
            doms.push( this.renderStaff() );

        }

        return doms;
    }

    renderTimetrackingTypeSelect()
    {
        let options = [
            { value: "suggested", label: 'Ehdotettu' },
            { value: "open", label: 'Avoin', },
            { value: "submitted", label: 'Lähetetty', },
            { value: "rejected", label: 'Hylätty', },
            { value: "approved", label: 'Esimies hyväksynyt', },
            { value: "confirmed", label: 'Hyväksytty', },
            { value: "payrolled", label: 'Siirretty maksuun', },
            { value: "paid", label: 'Maksettu', },
        ];

        return <ApSelect key="columnsSelect"
            label="Kirjauksen tila"
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'ttstatus', true, [] ) }
            onChange={ ( values ) => this.setForm({ ttstatus: values }) }
            objKeyId="value"
            multiselect
            options={ options }
            optionRenderer="label"
            objKeySearchable="label"
            valueRenderer="label"
        />


    }

    renderComponentTypeSelect()
    {
        let options = [
            { value: 'item', label: 'Komponentit', },
            { value: 'work', label: 'Työt', },
            { value: 'other', label: 'Kustannukset', },
        ];
        return this.renderOptionBar( 'ctype', options );
    }

    render()
    {
        //if( !hasPermissions( permissions ) ) return <Notfound />

        let searchOptionsDom = null;
        if( this.state.form.type )
        {
            searchOptionsDom = <div className="clear">
                <div className="apColumn w50">
                    <div className="padding">
                        <h4 className="formHeader">
                            <SvgIcon icon="columns" type="solid" /> Sarakkeet
                        </h4>
                        <div className="apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            Mihin sarakkeisiin tiedot erotellaan ja mitä tietoa näytetään
                        </div>
                        { this.renderSplitsSelect() }
                        { this.renderColumnSelect() }
                        { this.renderDepthInputs() }
                    </div>
                </div>


                <div className="apColumn w50">
                    <div className="padding">
                        <h4 className="formHeader">
                            <SvgIcon icon="filter" type="solid" /> Rajoittimet
                        </h4>

                        <div className="apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            Rajaa näytettävää tietoa
                        </div>
                        { this.renderFilters() }
                    </div>
                </div>
            </div>
        }

        return <div className="apBox" id="reportsPage">
                <div className="apBoxHeader">
                    <h1>Raportit </h1>
                    <p>Luo raportteja järjestelmästä. </p>
                </div>

                <ApTabs ref={this.tabsRef }>
                    <ApTab icon="share" label="Tallennetut pohjat">
                        <div className="padding">
                            { this.renderTemplateList() }
                        </div>


                    </ApTab>

                    <ApTab icon="table" label="Raportti">
                        <div className="padding">
                            { this.renderTypeSelect() }
                            { searchOptionsDom }
                            { this.renderButtonBar() }
                        </div>
                        <div className="padding">
                            { this.renderTable() }
                        </div>


                    </ApTab>
                </ApTabs>

                { this.renderFormModal() }
        </div>
    }

}

export default Reports;
