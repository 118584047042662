import React from 'react';
import autobind from 'react-autobind';
import { tr, errorPopper, sqlToDateInput, keyExists, getImageUrl } from 'services/Helpers/Helpers.js';
import api from 'services/Api/Api.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import { ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApButton from 'common/ApButton/ApButton.js';
import File from 'common/File/File.js';

import styles from './AssignmentPage.module.css';

class AssignmentPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          loading: false,
          selectedImage: null,
          showImageModal: false,

          begin_at: null,
          description: '',
          end_at: null,
          name: '',
          project_id: null,
          project: null,
          status_id: null,
          statuses: null,
          team_id: null,
          team: null,
          user_id: null,
          user: null,
          work_lenght_sec: null,
          files: [],
        };

        autobind(this);
    }

    componentDidMount() {
        this.setState({loading:true});
        api({
            method: 'get',
            url: `extranet/assignments/${this.props.match.params.id}/get`,
        }).then(response => {
            // console.log('RES', response);
            const {
                begin_at,
                end_at,
                name,
                description,
                project_id,
                project,
                status_id,
                team_id,
                team,
                user_id,
                user,
                work_lenght_sec,
                statuses,
                files,
                address_street,
                address_postal_code,
                address_city,
                address_country,
            } = response
            this.setState({
                loading: false,
                begin_at,
                end_at,
                name,
                description,
                project_id,
                project,
                status_id,
                team_id,
                team,
                user_id,
                user,
                work_lenght_sec,
                statuses,
                files,
                address_street,
                address_postal_code,
                address_city,
                address_country,
            });
        }).catch(err => {
            this.setState({loading:false});
            console.error(err);
            errorPopper(err, tr('get_error'));
        })
    }

    renderEstimatedDuration() {
        let hours = '';
        if (this.state.work_lenght_sec) {
            hours = this.state.work_lenght_sec/3600;
            hours += 'h';
        }
        return hours;
    }

    renderWorker() {
        let worker = '';
        if (keyExists(this.state.user, 'person_detail')) {
            worker = `${this.state.user.person_detail.first_name} ${this.state.user.person_detail.last_name}`;
        }
        if (keyExists(this.state.team, 'team_members')) {
            const leader = this.state.team.team_members.find(member => member.team_leader);
            const members = this.state.team.team_members.reduce((membersList, member) => {
                if (member.user.is_active && !member.user.deleted_at) {
                    const newMember = (
                        <div key={member.user_id}>
                            {`${member.user.person_detail.last_name} ${member.user.person_detail.first_name}`}
                        </div>
                    );
                    return [...membersList, newMember]
                } else {
                    return membersList;
                }
            }, []);
            worker = (
                <ApTooltip text={members}>
                    {leader ? `${leader.user.person_detail.first_name} ${leader.user.person_detail.last_name}`: ''} <SvgIcon type='solid' icon='user-friends' />
                </ApTooltip>
            )
        }

        return worker;
    }

    renderFiles() {
        return this.state.files.map( ( i, index ) => {
            return <div key={ i.id } className="files listStack">
                <ApInputStack gap="0">
                        <ApAddon>
                            <div
                                onClick={ () => {this.setState({
                                    selectedImage: i, 
                                    showImageModal: true,
                                    // selectedImageName: i.original_name,
                                    // selectedImageExtension: i.extension,
                                })}}
                                className="pointer"
                            >
                                <SvgIcon 
                                    className="showImageBtn pointer"
                                    icon="file" 
                                    type="solid"
                                />
                                { i.original_name }
                            </div>
                        </ApAddon>
                </ApInputStack>
            </div>
        });
    }

    renderAddress() {
        let addressEl = (
            <span>
                {this.state.address_street ? <>{this.state.address_street} <br/></> : null}
                {this.state.address_postal_code ? `${this.state.address_postal_code} ` : null}
                {this.state.address_city ? `${this.state.address_city} ` : null}
                {this.state.address_country ? `${this.state.address_country} ` : null}
            </span>
        );
        return addressEl;
    }

    selectImage() {

    }

    render() {
        if (this.state.loading) {
            return <div style={{position:'relative'}}><div className='apLoader'></div></div>
        }

        return (
            <div id='AssignmentPage' className='apBox'>
                <div className="pageBackButton" onClick={ this.props.history.goBack }>
                    <SvgIcon icon="arrow-left" type="solid" size="small" />
                    { tr('back') }
                </div>
                <div className='apBoxHeader'>
                    <h1>{tr('assignment')}</h1>
                </div>
                <div className='padding'>
                    <div className={`clear ${styles['row-padding']}`}>
                        <div className='apColumn w33'>
                            <strong>{tr('schedule')}:</strong>
                            <div>
                                {tr('estimated_duration')}: {this.renderEstimatedDuration()} <br />
                                {tr('start_date')}: {this.state.begin_at ? sqlToDateInput(this.state.begin_at, true) : tr('not_defined') } <br />
                                {tr('end_date')}: {this.state.end_at ? sqlToDateInput(this.state.end_at, true) : tr('not_defined') } <br />
                            </div>
                        </div>
                        <div className='apColumn w33'>
                            <strong>{tr('project')}:</strong>
                            <div>
                                {keyExists(this.state, 'project.name', true, null)} <br />
                                {keyExists(this.state, 'project.project_code', true, null)} <br />
                            </div>
                        </div>
                        <div className='apColumn w33'>
                            <strong>{tr('location_alt')}:</strong>
                            <div>
                                {this.renderAddress()}
                            </div>
                        </div>
                    </div>
                    <div className={`clear ${styles['row-padding']}`}>
                        <div className='apColumn w33'>
                            <strong>{tr('status')}:</strong>
                            <div>
                                {this.state.statuses ? tr(this.state.statuses[this.state.status_id]) : ''} <br />
                            </div>
                        </div>
                    </div>
                    <div className={`clear ${styles['row-padding']}`}>
                        <div className='apColumn w33'>
                            <strong>{tr('assignment_worker')}:</strong>
                            <div>
                                {this.renderWorker()} <br />
                            </div>
                        </div>
                    </div>
                    <div className={`clear ${styles['row-padding']}`}>
                        <div className='apColumn w50'>
                            <strong>{tr('header')}:</strong> <p>{this.state.name}</p>
                            <strong>{tr('description')}:</strong> <p className={styles['white-space']}>{this.state.description}</p>
                        </div>
                        <div className='apColumn w50'>
                            {this.state.files && this.state.files.length > 0 && 
                            <div>
                                <h4>{tr('files')}</h4>
                                {this.renderFiles()}
                            </div>}
                        </div>
                    </div>
                </div>
                <ApModal 
					show={this.state.showImageModal}
					onClose={() => this.setState({showImageModal: false, selectedImage: null})}
					handleClose={() => this.setState({showImageModal: false, selectedImage: null})}
					closeFromBg
					className="narrow overflow"

					header={ 
						<div className="padding">
							<h3>{ tr('file') }</h3>
						</div>
					}

					body={
						<div className={`padding ${styles['pdf-canvas']}`}>
                            {this.state.selectedImage && 
                            <File 
                                fileId={this.state.selectedImage.id}
                                name={this.state.selectedImage.original_name}
                                type={this.state.selectedImage.extension.toLowerCase() == 'pdf' ? 'pdf' : 'image'}
                                style={{ width: "100%", height: "500px", objectFit: "contain" }}
                            />}
						</div>
					}
					footer={
						<div className="padding">
							<ApButton 
								onClick={() => {this.setState({showImageModal: false})}} 
								className="cancel"
							>
								{ tr('cancel') }
							</ApButton>
								
							<ApButton 
								onClick={() => this.getFile(this.state.selectedImage, this.state.selectedImageName)}
								style={{float: "right"}} 
								className="applyButton" 
								color="green"
							>
								<SvgIcon icon="file" type="solid" />
								{ tr('download') }
							</ApButton>
						</div>
					}
				/>
            </div>
        )
    }
}

export default AssignmentPage;