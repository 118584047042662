/* eslint-disable no-unused-vars */
import { tr } from 'services/Helpers/Helpers.js';

// This should match with DB table 'static_storage_component_statuses'
const COMPONENT_STATUSES = {
    1: { name: "plan", color: 'warning' },
    2: { name: "active", color: 'success' },
    3: { name: "frozen", color: 'info' },
    4: { name: "removed", color: 'error' },
    5: { name: "temporary", color: '' },
};

// This should match with DB table 'static_storage_component_types'
const COMPONENT_TYPES = [
    { id: 1, name: "item", text: tr('component') },
    { id: 2, name: "work", text: tr('work') },
    { id: 3, name: "other", text: tr('cost') },
    { id: 1, name: "storage", text: tr('component') },
];

const orderStatusColors = {
    archived: '',
    open: '',
    available_none: '',
    available_some: 'info',
    available_all: 'info',
    sent_all: 'success',
};

const poStatusColors = {
    archived:  '',
    open: '',
    approval: 'info',
    order_send: 'info',
    order_confirm: 'info',
    arrival: '',
    arrived: 'success',
    late: 'error',
};

const types = {
    po: { name: tr('purchase_order'), icon: 'clipboard-check' },
    order: { name: tr('order'), icon: 'receipt' },
    location: { name: tr('storage'), icon: 'archive' },
    delivery: { name: tr('shipment'), icon: 'truck' },
    project: { name: tr('project'), icon: 'folder' },
    company: { name: tr('company'), icon: 'building' },
    cash_buyer: {name: tr('cash_customer'), icon: 'user-tag'},
    supplier: { name: tr('supplier'), icon: 'building' },
    item: { name: tr('component'), icon: 'puzzle-piece' },
    work: { name: tr('work'), icon: 'user-clock' },
    other: { name: tr('cost'), icon: 'money-bill-wave' },

    storage: { name: tr('component'), icon: 'puzzle-piece' },

    identifier: { name: tr('identifier'), icon: 'fingerprint' },
    property: { name: tr('property'), icon: 'ruler-combined' },
    calculatable: { name: tr('meter'), icon: 'weight' },

    trackingunit: { name: tr('monitoring_unit'), icon: 'crosshairs' },
};

export const componentTypes = COMPONENT_TYPES;

export function orderStatusBoxColor( name )
{
    for (const key in orderStatusColors ) {
        if( key === name )
            return orderStatusColors[ key ];
    }
    console.error( `StorageHelpers.js: orderStatusColor "${ name }" not defined` );
    return '';
}

export function poStatusBoxColor( name )
{
    for (const key in poStatusColors ) {
        if( key === name )
            return poStatusColors[ key ];
    }
    console.error( `StorageHelpers.js: poStatusColor "${ name }" not defined` );
    return '';
}

export function getTypeIcon( name )
{
    for (const key in types ) {
        if( key === name )
            return types[ key ].icon;
    }
    console.error( `StorageHelpers.js: typeIcon "${ name }" not defined` );
    return '';
}
export function getTypeName( name )
{
    for (const key in types ) {
        if( key === name )
            return types[ key ].name;
    }
    console.error( `StorageHelpers.js: typeIcon "${ name }" not defined` );
    return '';
}


export function getComponentTypeId( name )
{
    for (const key in COMPONENT_TYPES ) {
        if( COMPONENT_TYPES[ key ].name === name )
            return COMPONENT_TYPES[ key ].id;
    }
    console.error( `StorageHelpers.js: COMPONENT_TYPES "${ name }" not defined` );
    return null;
}

export function getComponentStatusId( name )
{
    for (const key in COMPONENT_STATUSES ) {
        if( COMPONENT_STATUSES[ key ].name === name )
            return key;
    }
    console.error( `StorageHelpers.js: COMPONENT_STATUSES "${ name }" not defined` );
    return null;
}

export function getComponentStatusColor( name ) 
{
    for (const key in COMPONENT_STATUSES ) {
        if( COMPONENT_STATUSES[ key ].name === name )
            return COMPONENT_STATUSES[ key ].color ;
    }
    console.error( `StorageHelpers.js: COMPONENT_STATUSES "${ name }" not defined` );
    return null;
}


export function getComponentStatusNames()
{
    let tmp = [];
    for (const key in COMPONENT_STATUSES ) {
        tmp.push( COMPONENT_STATUSES[ key ].name  );
    }
    return tmp;
}


