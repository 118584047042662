import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { EventEmitter } from 'fbemitter';
import { unregister } from './registerServiceWorker';

import './polyfills.js';
import './main.css';
import 'react-table/react-table.css';
import 'react-datetime/css/react-datetime.css';

import PublicLayout from './layouts/PublicLayout/PublicLayout.js';
import ExtraLayout from './layouts/ExtraLayout/ExtraLayout.js';

import 'moment/locale/fi';


class App extends React.Component
{

	UNSAFE_componentWillMount()
	{

		window.emitter = new EventEmitter();

		const href = window.location.href;

		let token = href.split('?token=')[1];
		let userId = href.split('?token=')[0].split('?userId=')[1];
		
		if (token !== null && token !== undefined && userId !== null && token !== undefined)
		{
			window.sessionStorage.setItem('temptoken', token);
			window.sessionStorage.setItem('tempUserId', userId);
			//this.handleLoginFromIntranet(userId, token);
		}
	
	}

	render()
	{

		return (
			<BrowserRouter>
				<Switch>
					<Route path="/login" component={PublicLayout} />
					<Route path="/" component={ExtraLayout} />
				</Switch>
			</BrowserRouter>
		);

	}

}

ReactDOM.render(<App />, document.getElementById('app'));
unregister();
