/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import axios from 'axios';

import ApFormPage from 'common/ApFormPage/ApFormPage.js';

import ApButton from 'common/ApButton/ApButton.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApModalInput from 'common/ApModalInput/ApModalInput.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApUserImage from 'common/ApUserImage/ApUserImage.js';

import './Approve.css';

import { groupify, keyExists } from  'services/Helpers/Helpers.js';

import Week from '../Week/Week.js';
import UserBrowser from '../UserBrowser/UserBrowser.js';

export default class TimetrackingApprove extends React.Component {

    constructor( props ) 
    {
        super(props);
        this.state = {
            loading:            false,  
            settings:           null,

            users:              [],
            selectedUser:       null,

            entries:            [],
            selectedEntries:    [],

            salaryExtras:       [],
            showSalaryExtras:   false,

            showReadOnly:       false,

            changes:            [],

            commentEntryId:     null,
            commentText:        "",

            workNumEntryId:     null,
            workNumNew:        "",
        };
        this.statusLoop = [ "pending", "approved", "rejected" ];
        autoBind(this);
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers( user = null )
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'extranet/approval/users',
        }).then(( response ) => {

            // console.log('USERS', response.users );
            // console.log('SETTINGS', response.settings );
         
            this.setState({
                loading: false,
                users: response.users,
                settings: response.settings,
                changes: [],
            }, () => {

                if( response.users.length > 0 )
                {
                    if( !user || response.users.findIndex( r => r.id == user ) == -1 )
                        user = response.users[0].id;
                  
                    this.changeUser( user );
                }
                else 
                    this.setState({ 
                        selectedUser:       null,
                        entries:            [],
                        selectedEntries:    [],
                    });

            });

        }).catch( ( error ) => {
            console.log( 'getUsers ERROR', error );
            this.setState({ loading: false });
        });
    }

    changeUser( id )
    {
        // console.log('changeUser', id );

        // Cancel possible old query 
        if( this.state.cancelToken )
            this.state.cancelToken.cancel('Operation canceled by the user.');
           
        this.setState({ 
            selectedUser: id,
            loading: true,
            cancelToken: axios.CancelToken.source(),
        }, () => {
            api({
                cancelToken: this.state.cancelToken.token,
                method: 'post',
                url: 'extranet/approval/entries',
                data: { userId: id }
            }).then(( response ) => {

                // console.log('USER ENTRIES', response );

                this.setState({
                    loading: false,
                    entries: response,
                    selectedEntries: [],
                    cancelToken: null,
                });

            }).catch( ( error ) => {
                if( axios.isCancel( error ) ) return null;
                console.log('ERROR', error );
            });
        });
    }
    
    newChange( id, data, changes = null )
    {
        if( !changes )
            changes = this.state.changes.slice();

        const index = changes.findIndex( c => c.id == id );

        if( index == -1 )
            changes.push({
                id: id,
                status: 'pending',
                approver_comment: null,
                ...data,
            });

        else 
            changes[ index ] = { ...changes[ index ], ...data };

        return changes;
    }

    onToggleStatus( id )
    {
        let changes = this.state.changes.slice();
        let status = this.statusLoop[ 1 ];
        const index = changes.findIndex( c => c.id == id );
        if( index != -1 )
        {
            let statusIndex = this.statusLoop.indexOf( changes[ index ].status );
            statusIndex++;

            if( statusIndex >= this.statusLoop.length )
                statusIndex = 0;

            status = this.statusLoop[ statusIndex ];
        }

        changes = this.newChange( id, { status: status }, changes );
        this.setState({ changes });
    }

    onEditComment( id, currentComment = "" )
    {
        let changes = this.state.changes.slice();
        const index = changes.findIndex( c => c.id == id );

        if( index != -1 && changes[ index ].status == "rejected" )
            this.setState({ 
                commentText: currentComment,
                commentEntryId: id,
            });
    }

    onEditWorkNumber( id, currentNumber = "" )
    {       
        let changes = this.state.changes.slice();
        const index = changes.findIndex( c => c.id == id );
        
        if( index != -1 ) {
            this.setState({ 
                workNumNew: currentNumber,
                workNumEntryId: id,
            });
        }
        else {
            changes.push({
                id: id,
                status: 'pending',
                approver_comment: null,
            });
            this.setState({ 
                workNumNew: currentNumber,
                workNumEntryId: id,
            });
        }
    }

    saveComment( comment )
    {
        let changes = this.state.changes.slice();
        const update = {
            status: "rejected",
            approver_comment: comment,
        };

        if( this.state.commentEntryId === "selected" )
        {
            this.state.selectedEntries.forEach( id => {
                changes = this.newChange( id, update, changes );
            });
        }
        else 
        {
            changes = this.newChange( this.state.commentEntryId, update, changes );
        }

        this.setState({ 
            changes: changes,
            commentText: "",
            commentEntryId: null 
        });
    }

    closeComment()
    {
        this.setState({ 
            commentText: "",
            commentEntryId: null 
        });
    }

    saveNum( comment )
    {
        let changes = this.state.changes.slice();
        let entries = this.state.entries.slice();

        const update = {
            customer_work_number: comment
        };
        changes = this.newChange( this.state.workNumEntryId, update, changes );

        const indexEntry = entries.findIndex( c => c.id == this.state.workNumEntryId );

        let entry = entries[ indexEntry ];
        entry['customer_work_number']=comment;
        entries[ indexEntry ] = entry;

        this.setState({ 
            changes: changes,
            entries: entries,
            workNumNew: "",
            workNumEntryId: null 
        });
    }

    closeNum()
    {
        this.setState({ 
            workNumNew: "",
            workNumEntryId: null 
        });
    }



    isWeekSelected( week )
    {
        let selected = true;
        const entries = this.state.entries.filter( e => e.week === week && !e.readOnly );
        entries.forEach( e => {
            if( this.state.selectedEntries.indexOf( e.id ) == -1 )
                selected = false;
        });
        return selected;
    }

    onWeekSelect( week, selected )
    {
        let selectedEntries = this.state.selectedEntries.slice();

        const entries = this.state.entries.filter( e => e.week === week && !e.readOnly );
        entries.forEach( e => {
            const index = selectedEntries.indexOf( e.id );
            
            // Select all day entries that are not yet selected
            if( selected && index == -1 )
                selectedEntries.push( e.id );

            // Unselect all day entries that are selected
            else if ( !selected && index != -1 )
                selectedEntries.splice( index, 1 );
        });

        this.setState({ selectedEntries });
    }

    onEntrySelect( id )
    {
        let selectedEntries = [...this.state.selectedEntries]

        if( id === "all" )
        {
            //const allEntries = this.state.entries.filter( e => e.readOnly === false );
            const allEntries = this.state.entries

            if( selectedEntries.length === allEntries.length ){
                selectedEntries = [];
            } else {
                selectedEntries = allEntries.map( e => e.id );
            }       
        }
        else 
        {
            const index = selectedEntries.indexOf( id );
            if( index === -1 )
                selectedEntries.push( id );
            else 
                selectedEntries.splice( index, 1 );
        }

        this.setState({ selectedEntries });
    }

    updateSelected( status )
    {
        if( status === "rejected" )
        {
            this.setState({ 
                commentText: "",
                commentEntryId: "selected",
            }); 
        }
        else 
        {
            let changes = this.state.changes.slice();
            this.state.selectedEntries.forEach( id => {
                changes = this.newChange( id, { status: status }, changes );
            });
            this.setState({ changes });
        }
    }

    toggleTypeFilter( id )
    {
        let filtered_types = this.state.filtered_types.slice();
        const index = filtered_types.indexOf( id );
        
        if( index == -1 )
            filtered_types.push( id );
        else 
            filtered_types.splice( index, 1 );

        this.setState({ filtered_types });
    }

    resetFilters()
    {
        this.setState({
            filtered_users: [],
            filtered_projects: [],
            filtered_types: [],
        });
    }

    removeFilter( type, index = null )
    {
        if( [ "filtered_users", "filtered_projects", "filtered_types" ].indexOf( type ) != -1 )
        {
            let items = this.state[ type ];
            items.splice( index, 1 );
            this.setState({ [ type ]: items });
        }
    }

    filteredEntries()
    {
        let entries = this.state.entries.slice();
        const users =  keyExists( this.state, "filtered_users", true, [] ).map( u => u.id );
        const projects =  keyExists( this.state, "filtered_projects", true, [] ).map( p => p.id );
        const types = keyExists( this.state, "filtered_types", true, [] );
        
        entries = entries.filter( e => {

            if( users.length > 0 && users.indexOf( e.user_id ) == -1 )
                return false;

            if( projects.length > 0 && projects.indexOf( e.project_id ) == -1 )
                return false;

            if( types.length > 0 && types.indexOf( e.type ) == -1 )
                return false;

            return true;
        });

        return entries;
    }

    save()
    {
        // console.log( 'Save...', this.state.entries );

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'extranet/approval/save',
            data: {
                changes: this.state.changes,
            },
        }).then(( response ) => {

            // console.log( 'saved!', response );
            this.getUsers( this.state.selectedUser );

        }).catch((error) => {
            console.error(error);
            this.setState({ loading: false });
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>Virhe hyväksymisessä</strong>,
            });
        });
    }


    renderFooter()
    {
        const approved = this.state.changes.filter( c => c.status === "approved" ).length;
        const rejected = this.state.changes.filter( c => c.status === "rejected" ).length;
        const total = this.state.users.reduce( ( total, u ) => total + u.entry_count, 0 );
        const haveChanges = approved + rejected > 0;
  
        return (
            <div className="apBox customFooter">

                <div className="tools">

                    <div className="selection">

                        <div className="selectAll" onClick={ () => this.onEntrySelect( "all" ) }>
                            <input 
                                type="checkbox"
                                disabled={ this.state.entries.length === 0 }
                                checked={ this.state.selectedEntries.length === this.state.entries.filter( e => !e.readOnly ).length }
                                readOnly
                            />
                            { this.state.selectedEntries.length > 0 &&
                                <span className="apBadge blue">{ this.state.selectedEntries.length }</span>
                            }
                        </div>

                        <ApDropdown
                            position="topright"
                            disabled={ this.state.entries.length == 0 }
                            actions={[
                                { 
                                    icon: "check",
                                    label: "Hyväksy valitut",
                                    disabled: this.state.selectedEntries.length == 0,
                                    action: ( id, close ) => {
                                        this.updateSelected( "approved" );
                                        close();
                                    }
                                },
                                { 
                                    icon: "times",
                                    label: "Hylkää valitut",
                                    disabled: this.state.selectedEntries.length == 0,
                                    action: ( id, close ) => {
                                        this.updateSelected( "rejected" );
                                        close();
                                    }
                                },
                                { 
                                    icon: "question",
                                    label: "Kumoa päätös valituista",
                                    disabled: this.state.selectedEntries.length == 0,
                                    action: ( id, close ) => {
                                        this.updateSelected( "pending" );
                                        close();
                                    }
                                },
                            ]}
                        />
                    </div>
                    
                </div>

                <div className="action">

                    <div className="totals">
                        <div className="total">
                            <ApTooltip text="Odottaa päätöstä" block>
                                <SvgIcon icon="question" type="solid" />
                                <span>{ total - approved - rejected }</span>
                            </ApTooltip>
                        </div>
                        <div className={ "total" + ( approved > 0 ? " approve" : "" ) }>
                            <ApTooltip text="Hyväksyttyjä" block>
                                <SvgIcon icon="check" type="solid" />
                                <span>{ approved }</span>
                            </ApTooltip>
                        </div>
                        <div className={ "total " + ( rejected > 0 ? " reject" : "" ) }>
                            <ApTooltip text="Hylättyjä" block>
                                <SvgIcon icon="times" type="solid" />
                                <span>{ rejected }</span>
                            </ApTooltip>
                        </div>
                    </div>

                    <ApButton 
                        className={ "save" + ( haveChanges && !this.state.loading ? " highlight" : "" ) } 
                        color="blue" 
                        onClick={ this.save } 
                        disabled={ !haveChanges || this.state.loading } 
                        loading={ this.state.loading }
                    >
                        <SvgIcon icon="clipboard-check" type="solid" />
                        Tallenna muutokset
                    </ApButton>

                </div>

            </div>
        );
    }

    // Group entries to weeks and days
    groupifyEntries()
    {
        let parsed = {};
        const weeks = groupify( this.state.entries, "week" );
        Object.keys( weeks ).map( ( week ) => {
            const days = groupify( weeks[ week ], "date" );
            parsed[ week ] = days;
        });
        return parsed;
    }

    render()
    {

        const haveChanges = this.state.changes.filter( c => c.status === "approved" || c.status === "rejected" ).length > 0;
        const weeks = this.groupifyEntries();
      
        return (
            <div id="pageTimetrackingApproval" style={{minWidth: 500}}>
                <ApFormPage 
                    unsaved={ haveChanges }
                    className="timetrackingApprovalForm"
                    customFooter={ this.renderFooter }
                >
                    <div className="apBox">
                        <div className="apBoxHeader">
                            <h3>Työaikojen hyväksyntä</h3>
                            <p>Tarkista laskutettavia työtunteja ja hyväksy tai hylkää ne.</p>

                            <div className="apBoxCorner" style={{marginTop: 10}}>

                                <label htmlFor="show-readOnly-switch">
                                    Näytä kaikki kirjaukset
                                </label>
                                <ApSwitch
                                    small
                                    inline
                                    id="show-readOnly-switch"
                                    on={ this.state.showReadOnly }
                                    onChange={ () => this.setState({ showReadOnly: !this.state.showReadOnly }) }
                                />

                            </div>
                        </div>

                        <UserBrowser 
                            users={ this.state.users } 
                            selectedUser={ this.state.selectedUser }
                            onChangeUser={ this.changeUser }
                        />

                    </div>

                    <div className={ "entries" + ( this.state.loading ? " loading" : "" ) + ( this.state.showReadOnly ? "" : " hideReadOnly" ) }>

                        { this.state.loading && <div className="apLoader"></div> }

                        { !this.state.loading && this.state.users.length == 0 &&
                            <div className="apSuccessMsg">
                                <SvgIcon block icon="thumbs-up" type="solid" size="huge" />
                                <h4>Ei hyväksyttäviä rivejä</h4>
                                <p>Kaikki vastuullasi olevat rivit on hoidettu.</p>
                            </div>
                        }

                        { Object.keys( weeks ).map( week => {
                            return (
                                <Week
                                    key={ week }
                                    week={ week }
                                    days={ weeks[ week ] }
                                    weekSelected={ this.isWeekSelected( week ) }
                                    selectedEntries={ this.state.selectedEntries }
                                    changes={ this.state.changes }
                                    settings={ this.state.settings }
                                    showReadOnly={ this.state.showReadOnly }
                                    showSalaryExtras={ this.state.showSalaryExtras }
                                    salaryExtras={ this.state.salaryExtras }
                                    statuses={ this.statusLoop }
                                    onToggleReadOnly={ ( value ) => this.setState({ showReadOnly: value }) }
                                    onToggleStatus={ this.onToggleStatus }
                                    onEntrySelect={ this.onEntrySelect }
                                    onWeekSelect={ this.onWeekSelect }
                                    onEditComment={ this.onEditComment }
                                    onEditWorkNumber={ this.onEditWorkNumber }
                                />
                            );
                        })}

                    </div>

                
                </ApFormPage>

                <ApModalInput 
                    show={ this.state.commentEntryId !== null }
                    value={ this.state.commentText }
                    onSave={ this.saveComment }
                    onCancel={ this.closeComment }
                    title="Hylkäyksen syy"
                    info={ this.state.commentEntryId == "selected" ? "Valittuna " + this.state.selectedEntries.length + " riviä" : undefined }
                />

                <ApModalInput 
                    show={ this.state.workNumEntryId !== null }
                    value={ this.state.workNumNew }
                    onSave={ this.saveNum }
                    onCancel={ this.closeNum } saveNum
                    title="Päivitä työnumero"
                    info=""
                />

            </div>
        );
    }
}
