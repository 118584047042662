/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';

import './ApTabs.css';
import SvgIcon from '../SvgIcon/SvgIcon.js';



// Tabs wrapper component
class ApTabs extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            selected: ( this.props.selected ? this.props.selected : 0 )
        };

        this.getTabs = this.getTabs.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.prevTab = this.prevTab.bind(this);
        this.nextTab = this.nextTab.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.selected && prevProps.selected != this.props.selected) {
            this.setState({selected: this.props.selected})
        }
    }

    getTabs()
    {
        let childs = [];
        let index = 0;
        React.Children.map(this.props.children, child =>
        {
            if(child !== null)
            {
                childs.push( React.cloneElement( child, { selected: ( index === this.state.selected ? true : false ) }) );
                index++;
            }
        })
        return childs;
    }

    changeTab( index )
    {
        if( this.state.selected != index )
        {
            this.setState( { selected: index } );

            if( typeof( this.props.onChange ) == "function" )
                this.props.onChange( index );
        }
    }

    prevTab( tabCount )
    {
        let selected = this.state.selected;

        selected--;
        if( selected < 0 ) selected = tabCount - 1;

        this.setState( { selected } );

        if( typeof( this.props.onChange ) == "function" )
            this.props.onChange( selected );
    }

    nextTab( tabCount )
    {
        let selected = this.state.selected;

        selected++;
        if( selected > (tabCount - 1) ) selected = 0;

        this.setState( { selected } );

        if( typeof( this.props.onChange ) == "function" )
            this.props.onChange( selected );
    }

    render()
    {
        const tabs = this.getTabs();

        let linksClasses = ["apTabLinks", "count-" + tabs.length ];
        if( this.props.size )       linksClasses.push( "size-" + this.props.size );
        if( this.props.fullWidth )  linksClasses.push( "fullWidth" );
        if( this.props.narrow )     linksClasses.push( "narrow" );

        return (
            <div className="apTabsWrapper">

                <div className={ linksClasses.join(" ") }>
                    { tabs.map( (child, index) => {

                        let classes = ["apTabLink"];

                        if( child.props.selected )      classes.push("selected");
                        if( child.props.align )         classes.push("align-" + child.props.align );
                        if( child.props.disabled )      classes.push("disabled" );
                        if( child.props.icon )          classes.push("withIcon" );
                        if( child.props.badgeColor )    classes.push("badge-color-" + child.props.badgeColor );

                        const onClick = ( child.props.disabled ? undefined : () => this.changeTab( index, child ) );

                        let badges = [];

                        if( typeof( child.props.badge ) == "string" || typeof( child.props.badge ) == "number" )
                            badges.push( { value: child.props.badge, color: child.props.badgeColor ? child.props.badgeColor : false } );

                        if( typeof( child.props.badge ) == "object" && Array.isArray( child.props.badge ) )
                            badges = child.props.badge;

                        return (
                            <div key={index} className={ classes.join(" ") } onClick={ onClick }>
                                { child.props.icon && <SvgIcon icon={ child.props.icon } type="solid" /> }
                                { child.props.label && <span>{ child.props.label }</span> }
                                { badges.length > 0 && badges.map( ( b, index ) => {
                                    
                                    let badgeClasses = ["apTabBadge", "count-" + index ];
                                    
                                    if( b.color )
                                        badgeClasses.push( "color-" + b.color );

                                    if( parseInt( b.value, 10 ) == 0 )
                                        badgeClasses.push( "zero" );

                                    return (
                                        <span key={ index } className={ badgeClasses.join(" ") }>{ b.value }</span> 
                                    );
                                })}
                            </div>
                        )
                    })}
                    <div className="mobileNav">
                        <SvgIcon className="prevTab" icon="chevron-left" type="solid" onClick={ () => { this.prevTab( tabs.length ) }} />
                        <SvgIcon className="nextTab" icon="chevron-right" type="solid" onClick={ () => { this.nextTab( tabs.length ) }} />
                        <table className="lines">
                            <tbody>
                                <tr>
                                    { tabs.map( (child, index) =>
                                        <td key={index} className={ child.props.selected ? "selected" : "" } onClick={ () => { this.changeTab( index ) }}></td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="apTabs">
                    { tabs.map( (child, index) => {
                        if( child.props.mountOnSelect && !child.props.selected )
                            return null;
                        return <div key={index}>{child}</div>
                    })}
                </div>
            </div>
        );
    }
};

ApTabs.propTypes = {
    size:       PropTypes.string,
    fullWidth:  PropTypes.bool,
    narrow:     PropTypes.bool,
    onChange:   PropTypes.func,
};

// Individual tab component
class ApTab extends React.Component {

    render()
    {
        let classes = ["apTab"];
        if( this.props.selected ) classes.push("selected");

        return (
            <div className={ classes.join(" ") }>{ this.props.children }</div>
        );
    }

}

ApTab.propTypes = {
    icon:           PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
    label:          PropTypes.string,
    align:          PropTypes.string,
    badge:          PropTypes.node,
    badgeColor:     PropTypes.string,
    mountOnSelect:  PropTypes.bool,
};

export {
    ApTabs,
    ApTab
};
