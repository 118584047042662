/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import auth from 'services/Authed/Authed.js';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fi';

import ReactTable from 'react-table'
import matchSorter from 'match-sorter';

import './ApReactTable.css';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApRangeSlider from 'common/ApRangeSlider/ApRangeSlider.js';
import ApSelect        from 'common/ApSelect/ApSelect.js';
/*
import { getComponentName, 
         getCurrentPage,
         getFirstPageRefresh,
         setCurrentPage,
         setFirstPageRefresh} from '../../modules/Storage/PurchaseOrders/PurchaseOrderHelper'
import { getOrderCurrentPage,
         setOrderCurrentPage,
         getOrderFirstPageRefresh, 
         setOrderFirstPageRefresh } from '../../modules/Storage/Orders/OrderHelper'
         */

import { keyExists, formatMoney, sqlToDateInput, onlyNumber, tr, currentLang } from 'services/Helpers/Helpers.js';

export class ApReactTable extends React.Component {

    constructor( props )
    {
        super( props );

        this.state = {
            selectedRows: [],
            customColumns: [],
            filters: Boolean( this.props.showFiltersInitially ),

            multiselectActionsOpen: false,
            customColumnsOpen: false,
            paginationSettingsOpen: false,

            pageSelectorValue: null,
        }

        autoBind(this);
    }

    onSelectChange()
    {
        if( typeof this.props.onSelectChange === 'function' )
            return this.props.onSelectChange( this.state.selectedRows );
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.clickOutsideHandler);
        this.fetchSettings();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.clickOutsideHandler);

        this.memorizeSettings();
    }

    componentDidUpdate( prevProps ) {
        if( prevProps.data.length != this.props.data.length || (prevProps.showModal != this.props.showModal))
            this.clearSelectedRows();
    }

    setInitialColumns( memorizedCols = false )
    {
        let customColumns = [];
        if( memorizedCols )
        {
            customColumns = memorizedCols;
        }
        else
        {
            const columns = this.props.columns.slice();
            for( let i = 0; i < columns.length; i++)
            {
                // Header group
                if( columns[i].columns )
                {
                    for( let j = 0; j < columns[i].columns.length; j++ )
                    {
                        if( columns[i].columns[j].showInitially )
                            customColumns.push( i + "_" + j );
                    }
                }
                // Plain column (no header group available)
                else if( columns[i].showInitially )
                    customColumns.push( i );
            }
        }
        if( this.props.forceCustomColumns )
            this.props.onCustomColumnChange( customColumns );

        else 
            this.setState({ customColumns });
    }

    resolveCustomColumns()
    {
        if( this.props.forceCustomColumns )
            return this.props.customColumns;

        return this.state.customColumns;
    }

    fetchSettings()
    {
        const settings = JSON.parse( window.localStorage.getItem( "ApReacTable_settings_" + this.props.rememberId ) );
        
        /*
        if( settings )
        {
            console.log('settings found!', settings );
        } 
        */

        this.setInitialColumns( settings ? settings.customColumns : false ); 
    }

    memorizeSettings()
    {
        if( !this.props.rememberId )
            return false;

        const data = {
            //"page":         rt.state.page,
            //"pageSize":     rt.state.pageSize,
            //"filtered":     rt.state.filtered,
            //"sorted":       rt.state.sorted,
            customColumns:  this.resolveCustomColumns(),
        };

        window.localStorage.setItem( "ApReacTable_settings_" + this.props.rememberId, JSON.stringify( data ) );
        //console.log('saved settings', data );
    }

    clickOutsideHandler(event) {

        let newState = {};

        if( this.paginationSettingsRef && !this.paginationSettingsRef.contains(event.target) )
            newState.paginationSettingsOpen = false;

        if( this.multiselectActionsRef && !this.multiselectActionsRef.contains(event.target) )
            newState.multiselectActionsOpen = false;

        if( this.customColumnsRef && !this.customColumnsRef.contains(event.target) )
            newState.customColumnsOpen = false;

        if( Object.keys( newState ).length > 0 )
            this.setState( newState );
    }

    toggleFilters()
    {
        this.setState({ filters: !this.state.filters });
    }

    rowSelected( index )
    {
        if( index === "all" )
        {
            return this.state.selectedRows.length >= this.props.data.length;
        }
        else if (index === "filtered" )
        {
            let filtered = []; 
            if( this.reactTableRef )
                filtered = this.reactTableRef.getResolvedState().sortedData.map( i => i._index );
            
            // Not enough row selected (no need to make deeper checks)
            if( filtered.length > this.state.selectedRows.length )
                return false;

            for( let i = 0; i < filtered.length; i++ )
                if( this.state.selectedRows.indexOf( filtered[i] ) == -1 )
                    return false;
    
            return true;
        }
        else
        {
            return this.state.selectedRows.indexOf( index ) != -1;
        }
    }

    clearSelectedRows()
    {
        this.setState({ selectedRows: [] }, () => this.onSelectChange());
    }

    toggleRowSelection( rowIndex )
    {
        let selectedRows = this.state.selectedRows.slice();
        const index = selectedRows.indexOf( rowIndex );
        if( index == -1 )
            selectedRows.push( rowIndex );
        else
            selectedRows.splice( index, 1 );

        if (this.props.selectedRowsToParent)
        {
            this.props.selectedRowsToParent(selectedRows);
        }

        this.setState({ selectedRows }, () => this.onSelectChange());
    }

    toggleRowSelectionAll( forceClear )
    {
        let selectedRows = [];

        if( typeof( forceClear ) != "boolean" || !forceClear )
        {
            if( this.state.selectedRows.length < this.props.data.length )
            {
                for( let i = 0; i < this.props.data.length; i++ )
                {
                    selectedRows.push( i );
                }
            }
        }

        if (this.props.selectedRowsToParent)
        {
            this.props.selectedRowsToParent(selectedRows);
        }

        this.setState({ selectedRows }, () => this.onSelectChange());
    }

    toggleRowSelectionFiltered()
    {
        let selectedRows = this.state.selectedRows.slice();
        if( this.reactTableRef )
        {
            const unselect = this.rowSelected( "filtered" );
            this.reactTableRef.getResolvedState().sortedData.forEach( item => {
                const index = selectedRows.indexOf( item._index );

                // Add selection
                if( !unselect && index == -1 )
                    selectedRows.push( item._index );

                // Remove selection
                else if( unselect && index != -1 )
                    selectedRows.splice( index, 1 );
            });
        }

        if (this.props.selectedRowsToParent)
        {
            this.props.selectedRowsToParent(selectedRows);
        }

        this.setState({ selectedRows }, () => this.onSelectChange());
    }

    customizeColumns( columns )
    {
        // Check for customized columns and show/hide them
        let haveCustomColumns = false;

        const handleCustomColumn = ( col, index ) => {
            if( col.customizable )
            {
                haveCustomColumns = true;
                col.show = ( this.resolveCustomColumns().indexOf( index ) !== -1 );
            }

            /*
            // Preset columns available
            if( col.preset )
            {
                switch( col.preset.type )
                {
                    case "name":

                        if( col.preset.label )
                            col.Header = col.preset.label;

                            
                        TODO


                        if( !col.id )
                            col.id = "preset_name_" 
                        
                        break;
                }
            }
            */

            // Column have custom filter 
            if( col.customFilter && !col.filterMethod && !col.Filter )
            {   
                switch( col.customFilter.type )
                {

                    // Regular text filter (matchSorter)
                    case "text":

                        col.filterMethod = ( filter, rows ) => {
                            let keys = col.customFilter.fields ? col.customFilter.fields : [ col.accessor ];
                            return matchSorter( rows, filter.value, { keys: keys });
                        };
                        col.Filter = ({ filter, onChange }) => {
                            return <input 
                                type="text"
                                className="customFilter" 
                                placeholder={ col.customFilter.placeholder }
                                onChange={ e => onChange( e.target.value ) }
                                value={ filter ? filter.value : "" }
                                />
                        };
                        col.filterAll = true;
                        break;


                    // Drop down select one
                    case "select":

                        const options = col.customFilter.options ? col.customFilter.options : [];
                        
                        col.filterMethod = ( filter, row ) => {
                        
                            let option = options.find( o => o.value == filter.value );

                            if( option && typeof( option.filter ) == "function" )
                                return option.filter( row._original, filter.value );

                            // check if selected value does not match value in row
                            if (option.value != row._original[filter.id]) return false;

                            return true;
                        };
                        col.Filter = ({ filter, onChange }) => {
                            return (
                                <select
                                    className="customFilter"
                                    onChange={ e => onChange( e.target.value ) }
                                    onMouseDown={ e => onChange( e.target.value ) } // Fixes FireFox bug (https://github.com/facebook/react/issues/12584)
                                    value={ filter ? filter.value : options[0].value }
                                >  
                                    { options.map( ( opt, index ) => <option key={ index } value={ 'value' in opt ? opt.value : index }>{ opt.label }</option> )}
                                </select>
                            );
                        };
                        break;

                    
                    case "range":
                        
                        col.filterMethod = ( filter, row ) => {
                            const accessor = ( col.customFilter.accessor || col.accessor );
                            let value = null;
                            if( typeof( accessor === 'function' ))
                                value = accessor( row._original );
                            else
                                value = keyExists( row._original, accessor, true, 0 );
                            return ( value >= filter.value[0] && value <= filter.value[1] );
                        };
                        
                        col.Filter = ({ filter, onChange }) => {

                            let defaultValue = [ col.customFilter.min || 0, col.customFilter.max || 100 ];
                            
                            const output = ( value ) => {
                                if( typeof( col.customFilter.valueRenderer ) == "function" )
                                    return col.customFilter.valueRenderer( value );
                                return value[0] + " - " + value[1];
                            };

                            return (
                                <div className="customFilter range">
                                    <ApRangeSlider
                                        min={ defaultValue[0] }
                                        max={ defaultValue[1] }
                                        step={ col.customFilter.step || 1 }
                                        defaultValue={ defaultValue } 
                                        value={ filter ? filter.value : defaultValue } 
                                        onChange={ onChange }
                                    />
                                    <div className="rangeValue">
                                        { output( filter ? filter.value : defaultValue ) }
                                    </div>
                                </div>
                            );
                        };
                        break;


                    case "toggle":
                        
                        col.filterMethod = ( filter, row ) => {

                            const value = keyExists( row._original, ( col.customFilter.accessor || col.accessor ), true, 0 );

                            if( ( filter.value == "true" && !value ) ||
                                ( filter.value == "false" && value ) )
                                return false;

                            return true;
                        };

                        col.Filter = ({ filter, onChange }) => {

                            let value = filter ? filter.value : "";

                            const handleClick = () => {
                                if( value == "" )           value = "true";
                                else if ( value == "true" ) value = "false";
                                else                        value = "";
                                onChange( value );
                            };

                            return (
                                <div className={ "customFilter toggle " + value } onClick={ handleClick }>
                                    <SvgIcon icon={ value == "true" ? "check" : value == "false" ? "times" : "asterisk" } type="solid" />   
                                </div> 
                            );
                        };
                        break;

                    case "apSelect":
                        col.headerClassName += " overflowable";
                        col.filterMethod = ( filter, rows ) => {
                            // I currently use apSelect as backend pagination and filterMethod is only used on frontend pagination
                            // so if you need this, you can code it
                            console.error( 'FilterMethod not implemented for ApReactTable:apSelect"' );
                        };
                        col.Filter = ({ filter, onChange }) => {
                            let { type, ...otherProps } = col.customFilter;
                            return <div className="customFilter apSelect">
                                <ApSelect
                                    { ...otherProps }
                                    value={ filter ? filter.value : '' }
                                    onChange={ onChange }
                                    clearable
                                />
                            </div>
                        };
                        break;

                    default:
                        break;

                }
            }

            return col;
        };

        for( let i = 0; i < columns.length; i++)
        {
            // Header group
            if( columns[i].columns )
            {
                for( let j = 0; j < columns[i].columns.length; j++ )
                {
                    columns[i].columns[j] = handleCustomColumn( columns[i].columns[j], i + "_" + j );
                }
            }
            // Plain column (no header group available)
            else
            {
                columns[i] = handleCustomColumn( columns[i], i );
            }

        }

        if( this.props.multiselect )
        {
            let multiselectActionsButton =
                <div className={"dropPanelWrapper multiselect" + ( this.state.multiselectActionsOpen ? " open" : "" ) } ref={ node => this.multiselectActionsRef = node }>
                    <button className="apSimpleButton" onClick={ this.toggleMultiselectActions }>
                        <SvgIcon icon="ellipsis-h" type="solid" />
                        <span className={"apBadge " + ( this.state.selectedRows.length > 0 ? "blue" : "faded" ) }>{ this.state.selectedRows.length }</span>
                    </button>
                    <div className="dropPanel">
                        { this.multiselectRenderer() }
                    </div>
                </div>;

            let headerElement =
                <div className="inputWrapper" onClick={ this.toggleRowSelectionAll }>
                    <input type="checkbox" checked={ this.rowSelected( 'all' ) } readOnly />
                </div>;

            columns.unshift({
                Header: () => multiselectActionsButton,
                headerClassName: "multiselect",
                columns: [{
                    Header: headerElement,
                    //filterable: false,
                    sortable: false,
                    resizable: false,
                    width: 70,
                    headerClassName: "multiselect",
                    className: "multiselect",
                    footerClassName: "multiselect",
                    Cell: row => {
                        if( this.props.multiselect === 'placeholder' )
                            return null;
                        return (
                            <div className="inputWrapper" onClick={ () => this.toggleRowSelection( row.index ) }><input type="checkbox" checked={ this.rowSelected( row.index ) } readOnly /></div>
                        );
                    },
                    Filter: () => {
                        return (
                            <div className="multiselectFiltered" onClick={ () => this.toggleRowSelectionFiltered() }><input type="checkbox" checked={ this.rowSelected( 'filtered' ) } readOnly /></div>
                        );
                    }
                }]
            });
        }

        if( this.props.rowActions || this.props.filterable || haveCustomColumns )
        {
            let editColumnsButton = null;
            let toggleFiltersButton = null;

            if( haveCustomColumns ) {
                editColumnsButton =
                    <div className={"dropPanelWrapper customColumns" + ( this.state.customColumnsOpen ? " open" : "" ) } ref={ node => this.customColumnsRef = node }>
                        <button className="apSimpleButton" onClick={ this.toggleCustomColumns }>
                            <SvgIcon icon="columns" type="solid" />
                        </button>
                        <div className="dropPanel">
                            { this.customColumnsRenderer() }
                        </div>
                    </div>;
            }

            if( this.props.filterable )
                toggleFiltersButton = <button className={"apSimpleButton " + ( this.state.filters === true ? " toggled" : "" ) } onClick={ this.toggleFilters }><SvgIcon icon="filter" type="solid" /></button>;

            columns.push({
                Header: () => editColumnsButton,
                headerClassName: "rowAction",
                columns: [{
                    Header: toggleFiltersButton,
                    filterable: this.state.filters,
                    sortable: false,
                    resizable: false,
                    width: 50,
                    headerClassName: "rowAction",
                    className: "rowAction",
                    footerClassName: "rowAction",
                    Cell: row => this.rowActionRenderer( row.original, row.index ),
                    Filter: row => (
                        <button className="apSimpleButton" onClick={ this.toggleFilters }>
                            <SvgIcon icon="backspace" type="solid" />
                        </button>
                    ),
                }]
            });
        }
        return columns;
    }

    multiselectRenderer()
    {
        // Custom multiselect function
        if( typeof( this.props.multiselect ) == "function" )
        {
            return this.props.multiselect( this.state.selectedRows );
        }

        // Otherwise excpect array of actions
        return (
            <div className="scroller">
                <div className="info">
                    { tr('selected') } { this.state.selectedRows.length } / { this.props.data.length }
                </div>

                { this.props.multiselect.map( ( action, index ) => {

                    if( action.divider )
                        return <div key={ index } className="divider"></div>

                    let classes = ["action"];
                    let disabled = action.disabled;

                    if( this.state.selectedRows.length == 0 )
                        disabled = true;

                    const onClick = () => { 
                        if( disabled )
                            return null;

                        if( typeof( action.action ) == "function" )
                            action.action( this.state.selectedRows );

                        if( action.closeAfter )
                            this.toggleMultiselectActions();
                        
                        if( action.unselectAfter )
                            this.clearSelectedRows();
                    };

                    if( disabled )
                        classes.push("disabled");

                    return (
                        <div key={index} className={ classes.join(" ") } onClick={ onClick }>
                            { action.icon && <SvgIcon icon={ action.icon } type="solid" /> }
                            { action.label }
                        </div>
                    );
                })}

            </div>
        );
    }


    customColumnsRenderer()
    {
        let html = [];
        const columnSwitch = ( index, name, indent ) => {

            const checked = ( this.resolveCustomColumns().indexOf( index ) != -1 );
          
            html.push(
                <div key={ index } className={"customColumnItem" + ( indent ? " indent" : "") } onClick={ () => this.toggleColumn( index ) }>
                    <input type="checkbox" checked={ checked } onChange={ () => {}} />
                    { name }
                </div>
            );
        };


        this.props.columns.slice().forEach( ( col, index ) => {


            // Header group
            if( col.columns && col.customizable )
            {
                html.push( <div key={ index } className="customColumnItem groupHeader">{ col.Header }</div> );
                col.columns.forEach( ( subcol, subindex ) => {

                    if( subcol.customizable )
                    {
                        let title = subcol.Header;
                        if( subcol.HeaderText ) title = subcol.HeaderText;

                        html.push( columnSwitch( index + "_" + subindex, title, true ) );
                    }

                })
            }
            // Plain column (no header group available)
            else {

                if( col.customizable )
                {
                    let title = col.Header;
                    if( col.HeaderText ) title = col.HeaderText;

                    html.push( columnSwitch( index, title ) );
                }


            }


        });


        return (
            <div className="scroller">
                { html }
            </div>
        );
    }

    toggleColumn( colIndex )
    {
        let customColumns = this.resolveCustomColumns();
        const index = customColumns.indexOf( colIndex );
        if( index == -1 )
            customColumns.push( colIndex )
        else
            customColumns.splice( index, 1 );

        // We want to force custom columns from outside ApReactTable
        if( this.props.forceCustomColumns )
            this.props.onCustomColumnChange( customColumns );

        else 
            this.setState({ customColumns });

    }


    rowActionRenderer( row, index )
    {
        // Function provided
        if( typeof( this.props.rowActions ) == "function" )
        {
            return this.props.rowActions( row, index  );
        }
        // Array provided
        else if ( Array.isArray( this.props.rowActions ) )
        {
            return (
                <ApDropdown
                    button={ <SvgIcon className="defaultRowActionIcon" icon="ellipsis-h" type="solid" /> }
                    actions={ this.props.rowActions }
                    actionId={ row.id }
                />
            );
        }
        return <div></div>
    }


    customPagination( data )
    {
        let currentPage = data.page + 1;

        /*
        if (getComponentName() === 'purchaseOrders') {
            currentPage = getCurrentPage() + 1
        }

        if (getComponentName() === 'saleOrders') {
            currentPage = getOrderCurrentPage() + 1
        }
*/
        if (Number.isNaN(Number(currentPage))) {
            currentPage = 1
        }

        // Fix when using backend pagination
        let pageCount = data.pages;
        if( this.props.pages )
            pageCount = this.props.pages;

        let pages = [];
        for( let i = 1; i <= pageCount; i++ ) {
            pages.push( {
                number: i,
                class: "page" + ( i == currentPage ? " current" : "" ),
            });
        }

        const setSelectorValue = ( value, blur ) => 
        {
            value = onlyNumber( value );
            this.setState({ pageSelectorValue: value });
            if( blur )
            {
                if( !value ) value = currentPage;
                value = parseInt( value, 10 ) - 1;
                this.setState({ pageSelectorValue: null }, () => {
                    data.onPageChange( value );
                });
            }
        }

        return (
            <div className="customPagination">

                <div className={ "settings" + ( this.state.paginationSettingsOpen ? " open" : "" ) } ref={ node => this.paginationSettingsRef = node }>
                    <button className={ "apSimpleButton" + ( this.state.paginationSettingsOpen ? " toggled" : "" ) } onClick={ this.togglePaginationSettings }>
                        <SvgIcon icon="list-alt" />
                    </button>
                    <div className="paginationSettings">
                        <div className="padding-small">
                            <span className="apLabel">{ tr('rows_per_page') }:</span>
                            <div className={"apOptionBar count-" + data.pageSizeOptions.length }>
                                { data.pageSizeOptions.map( (size, index) =>
                                    <div
                                        key={index}
                                        className={"option" + ( size == data.pageSize ? " selected" : "" ) }
                                        onClick={ () => data.onPageSizeChange( size ) }
                                    >
                                        { size }
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="prev">
                    <button className="apSimpleButton" disabled={ currentPage <= 1 } onClick={ () => data.onPageChange( (data.page - 1) ) }>
                        <SvgIcon icon="chevron-left" type="solid" />
                    </button>
                </div>
                <div className="pages">
                    { pages.length <= 10 && pages.map( (page, index) =>
                        <ApTooltip key={ index } text={ "Sivu " + page.number } delayed>
                            <div className={ page.class } onClick={ () => data.onPageChange( (page.number - 1) ) }>
                                <div className="ball"></div>
                            </div>
                        </ApTooltip>
                    )}
                    { pages.length > 10 &&

                        <div className="pageSelector">
                            <input 
                                className="pageSelectorInput"
                                value={ ( this.state.pageSelectorValue === null ) ? currentPage : this.state.pageSelectorValue }
                                onChange={ ( e ) => setSelectorValue( e.target.value ) }
                                onBlur={ ( e ) => setSelectorValue( e.target.value, true ) }
                                onKeyPress={ ( e ) => { if( e.key === 'Enter' ) setSelectorValue( e.target.value, true ) } }

                            /> / { pages.length }
                        </div>
                    }
                </div>
                <div className="mobilePages">{ currentPage } / { pages.length }</div>
                <div className="next">
                    <button className="apSimpleButton" disabled={ currentPage >= data.pages } onClick={ () => data.onPageChange( (data.page + 1) ) }>
                        <SvgIcon icon="chevron-right" type="solid" />
                    </button>
                </div>
            </div>
        );
    }

    onPageSizeChange( pageSize, pageIndex )
    {
        if( this.props.manual )
            this.setState({ selectedRows: []});
    }

    onPageChange( pageIndex )
    {
        /*
        if (getComponentName() === 'purchaseOrders') {
            setCurrentPage(pageIndex)
            if (pageIndex === 0 && getFirstPageRefresh()) {
                window.location.reload()
            }
            setFirstPageRefresh(false)
        }

        if (getComponentName() === 'saleOrders') {
            setOrderCurrentPage(pageIndex)
            if (pageIndex === 0 && getOrderFirstPageRefresh()) {
                window.location.reload()
            }
            setOrderFirstPageRefresh(false)
        }*/
        
        if( this.props.manual )
            this.setState({ selectedRows: []});
    }

    togglePaginationSettings()
    {
        this.setState({ paginationSettingsOpen: !this.state.paginationSettingsOpen });
    }

    toggleMultiselectActions()
    {
        this.setState({ multiselectActionsOpen: !this.state.multiselectActionsOpen });
    }

    toggleCustomColumns()
    {
        this.setState({ customColumnsOpen: !this.state.customColumnsOpen });
    }


    renderRow( state, rowInfo, column )
    {
        let customizations = {};

        if( rowInfo && this.rowSelected( rowInfo.index ) )
            customizations.className = "selected";

        if( typeof( this.props.customRowClass ) == "function" )
            customizations.className += " " + this.props.customRowClass( rowInfo );

        return customizations;
    }

    renderCell( state, rowInfo, column )
    {
        let customizations = {};

        if( column.align )
            customizations.className = "align-" + column.align;

        // Column have click event
        if( column.onClick && rowInfo && rowInfo.original )
        {
            // Mark cell as clickable (cursor: pointer + hover effect)
            customizations.className += " clickable";

            // Use function from column
            if( typeof( column.onClick ) == "function" )
                customizations.onClick = () => column.onClick( rowInfo.original );


        }
        return customizations;
    }

    customLoader( { className, loading, loadingText } )
    {
        return (
            <div className={ "customLoader" + ( loading ? " show" : "" ) }>
                <div className="apLoader" />
            </div>
        );
    }

    customNoData()
    {
        let noDataText = tr('no_rows_found');
        if( this.props.noDataText )
            noDataText = this.props.noDataText;

        return (
            <div className="customNoRows">
                <div className="message">
                    <div className="apMsg">
                        <SvgIcon icon="exclamation-circle" type="solid" className="block" />
                        { noDataText }
                    </div>
                </div>
            </div>
        );
    }

    onFetchData( ...a )
    {
        this.props.onFetchData( ...a );
    }

    render()
    {
        let classes = ["apReactTable"];

        if( this.props.clipContent )
            classes.push("clipContent");

        if( this.state.filters )
            classes.push("filtersShowing");


        const overFlow = this.props.noOverflow ? 'none' : 'auto';

        return (
            <div style={{overflowX: overFlow}}>
            <div className={ classes.join(" ") }>

                <ReactTable
                    ref={ ref => this.reactTableRef = ref }
                    manual={ this.props.manual }
                    onFetchData={ this.props.onFetchData ? this.onFetchData : undefined }

                    loading={ this.props.loading }
                    data={ this.props.data }
                    columns={ this.customizeColumns( this.props.columns.slice() ) }
                    filterable={ this.state.filters }

                    //showPagination={ pagination }
                    defaultPageSize={ this.props.defaultPageSize ? this.props.defaultPageSize : 20 }
                    minRows={ this.props.minRows ? this.props.minRows : 5 }

                    PaginationComponent={ this.customPagination }
                    getTrProps={ this.renderRow }
                    getTdProps={ this.renderCell }
                    getTheadThProps={ this.renderCell }
                    LoadingComponent={ this.customLoader }
                    NoDataComponent={ this.customNoData }
                    onPageSizeChange={ this.onPageSizeChange }
                    onPageChange={ this.onPageChange }
                    onResizedChange={ this.props.onResizedChange }

                    defaultFiltered={ this.props.defaultFiltered }
                    defaultSorted={ this.props.defaultSorted }
                />

            </div>
            </div>
        );
    }
};


ApReactTable.propTypes = {
    data:                  PropTypes.array.isRequired,
    columns:               PropTypes.array.isRequired,
    multiselect:           PropTypes.oneOfType([ PropTypes.array, PropTypes.func, PropTypes.string ]),
    rowActions:            PropTypes.oneOfType([ PropTypes.array, PropTypes.func ]),
    pages:                 PropTypes.number,
    rowsPerPage:           PropTypes.number,
    minRows:               PropTypes.number,
    maxRows:               PropTypes.number,
    filterable:            PropTypes.bool,
    clipContent:           PropTypes.bool,
    showFiltersInitially:  PropTypes.bool,
    rememberId:            PropTypes.string,
    customRowClass:        PropTypes.func, 
    defaultPageSize:       PropTypes.number,

    // Required when customColumns are managed from outside of the component
    forceCustomColumns:   PropTypes.bool,
    customColumns:        PropTypes.array,
    onCustomColumnChange: PropTypes.func,
    selectedRowsToParent: PropTypes.func,
    showModal:            PropTypes.bool
};

export default ApReactTable;


export function colPreset( options ) {
    
    switch( options.type )
    {

        case "nameDesc":
            
            let filteredFields = [];
            if( options.name )
                filteredFields.push( "_original." + options.name )

            if( options.desc )
                filteredFields.push( "_original." + options.desc );

            return {
                customFilter: {
                    type: "text",
                    fields: filteredFields
                },
                ...options,
                Cell: ( props ) => 
                    <div className="nameCell">
                        <strong>{ props.original[ options.name ] }</strong><br />
                        <small>{ props.original[ options.desc ] }</small>
                    </div>
            };


        case "icon":
            return {
                width: 50, 
                resizable: false, 
                align: "center",
                Cell: ( props ) =>  {
                    let value = keyExists( props.original, options.accessor, true, false );
                    return <div className="iconCell">{ value ? value : "" }</div>;
                },
                customFilter: { type: "text" },
                ...options,
                headerClassName: "overflowableOnHover", 
                Header: 
                    <div className="iconHeader">
                        <ApTooltip text={ options.tooltip }>
                            <SvgIcon icon={ options.icon || "question" } type={ options.iconType || "solid" } />
                        </ApTooltip>
                    </div>,
                HeaderText: options.tooltip, // Used on show columns list
            };

        case "date":
            return {
                Cell: ( props ) => {
                    let value = keyExists( props.original, options.accessor, true, false );
                    if( value && value.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/) )
                        value = sqlToDateInput( value );

                    return (
                        <div className="dateCell">
                            { value }
                        </div>
                    );
                },
                customFilter: { type: "date" },
                ...options
            };
        
        case "dateAndDiff":
            return {
                Cell: ( props ) => {
                    let value = keyExists( props.original, options.accessor, true, false );
                    let diff = null;

                    if( value && value.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/) )
                    {
                        if( Math.abs( moment().diff( moment( value ), 'days')) < 1 )
                            diff = tr('today');
                        else
                            diff  = moment().locale(currentLang()).to( moment( value ) );
                        value = sqlToDateInput( value );
                    }
                    // console.log(diff)
                    return (
                        <div className="dateCell">
                            { value }<br />
                            <small>{ diff}</small>
                        </div>
                    );
                },
                customFilter: { type: "date" },
                ...options
            };

        case "currency":
        case "number":
        case "numberNice":
        case "percent":
            return {
                align: "right",
                Cell: ( props ) => {
                    let value = props.value;
                    let decimals = 0;
                    let unit = null;

                    if( options.type === 'percent' )
                    {
                        decimals = 1;
                        unit = '%';
                    }
                    if( options.type === 'currency')
                    {
                        decimals = 2;
                        unit = auth.getCurrencySign();
                    }

                    if( options.unit ) unit = options.unit;

                    if( typeof unit === 'function' )
                        unit = unit(props.original )

                    if( options.decimals || options.decimals === 0 ) decimals = options.decimals;

                    if( ['currency', 'numberNice', 'percent'].includes( options.type ))
                        value = formatMoney( value, decimals );

                    return (
                        <div className="numberCell">
                            { value }
                            { value && unit && <span className="unit"> { unit }</span> }
                        </div>
                    );
                },
                customFilter: { type: "range" },
                ...options
            };

        default:
            return {
                customFilter: { type: "text" },
                ...options
            };
    }

}

