import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import './ApPath.css';

class ApPath extends React.Component {

    render()
    {
        const items = this.props.items || [];
        let classes = ["apPath count-" + items.length ];

        if( this.props.hideFromEnd )
            classes.push( "hideFromEnd" );

        if( this.props.boldLast )
            classes.push( "boldLast" );

        return (
        	<span className={ classes.join(" ") }>

                { this.props.icon && <SvgIcon icon={ this.props.icon } type={ this.props.iconType || "solid" } /> }

                { items.map( ( item, level ) => {

                    let itemClasses = [ "item", "level-" + level ];
                    let delimiter = this.props.delimiter || " / ";

                    if( level === 0 )
                        itemClasses.push( "root" );

                    if( level + 1 == items.length )
                    {
                        itemClasses.push( "last" );
                        delimiter = false;
                    }

                    return (
                        <React.Fragment key={ level }>
                            <span className={ itemClasses.join(" ") }>{ item }</span>
                            { delimiter && <span className="delimiter">{ delimiter }</span> }
                        </React.Fragment>
                    );
                })}
        	</span>
        );
    }
};

ApPath.propTypes = {
    items:          PropTypes.array.isRequired, 
    icon:           PropTypes.string,
    iconType:       PropTypes.string,
    delimiter:      PropTypes.node,
    hideFromEnd:    PropTypes.bool,
    boldLast:       PropTypes.bool,
};

export default ApPath;

