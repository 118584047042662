import React 		from 'react';
import autoBind 	from 'react-autobind';

import { Switch, Route, Redirect } from 'react-router-dom';

import auth 		from 'services/Authed/Authed.js';
import routes 		from 'routes/extra.js';
import config		from 'env.js';

import NavTop 		from '../NavTop/NavTop.js';
import NavSide 		from '../NavSide/NavSide.js';
import ErrorBoundary from '../ErrorBoundary.js'
import IdleScreen from './IdleScreen/IdleScreen.js';
import Popper from 'layouts/Popper/Popper.js';


import ApButton		from 'common/ApButton/ApButton.js';
import ApInput		from 'common/ApInput/ApInput.js';

class ComponentWithCallbacks extends React.Component
{

	UNSAFE_componentWillMount()
	{

		window.emitter.removeAllListeners('goTo');
		window.emitter.addListener('goTo', args => {
			if (args.replace)
				this.props.history.replace(args);
			else
				this.props.history.push(args);
		});		

	}

	render()
	{

		let { component, ...props } = this.props;

		return React.createElement(component, {...props });

	}

}

class ExtraLayout extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			url: null,
			breadcrumbs: [],
			sideNav: {
				selected: null,
				minified: ( window.innerWidth < 1000 ? true : false )
			}
		};

		autoBind(this);

	}

	UNSAFE_componentWillMount()
	{

		this.unlisten = this.props.history.listen( location => {
			this.setState({ url: location.pathname });
		});

	}

	componentWillUnmount()
	{

		this.unlisten();

	}

	setTitle(title)
	{

		document.title = (title) ? 'EASY-PRO :: ' + title : 'EASY-PRO';

	}

	setBreadCrumbs(breadcrumbs)
	{

		if (JSON.stringify(breadcrumbs) !== JSON.stringify(this.state.breadcrumbs))
		{
			this.setState({ breadcrumbs });
		}

	}

	setActiveMenu(menu)
	{

		if (menu && menu !== this.state.sideNav.selected) 
		{
			let sideNav = { ...this.state.sideNav };
			sideNav.selected = menu;
			this.setState({ sideNav });
		}

	}

	toggleSideNav()
	{

		var sideNav = { ...this.state.sideNav };
		sideNav.minified = !sideNav.minified;
		this.setState({ sideNav });

	}

	selectSideNav(id)
	{

		var sideNav = { ...this.state.sideNav };
		sideNav.selected = id;
		this.setState({ sideNav });

	}

	render()
	{

		if (!auth.isLoggedIn())
		{
			return <Redirect to="/login" />
		}

		return(
			
			<div className={"mainWrapper" + (this.state.sideNav.minified ? " sideNavMinified" : "")}>
				<IdleScreen />
				<Popper />

				<NavTop url={ this.state.url } />

				<NavSide
					ref={ (ref) => {window.sidebarRef = ref} }
					selected={this.state.sideNav.selected}
					minified={this.state.sideNav.minified}
					handleMinify={this.toggleSideNav}
					handleSelect={this.selectSideNav}
				/>

				<div className="content">

					<Switch>
						{routes.map((route, index) => {

							return <Route
								key={index}
								exact={true}
								path={route.path}

								breadcrumbs={route.breadcrumbs}
								title={route.title}
								menu={route.menu}

								render={(props) => {
									
									this.setTitle(route.title);

									const componentWithCallbacks = <ComponentWithCallbacks
										{...props}
										component={route.component}
										title={route.title}
										breadcrumbs={route.breadcrumbs}
										menu={route.menu}
										setBreadCrumbs={this.setBreadCrumbs}
										setActiveMenu={this.setActiveMenu}
									/>

									if (config.dev)
										return componentWithCallbacks;
									else
									{
										return <ErrorBoundary route={props}>
											{componentWithCallbacks}
										</ErrorBoundary>
									}

								}}
							/>
						})}						
					</Switch>

				</div>

			</div>

		);

	}

}

export default ExtraLayout;
