import React from 'react';
import autoBind from 'react-autobind';

import TimetrackingApprove from './tabs/Approve.js';
import OldHours from './tabs/OldHours.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';

export default class Timetracking extends React.Component
{

	constructor(props)
	{

		super(props);

		autoBind(this);

	}

	render()
	{

		return(
			<div className="apBox">

			<div className="apBoxHeader">
				<h1> Työaikojen hyväksyntä </h1>
				<p> Tarkista laskutettavia työtunteja ja hyväksy tai hylkää ne. </p>
			</div>
				
				<ApTabs>

					<ApTab icon="calendar-check" label="Työaikojen hyväksyntä">
						<TimetrackingApprove />
					</ApTab>

					<ApTab icon="calendar-week" label="Aikaisemmat tuntikirjaukset">
						<OldHours />
					</ApTab>

				</ApTabs>
			</div>
		);

	}

}