/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import auth from 'services/Authed/Authed.js';

import { keyExists, formatMoney } from  'services/Helpers/Helpers.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApUserImage from 'common/ApUserImage/ApUserImage.js';

import './UserBrowser.css';

export default class UserBrowser extends React.Component {

    constructor(props) 
    {
        super(props);
        autoBind(this); 
    }

    arrowDisabled( direction )
    {
        const selectedUser = this.props.selectedUser;
        const users = this.props.users;

        if( !users || !selectedUser || users.length == 0 )
            return true;

        const limit = direction == "prev" ? users[0].id : users[ users.length - 1 ].id;

        if( limit === selectedUser )
            return true;

        return false;
    }

    offsetUser( offset = 1 )
    {
        const index = this.props.users.findIndex( u => u.id == this.props.selectedUser );
        const target = this.props.users[ index + offset ];
        if( target )
            this.props.onChangeUser( target.id );
    }

    render() 
    {

        return (
            <div className="timetrackingUserBrowser">
                <div className="controls left">
                    <div className={ "arrow prev" + ( this.arrowDisabled( "prev" ) ? " disabled" : "" ) } onClick={ () => this.offsetUser( -1 ) }>
                        <SvgIcon icon="chevron-left" type="solid" />
                    </div>
                </div>
                <div className="users">
                    { this.props.users.map( user => {                     
                        let classes = [ "user" ];
                        
                        if( this.props.selectedUser == user.id )
                            classes.push( "selected" )

                        return (
                            <div key={ user.id } className={ classes.join(" ") } onClick={ () => this.props.onChangeUser( user.id ) }>
                                <ApUserImage user={ user } size="small" />
                                <strong>{ user.name }</strong><br />
                                <small>Kirjauksia: { user.entry_count }</small>
                            </div>
                        );
                    })}
                </div>
                <div className="controls right">
                    <div className={ "arrow next" + ( this.arrowDisabled( "next" ) ? " disabled" : "" ) } onClick={ () => this.offsetUser( 1 ) }>
                        <SvgIcon icon="chevron-right" type="solid" />
                    </div>
                </div>
            </div>
        );
    }
}
