import React from 'react';
import PropTypes from 'prop-types';

import ApModal from 'common/ApModal/ApModal.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';


/**
 * Simple confirmation dialog with yes and no answers
 *
 * Props:
 *  show:      boolean that will handle displaying the modal
 *  header:    header text
 *  body:      body text
 *  onConfirm: function called on confirmation
 *  onCancel:  function called on dialog cancel
 *  onClose:   function will be called on dialog close (both success and cancel)
 *  meta:      data passed thrugh confirm modal
 *
 */
class Confirm extends React.Component {
    constructor(props)
    {
        super(props);
        this.close = this.close.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    close()
    {
        if(this.props.onCancel)
            this.props.onCancel();

        if(this.props.onClose)
            this.props.onClose();
    }

    onConfirm()
    {
        if(this.props.onConfirm)
            this.props.onConfirm(this.props.meta);

        if(this.props.onClose)
            this.props.onClose();
    }

    render()
    {
        const header = ( this.props.header ) ? this.props.header : 'Varmistus';
        const body = ( this.props.body ) ? this.props.body : 'Oletko varma?';

        return <div className="ApModalConfirm">
            <ApModal
                show={ this.props.show }
                handleClose={ this.close }
                closeFromBg
                className="narrow"
                header={
                    <div className="padding-small">
                        <h4>{ header }</h4>
                    </div>
                }
                body={
                    <div className="padding">
                        <div>{ body }</div>
                    </div>
                }
                footer={
                    <div className="footer padding">
                        <ApButton className="cancel" onClick={ this.close }>
                            <SvgIcon icon="times" type="solid" />
                            Ei
                        </ApButton>

                        <ApButton className="save" color="green" onClick={ this.onConfirm }>
                            <SvgIcon icon="check" type="solid" />
                            Kyllä
                        </ApButton>
                    </div>
                }
            />
        </div>
    }
}
Confirm.propTypes = {
    show: PropTypes.bool.isRequired,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    meta: PropTypes.any,
};

export default Confirm;
