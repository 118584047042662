import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import Measure from 'react-measure'

import './ApStickyBar.css';

class ApStickyBar extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            bounds: {},
            breakpoint: 0,
            isSticky: false,
            navBarHeight: 50, 
        }

        autoBind(this); 
    } 

    componentDidMount() {
        document.addEventListener('scroll', this.handleScroll );
        this.updateBounds();
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll );
    }

    updateBounds()
    {   
        if( this.node )
        {
            const wasSticky = this.state.isSticky;

            if( this.state.isSticky )
                this.setState({ isSticky: false });

            const bounds = this.node.getBoundingClientRect();
            const defaultOffset = ( !this.props.bottomMode ? this.state.navBarHeight : 0 ); 
            const offset = typeof( this.props.offset ) === "number" ? this.props.offset : defaultOffset;

            let breakpoint = bounds.top + window.scrollY - offset;

            if( this.props.bottomMode )
                breakpoint = breakpoint - window.innerHeight + bounds.height + offset;

            this.setState({ 
                bounds: bounds,
                breakpoint: breakpoint,
                isSticky: wasSticky
            }, this.handleScroll );
        }
    }

    handleScroll( e ) {
        
        const scrollAmount = window.pageYOffset || document.documentElement.scrollTop;
        let isSticky = false;

        if( this.state.bounds )
        {   
            if( !this.props.bottomMode )
            {
                if( scrollAmount > this.state.breakpoint )
                    isSticky = true;
            }
            else 
            {
                if( scrollAmount < this.state.breakpoint )
                    isSticky = true;
            }
            
        }
        this.setState({ isSticky });
    }

    render()
    {
        let classes = ["ApStickyBar"];
        let styles = {
            wrapper: {},
            content: {},
        };

        if( this.node )
            styles.wrapper.height = this.state.bounds.height + "px";

        if( this.state.isSticky ) 
        {
            classes.push("sticky");

            if( !this.props.bottomMode )
                styles.content.top = ( typeof( this.props.offset ) === "number" ? this.props.offset : this.state.navBarHeight ) + "px";
            else 
                styles.content.bottom = ( typeof( this.props.offset ) === "number" ? this.props.offset : 0 ) + "px";

            styles.content.left = this.state.bounds.left + "px";
            styles.content.width = this.state.bounds.width + "px";

            if( this.props.zIndex )
                styles.content.zIndex = this.props.zIndex;
        }
        else
        {
            classes.push("noSticky"); // Added so can add custom styles to element when not sticky
        }

        return (
            <Measure
                bounds
                onResize={ this.updateBounds}
            >
            {({ measureRef }) =>
                <div ref={ measureRef } className={ classes.join(" ") } style={ styles.wrapper }>
                    <div className="stickyContent" ref={node => this.node = node} style={ styles.content }>
                        { this.props.children }
                    </div>
                </div>
            }
            </Measure>
        );
    }
};


ApStickyBar.propTypes = {
    offset:     PropTypes.number,
    zIndex:     PropTypes.number,
    bottomMode: PropTypes.bool,
};

export default ApStickyBar;

