/* eslint-disable eqeqeq */
import React from 'react';
import './TimerMenu.css';

import Stopwatch from './Stopwatch/Stopwatch.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';

class TimerMenu extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            status: "stopped",
            startTime: false,
            stopTime: false,
            pauses: [],
            hoursWork: 0,
            hoursPause: 0,
            hoursGoal: 0
        };
        this.startTimer = this.startTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.pauseTimer = this.pauseTimer.bind(this);
        this.update = this.update.bind(this);
    }
    
    UNSAFE_componentWillMount() 
    {
        this.setState({ hoursGoal: 7.5 * 3600 }); // TODO = 7.5 | 8
        this.update();
    }

    componentWillUnmount() 
    {
        clearTimeout( this.tick );
    }

    startTimer() 
    {
        this.setState({
            status: "playing",
            startTime: Math.floor( Date.now() / 1000 ),
            stopTime: false,
            pauses: []
        });
        this.update();
    }

    stopTimer() 
    {
        this.setState({
            status: "stopped",
            stopTime: Math.floor( Date.now() / 1000 ) 
        });
        this.update();
    }

    pauseTimer() 
    {
        var now = Math.floor( Date.now() / 1000 );
        var pauses = this.state.pauses;
        
        // Start new pause
        if( this.state.status == "playing" ) {
            pauses.push( {
                start: now,
                stop: false
            });
            this.setState({ 
                status: "paused",
                pauses: pauses
            });
        }
        // Stop last pause
        else if (this.state.status == "paused" ) {
            pauses[ pauses.length - 1 ].stop = now;
            this.setState({ 
                status: "playing",
                pauses: pauses
            });
        }
        this.update();
        
    }

    update() {

        if( this.state.status == "playing" || this.state.status == "paused" ) {

            var newState = {
                hoursWork: 0,
                hoursPause: 0
            }

            if( this.state.startTime ) {

                var now = Math.floor( Date.now() / 1000 );
                var endTime = ( this.state.stopTime ? this.state.stopTime : now );
                // var goalTime = this.state.startTime + ( this.state.hours * 3600 ); 

                var total = endTime - this.state.startTime;
                var pause = 0;

                for( var i = 0; i < this.state.pauses.length; i++ ) {
                    var pauseEnd = ( this.state.pauses[i].stop ? this.state.pauses[i].stop : now );
                    pause += pauseEnd - this.state.pauses[i].start;
                }
                
                newState = {
                    hoursWork: total - pause,
                    hoursPause: pause
                };

            }
        }

        this.setState( newState );

        clearTimeout( this.tick );
        this.tick = setTimeout( this.update, (this.props.open ? 1000 : 10000 ));
    }

    secondsToHms( seconds ) {
        var h = Math.floor( seconds / 3600 );
        var m = Math.floor( ( seconds % 3600 ) / 60 );
        var s = Math.floor( seconds % 60 );

        return {
            h: ("0" + h).substr(-2),
            m: ("0" + m).substr(-2),
            s: ("0" + s).substr(-2)
        };
    }

    timestampToTime( timestamp, format )
    {
        if( timestamp ) {
            var date = new Date(timestamp*1000);
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var seconds = date.getSeconds();

            if( typeof( format ) == "string" ) {
                return format.replace('hh', ("0" + hours).substr(-2))
                             .replace('mm', ("0" + minutes).substr(-2))
                             .replace('ss', ("0" + seconds).substr(-2))
                             .replace('h', hours )
                             .replace('m', minutes )
                             .replace('s', seconds );
            }
            return {
                h: hours,
                m: minutes.substr(-2),
                s: seconds.substr(-2)
            };
        }
        return false;
    }

    render()
    {

        var icon = "clock";
        if( this.state.status == "playing" )        icon = "play-circle";
        else if ( this.state.status == "paused" )   icon = "pause-circle";

        var hms = this.secondsToHms( this.state.hoursWork );
       
        return (
            <div className={"block timer " + this.state.status }>
                <div className="button" onClick={() => { this.props.toggleModal("timer"); setTimeout( this.update, 500 ); } }>
                    { this.state.status != "stopped" && (
                        <span className="text">{ hms.h + ":" + hms.m }</span>
                    )}
                    <SvgIcon icon={icon} />
                </div>
                <div className={"topModal" + (this.props.open ? " open" : "" ) }>
                    <div className="padding-small">

                        <div className="bg">

                            <button className="cornerButton left apTooltipper">
                                <SvgIcon type="solid" icon="car" />
                                <span className="apTooltip bottom delayed">Lisää matka</span>
                            </button>

                            <button className="cornerButton right apTooltipper">
                                <SvgIcon type="solid" icon="history" />
                                <span className="apTooltip bottom delayed">Näytä historia</span>
                            </button>

                            <Stopwatch 
                                value={ this.state.hoursWork }
                                max={ this.state.hoursGoal }
                                status={ this.state.status } 
                                time={ hms } 
                            />

                            <div className="controls">
                                <button className="apButton green large iconTop" onClick={this.startTimer} disabled={ (this.state.status != "stopped" ? true : false ) }>
                                    <SvgIcon type="solid" icon="sign-in-alt" />
                                    <span>Leimaa sisään</span>
                                </button>

                                <button className="apButton orange large iconTop" onClick={this.pauseTimer} disabled={ (this.state.status == "playing" || this.state.status == "paused" ? false : true ) }>
                                    <SvgIcon type="solid" icon="coffee" />
                                    <span>{ this.state.status != "paused" ? "Aloita" : "Lopeta" } tauko</span>
                                </button>

                                <button className="apButton red large iconTop" onClick={this.stopTimer} disabled={ (this.state.status == "playing" || this.state.status == "paused" ? false : true ) }>
                                    <SvgIcon type="solid" icon="sign-out-alt" />
                                    <span>Leimaa ulos</span>
                                </button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    }
};


export default TimerMenu;

