import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './ApButton.css';

class ApButton extends React.Component {

    constructor( props )
    {
        super( props );
        this.clickHandler = this.clickHandler.bind(this);
    }

    clickHandler(e) {
        if( typeof( this.props.onClick ) === "function" ) {
            this.props.onClick(e);
        };
    }

    render()
    {
        let {
            size,
            color,
            fullWidth,
            ghost,
            narrow,
            loading,

            className,
            onClick,

            children,
            ...otherProps

        } = this.props;

        let classNames = ["apButton"];

        if( size ) classNames.push( "size-" + size );
        if( color ) classNames.push( "color-" + color );
        if( fullWidth ) classNames.push( "full-width");
        if( ghost ) classNames.push("ghost");
        if( narrow ) classNames.push("narrow");
        if( loading ) classNames.push("loading");

        if( className ) classNames.push(className);


        if( typeof( onClick ) == "string" )
            return (
                <NavLink to={ onClick }>
                    <button
                        { ...otherProps }
                        className={ classNames.join(" ") }
                    >
                        { loading && <div className="apLoader"></div> }
                        <div className="holder">
                            { children }
                        </div>
                    </button>
                </NavLink>
            );
        

        return (
            <button
                { ...otherProps }
                className={ classNames.join(" ") }
                onClick={ this.clickHandler }
            >
                { loading && <div className="apLoader"></div> }
                <div className="holder">
                    { children }
                </div>
            </button>
        );
    }
};

ApButton.propTypes = {
    onClick: PropTypes.oneOfType([ PropTypes.func, PropTypes.string ]), // Use string (url) if you wanna redirect
    size: PropTypes.string,
    color: PropTypes.string,
    ghost: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    narrow: PropTypes.bool,
    loading: PropTypes.bool,
    className: PropTypes.string,
};

export default ApButton;

