import React from 'react';
import api from 'services/Api/Api.js';

import {
    Alert,
} from 'react-bootstrap';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
        this.logToBackend = this.logToBackend.bind(this);
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        this.logToBackend(error, info);
    }

    logToBackend(error, info)
    {
        /*
        api({
            method: 'post',
            url: '/logerror',
            data: {
                error: error.message,
                info:info,
                route: this.props.route,
                navigator: navigator.userAgent,
            },
        }).then((response) => {
            console.log('Error logged');
        }).catch((error) => {
            console.log('Error', error);
        });
        */
    }

    render() {
        if (this.state.hasError) {
            return <Alert bsStyle="danger" onDismiss={this.handleDismiss}>
                <h4>Sivustolla taphtui odottamaton virhe.</h4>
                <p>Virheestä lähettiin raportti sivuston ylläpitäjille.</p>
            </Alert>
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
