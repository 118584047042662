import React from 'react';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import { tr } from 'services/Helpers/Helpers';

export default class Notfound extends React.Component {
    render() {
        return (
        	<div id="PageNotFound">
                <div className="apErrorMsg">
	                <h2>{ tr('page_not_found') }</h2>
	                <p>{ tr('page_not_found_info1') }</p>
	                <p>{ tr('page_not_found_info2', [<SvgIcon className="small-inline" icon="exclamation-triangle" type="solid" />]) }</p>
                </div>
	        </div>
        );
    }
}
