import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import File from 'common/File/File.js';

import ApButton from 'common/ApButton/ApButton.js';
import {ApAddon, ApInputStack} from 'common/ApInput/ApInput.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import './Documents.css';

export default class Documents extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			documents: []
		};

		autoBind(this);

	}

	UNSAFE_componentWillMount()
	{

		//this.getSampleContent();
		this.getDocuments();

	}

	getDocuments()
	{

		api({
			method: 'get',
			url: 'extranet/client/documents'
		}).then((response) => {
			console.log(response);
			this.constructFileList(response);
		}).catch((error) => {
			console.log(error);
		});

	}

	getSampleContent()
	{

		const documents = [
			{
				id: 1,
				fileName: "test_doc_1",
				extension: "PDF",
				description: "Testidokumentti pdf-muodosssa"
			},
			{
				id: 2,
				fileName: "laatujarj2019.pdf",
				extension: "PDF",
				description: "Laatujärjestelmä 2019"
			}
		];

		this.setState({ documents });

	}

	constructFileList(files)
	{

		const documents = [];

		files.map((file, index) => {
			documents.push({
				id: file.id,
				fileName: file.original_name,
				extension: file.extension,
				description: 'Sample content'
			});
		});

		this.setState({ documents });

	}

	getFile()
	{

		console.log("here here");
		return <ApAddon><File id='71' name='file-sample_150kB.pdf'/></ApAddon>

	}

	render()
	{

		return(
			
			<div className="apBox" id="extranetDocuments">

				<div className="apBoxHeader">
					<h1> Dokumentit </h1>
				</div>

				{this.state.documents.map((doc, index) => {
					return (
						<div className="files documentRow" key={index}>
							<div className="documentRow">
								<File fileId={doc.id} name={doc.fileName}> 
									<ApButton className="fileButton" size="small">
										<SvgIcon icon="download" type="solid" />
									</ApButton>
									<span style={{paddingLeft: '8px'}}>
										Lataa {doc.description} ({doc.fileName})
									</span>
								</File>
							</div>
						</div>
					)
				})}

			</div>

		);

	}

}
