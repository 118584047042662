/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import auth from 'services/Authed/Authed.js';

import { keyExists, formatMoney } from  'services/Helpers/Helpers.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';

import Day from './Day/Day.js';

export default class Week extends React.Component {

    constructor(props) 
    {
        super(props);
        autoBind(this); 
    }

    UNSAFE_componentWillMount()
    {

        console.log(this.props);

    }

    getSetting( path, column, id, idColumn = "id" )
    {
        if( !this.props.settings )
            return "";

        const item = keyExists( this.props.settings, path, true, [] ).find( s => s[ idColumn ] == id );
        if( !item )
            return "";

        if( !column )
            return item;

        if( column in item )
            return item[ column ];

        return "";
    }

    renderOverview( data, weekly = false )
    {
        let counts = {
            total_hours:       0,
            total_money:       0,
            total_distance:    0,
            total_travel_hours:0,
            total_allowance:   0,
            total_expenses:    0,
            hours_regular:     0,
            hours_overtime:    0,
            hours_notation:    0,
            hours_travel:      0,
            hours_absence:     0,
            money_travel:      0,
            money_allowance:   0,
            money_expense:     0,
        };
        let workNumber = '';
        let entryID = '';

        let hours = [];
        let travels = [];
        let expenses = [];
        let allowances = [];

        let storage = {
            installations:  [],
            chargeables:    [],
        };

        const addEntry = ( entry ) => {

            if (!entry.is_misentry || !weekly)
            {
                switch (entry.type)
                {
                    case 'timetracking_hour':
                        counts.total_hours += parseFloat(entry.amount);    
                        break;
                    case 'timetracking_travel':
                        counts.total_distance += parseFloat(entry.amount);
                        break;
                    case 'timetracking_travel_hours':
                        counts.total_travel_hours += parseFloat(entry.amount);
                        break;
                    case 'timetracking_expense':
                        counts.total_expenses += parseFloat(entry.amount);
                        break;
                    case 'timetracking_allowance':
                        counts.total_allowance += parseFloat(entry.amount);
                        break;
                    default:
                }
                if (!weekly && entry.customer_work_number) {
                    workNumber = entry.customer_work_number;
                }
                entryID= entry.id;
            }

        };


        if( weekly )
            Object.keys( data ).map( date => {
                data[ date ].forEach( entries => addEntry( entries ) );
            });
        else 
            addEntry( data );

        storage.total = storage.installations.length + storage.chargeables.length;
        
        // Add absence hours to total hours 
        // counts.total_hours += counts.hours_absence;

        const renderHourRow = ( h ) => {
            return <div key={ h.id }>{ this.getSetting( "hour_types", "name", h.hour_type_id ) }: <strong>{ h.hours } h </strong></div>;
        };

        const tooltipText = ( type ) => {

            if( type === "hours" && weekly )
                return (
                    <div>
                        <strong>Viikon tunnit: { counts.total_hours } h</strong><br />
                        { counts.hours_regular > 0 && <div>- Normaalit tunnit: { counts.hours_regular } h </div> }
                        { counts.hours_overtime > 0 && <div>- Ylityöt: { counts.hours_overtime } h </div> }
                        { counts.hours_travel > 0 && <div>- Matkatunnit: { counts.hours_travel } h </div> }
                        { counts.hours_absence > 0 && <div>- Poissaolot: { counts.hours_absence } h </div> }
                        { counts.hours_notation > 0 && 
                            <div>
                                <br />
                                <strong>Muut tunnit: </strong><br />
                                - Palkkiot/lisät: { counts.hours_notation } h<br />
                            </div>
                        }
                    </div>
                );

            if( type === "hours" && hours.length > 0 )
            {
                const notations = hours.filter( h => h.is_notation );
                return (
                    <div>
                        <strong>Työtunnit: </strong>
                        { hours.filter( h => !h.is_notation ).map( renderHourRow ) }
                        { notations.length > 0 && 
                            <div>
                                <br />
                                <strong>Palkkiot/lisät: </strong>
                                { notations.map( renderHourRow ) }
                            </div>
                        }
                    </div>
                );
            }

            if( type === "hours" && counts.hours_absence > 0 )
                return <strong>Poissaolotunnit: { counts.hours_absence } h</strong>;
            
            if( type === "hours" && counts.hours_travel > 0 )
                return <strong>Matkatunnit: { counts.hours_travel } h</strong>;
                
            if( type === "overtime" && weekly )
                return <strong>Viikon ylityöt: { counts.hours_overtime } h</strong>;

            if( type === "overtime" && hours.filter( h => h.is_overtime ).length > 0 )
                return (
                    <div>
                        <strong>Ylityötunnit: </strong>
                        { hours.filter( h => h.is_overtime ).map( renderHourRow ) }
                    </div>
                );

            if( type === "allowance" && counts.total_allowance > 0 )
                return <strong>Päivärahat: { counts.total_allowance } kpl</strong>;

            return false;
        };

        return (
            <div className={ "overview " + ( weekly ? "weekly" : "daily" ) }>
                <div className={ "block-work-number"}>
                    { weekly && <span className="text">Asiakastyönumero</span> }
                    {!weekly && workNumber && <span className="text client_work_number" onClick={this.props.old ? null : ()=>(this.props.onEditWorkNumber( entryID, workNumber ))}> 
                        {workNumber} {!this.props.old && <SvgIcon className="indicator" icon="pen" type="solid" />}
                     </span> }
                     { !workNumber && !weekly && <span className="text client_work_number" onClick={this.props.old?null: ()=>(this.props.onEditWorkNumber( entryID, "" ))}> 
                        {!this.props.old && <SvgIcon icon="pen"  className="show" type="solid" />}
                     </span> }
                </div>
                <div className={ "block hours" + ( !counts.total_hours ? " empty" : "" ) }>
                    <ApTooltip text={ tooltipText( "hours" ) }>
                        { weekly && <SvgIcon icon="clock" type="solid" /> }
                        <span className="text">{ counts.total_hours } h</span>
                    </ApTooltip>
                </div>
                <div className={ "block travel" + ( !counts.total_travel_hours ? " empty" : "")} >
                    <ApTooltip text={ tooltipText( "traveldistance" ) }>
                        { weekly && <SvgIcon icon="road" type="solid" /> }
                        <span className="text"> { counts.total_travel_hours } h </span>
                    </ApTooltip> 
                </div>
                <div className={ "block travel" + ( !counts.total_distance ? " empty" : "")} >
                    <ApTooltip text={ tooltipText( "traveldistance" ) }>
                        { weekly && <SvgIcon icon="road" type="solid" /> }
                        <span className="text"> { counts.total_distance } km </span>
                    </ApTooltip> 
                </div>
                <div className={ "block expense" + ( !counts.total_expenses ? " empty" : ""  )}>
                    <ApTooltip text={ tooltipText( "money" ) }>
                        { weekly && <SvgIcon icon="money-bill-wave" type="solid" /> }
                        <span className="text"> { counts.total_expenses } € </span>
                    </ApTooltip> 
                </div>
                <div className={ "block expense" + ( !counts.total_allowance ? " empty" : ""  )}>
                    <ApTooltip text={ tooltipText( "allowance" ) }>
                        { weekly && <SvgIcon icon="money-bill-wave" type="solid" /> }
                        <span className="text"> { counts.total_allowance } kpl</span>
                    </ApTooltip> 
                </div>
            </div>
        );
    }

    render() 
    {
 
        return (
            <div className="week" key={ this.props.week }>

                <div className="weekName" style={{minWidth: 1200}}>
                    Viikko { this.props.week.split("-")[ 1 ] } { this.props.old ? `(${this.props.week.split("-")[ 0 ]})` : null }

                    { !this.props.old &&
                    <div className="selectWeek" onClick={ () => this.props.onWeekSelect( this.props.week, !this.props.weekSelected ) }>
                        <input 
                            type="checkbox" 
                            checked={ this.props.weekSelected }
                            readOnly
                        />
                        Valitse viikko
                    </div>
                    }

                    { this.renderOverview( this.props.days, true ) }

                </div>

                { Object.keys( this.props.days ).map( date => {
                    return (
                        <Day
                            key={ date }
                            date={ date }
                            entries={ this.props.days[ date ] }
                            settings={ this.props.settings }
                            renderOverview={ this.renderOverview }
                            selectedEntries={ this.props.selectedEntries }
                            changes={ this.props.changes }
                            showReadOnly={ this.props.showReadOnly }
                            // showSalaryExtras={ this.props.showSalaryExtras }
                            // salaryExtras={ this.props.salaryExtras[ date ] }
                            statuses={ this.props.statuses }
                            onToggleReadOnly={ this.props.onToggleReadOnly }
                            onToggleStatus={ this.props.onToggleStatus }
                            onEntrySelect={ this.props.onEntrySelect }
                            onEditComment={ this.props.onEditComment }
                            old={this.props.old}
                        />
                    );
                })}

            </div>
        );
    }
}
