import React from 'react';
import autoBind from 'react-autobind';
import Notfound  from 'layouts/Notfound/Notfound';

import ApButton          from 'common/ApButton/ApButton.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';

// import { getComponentStatusId } from 'modules/Storage/common/StorageHelpers.js';
import { hasPermissions, tr } from 'services/Helpers/Helpers.js';

import CitList from 'modules/CustomerPortal/common/ComponentInstanceTable/CitList/CitList.js';
import NewComponent from './NewComponent/NewComponent.js';
import MassImport from './MassImport/MassImport.js';
import MassExport from './MassExport/MassExport.js';
import WholesaleComponents from './Wholesale/WholesaleComponents.js';
import WholesaleDiscount from './Wholesale/WholesaleDiscount.js';
import SvgIcon      from 'common/SvgIcon/SvgIcon.js';

import api         from 'services/Api/Api.js';
import { Collapse } from 'react-bootstrap';

import './Components.css';


const permissions = 'storage.items';

const tabIds = {
    0: "list",
    1: "import",
    2: "export",
    3: "wholesale",
    4: "discount",
}

class Components extends React.Component {
    constructor(props)
    {
        super(props);

        const params = this.props.match.params;
        let tab = ( params.tab ) ? this.getTabId( params.tab ) : 0;

        const initialFilters = this.getInitialFilters();
        this.state = {
            selectedTab: tab,
            exportFile: null,
            initialFilters: initialFilters,

            newComponentShow: false,
            
            lvisnet_enabled: false
        }
        autoBind(this);
    }

    onTabChange( tabId )
    {
        this.props.history.replace( `/supplierportal/components/${ tabIds[ tabId ] }` );
    }

    getTabId( param )
    {

        if( param in tabIds )
            return parseInt( param, 10 );
        return parseInt( Object.keys( tabIds ).find( key => tabIds[ key ] === param ), 10 );
    }

    // Get default filter value from redirect data
    getInitialFilters()
    {
        if(this.props.location.state && this.props.location.state.filters)
            return this.props.location.state.filters
        return [];
        //return [{ id: 'status_id', value: getComponentStatusId( 'active' ) }];
    }

    handleAddClick()
    {
        this.setState({
            newComponentShow: true,
        })
    }

    componentsImported()
    {
    	
        this.setState({loading: true})
        if( this.table )
            this.table.refresh();
    }
    
    componentDidMount()
    {
    	this.getSettings();
    }
    
    getSettings()
    {
        
        return api({
            method: 'get',
            url: 'extranet/storage/wholesaleEnabled',
        }).then((response) => {
            this.setState({ lvisnet_enabled: response.lvisnet_enabled });
        }).catch(( error ) => {
            //console.log(error);
            this.setState({ loading: false })        });
            
    }

    newComponentSaved( c )
    {
        this.setState({ newComponentShow: false })
        if( this.table )
            this.table.openComponetEdit( c.id );
    }

    render()
    {
        //if( !hasPermissions( permissions ) ) return <Notfound />

        let lvisnet=null;
        let lvisnet2=null;
        if (this.state.lvisnet_enabled) {
        	lvisnet=<ApTab icon="warehouse" label={ tr('wholesalers_material') } mountOnSelect>
            <WholesaleComponents />
	        </ApTab>
	        lvisnet2=<ApTab icon="handshake" label={ tr('wholesaler_discounts') } mountOnSelect>
	    	   <WholesaleDiscount />
	    	 </ApTab>
        }

        return <div id="pageComponents" className="apBox">
            <div className="apBoxHeader">
                <h1>
                    { tr('storage_components') }
                </h1>
                <p> {tr('storage_components_supplier_info') } </p>
                <div className="apBoxCorner">
                    {/* <ApButton color="green" onClick={ this.handleAddClick } >
                        <span className="hideUnder450">{ tr('new_storage_component') }</span>
                        <span className="showUnder450"><SvgIcon className="icon" icon="plus" type="solid" /></span>
                    </ApButton> */}
                </div>
            </div>
            <ApTabs selected={ this.state.selectedTab } onChange={ this.onTabChange } >
                <ApTab icon="cubes" label={ tr('storage_components') } mountOnSelect>
                    <div className="padding">
                        <CitList
                            ref={ table => this.table = table }
                            rememberId="component-management"
                            onSelect={ this.selectFromTable }
                            instance={ 'management' }
                            initialFilters={ this.state.initialFilters }
                        />
                    </div>
                </ApTab>
                {/*<ApTab icon="file-export" label={ tr('export') } mountOnSelect>
                    <MassExport />
                </ApTab>
                <ApTab icon="file-import" label={ tr('import') } mountOnSelect>
                    <MassImport />
                </ApTab>*/}
                {/*{lvisnet}
                {lvisnet2}*/}
		      </ApTabs>
            {/* <NewComponent
                show={ this.state.newComponentShow }
                onClose={ () => { this.setState({ newComponentShow: false }) } }
                onSave={ this.newComponentSaved }
            /> */}
        </div>
    }
}

export default Components;
