import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from 'routes/public.js';

export default class PublicLayout extends React.Component
{

	UNSAFE_componentWillMount()
	{

		document.title = (this.props.title) ? 'EASY-PRO EXTRANET :: ' + this.props.title : 'EASY-PRO EXTRANET';

	}

	render()
	{

		return (

			<div>
				<Switch>
					{routes.map((route, index) => (
						<Route
							key={index}
							exact={true}
							path={route.path}
							breadcrumbs={route.breadcrumbs}
							title={route.title}
							menu={route.menu}
							component={route.component}
						/>
					))}
				</Switch>
			</div>

		);

	}

}