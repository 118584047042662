import React from 'react';
import autobind from 'react-autobind';
import ApButton from 'common/ApButton/ApButton.js';
import ApFileUpload from 'common/ApFileUpload/ApFileUpload.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApModal from 'common/ApModal/ApModal.js';
import { tr, errorPopper, keyExists, dateInputToSql, sqlToDateInput, capitalize } from 'services/Helpers/Helpers.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import api, {apiurl} from 'services/Api/Api.js';
import File from 'common/File/File.js';
import {Collapse} from 'react-bootstrap';
import styles from './AssignmentPage.module.css';


class NewAssignmentRequest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            files: [],
            newFileIds: [],
            selectedImage: null,
            addresses: [],
            hideAssignmentAddress: localStorage.getItem('extranet-hide-assignment-address') 
                ? localStorage.getItem('extranet-hide-assignment-address') === 'true' ? true : false
                : false,
            hideBillingAddress: localStorage.getItem('extranet-hide-billing-address')
                ? localStorage.getItem('extranet-hide-billing-address') === 'true' ? true : false
                : false,

            billingMethods: [],

            assignment_name: '',
            assignment_description: '',

            assignment_street: '',
            assignment_postal_code: '',
            assignment_city: '',
            assignment_country: '',

            assignment_begin_at: null,
            assignment_end_at: null,

            project: null,
            billing_method: null,

            customer_billing_street: '',
            customer_billing_postal_code: '',
            customer_billing_city: '',
            customer_billing_country: '',

            status: null,
            id: null,
        }

        autobind(this);
    }

    componentDidMount() {
        this.setState({loading:true});
        api({
            method: 'get',
            url: 'extranet/assignments/new/info'
        }).then(response => {
            // console.log('RES', response);
            const billingMethods = Object.keys(response.billingMethods).map(key => {
                return {
                    label: tr(response.billingMethods[key]),
                    value: key
                }
            })
            if (!this.state.id) {
                // valitaan oletuslaskutustapa jos ollaan luomassa uutta tilausta
                const billing_method = billingMethods.find(item => Number(item.value) === Number(response.defaultBillingMethod));
                if (billing_method) {
                    this.setState({billing_method});
                }
                if (response.defaultAddressId) {
                    const address = response.addresses
                        ? response.addresses.find(address => address.id === response.defaultAddressId)
                        : null;
                    if (address) {
                        this.setState({
                            assignment_street: address.street,
                            assignment_postal_code: address.postal_code,
                            assignment_city: address.city,
                            assignment_country: address.country,
                        })
                    }
                }
                if (response.defaultBillingAddress) {
                    this.setState({
                        customer_billing_street: response.defaultBillingAddress.street,
                        customer_billing_postal_code: response.defaultBillingAddress.postal_code,
                        customer_billing_city: response.defaultBillingAddress.city,
                        customer_billing_country: response.defaultBillingAddress.country,
                    })
                }
                if (response.defaultProject) {
                    this.setState({project: response.defaultProject});
                }
            }
            this.setState({billingMethods, addresses: response.addresses , loading: false});
        }).catch(error => {
            console.error(error);
            errorPopper(error, tr('get_error'));
            this.setState({loading: false});
        })

        if (keyExists(this.props, 'match.params.id')) {
            this.setState({loading:true});
            api({
                method: 'get',
                url: `extranet/extassignments/${this.props.match.params.id}/get`
            }).then(response => {
                // console.log('RES', response)
                let billing_method = null;
                if (response.customer_billing_method_id) {
                    billing_method = {
                        value: response.customer_billing_method_id,
                        label: tr(response.billingMethodName),
                    }
                }
                this.setState({
                    loading: false,
                    id: response.id,

                    assignment_name: response.assignment_name,
                    assignment_description: response.assignment_description,

                    assignment_street: response.assignment_street,
                    assignment_postal_code: response.assignment_postal_code,
                    assignment_city: response.assignment_city,
                    assignment_country: response.assignment_country,

                    assignment_begin_at: sqlToDateInput(response.assignment_begin_at, true),
                    assignment_end_at: sqlToDateInput(response.assignment_end_at, true),

                    project: response.project,
                    billing_method,

                    customer_billing_street: response.customer_billing_street,
                    customer_billing_postal_code: response.customer_billing_postal_code,
                    customer_billing_city: response.customer_billing_city,
                    customer_billing_country: response.customer_billing_country,

                    status: response.status,
                    files: response.files,
                });
            }).catch(error => {
                console.error(error);
                errorPopper(error, tr('get_error'));
                this.setState({loading: false});
            })
        }
    }

    sendRequest() {
        if (!this.state.assignment_name) {
            errorPopper(null, tr('name_required'));
            return;
        }

        const fileIds = this.state.files.map(file => file.id);
        fileIds.push(...this.state.newFileIds);

        const data = {
            id: this.state.id,
            assignment_name: this.state.assignment_name,
            assignment_description: this.state.assignment_description,
            assignment_begin_at: dateInputToSql(this.state.assignment_begin_at, true),
            assignment_end_at: dateInputToSql(this.state.assignment_end_at, true),
            assignment_street: this.state.assignment_street,
            assignment_postal_code: this.state.assignment_postal_code,
            assignment_city: this.state.assignment_city,
            assignment_country: this.state.assignment_country,
            project_id: this.state.project ? this.state.project.id : null,
            billing_method: this.state.billing_method ? this.state.billing_method.value : null,
            customer_billing_street: this.state.customer_billing_street,
            customer_billing_postal_code: this.state.customer_billing_postal_code,
            customer_billing_city: this.state.customer_billing_city,
            customer_billing_country: this.state.customer_billing_country,
            fileIds,
        };

        this.setState({loading:true});
        api({
            method: 'post',
            url: 'extranet/assignments/save',
            data: data,
        }).then(response => {
            let billing_method = null;
            if (response.customer_billing_method_id) {
                billing_method = this.state.billingMethods.find(method => Number(method.value) === Number(response.customer_billing_method_id));
            }
            this.setState({
                loading: false,
                files: response.files,
                newFileIds: [],
                selectedImage: null,

                assignment_name: response.assignment_name,
                assignment_description: response.assignment_description,

                assignment_street: response.assignment_street,
                assignment_postal_code: response.assignment_postal_code,
                assignment_city: response.assignment_city,
                assignment_country: response.assignment_country,

                assignment_begin_at: response.assignment_begin_at ? sqlToDateInput(response.assignment_begin_at, true) : null,
                assignment_end_at: response.assignment_end_at ? sqlToDateInput(response.assignment_end_at, true) : null,

                project: response.project,
                billing_method,

                customer_billing_street: response.customer_billing_street,
                customer_billing_postal_code: response.customer_billing_postal_code,
                customer_billing_city: response.customer_billing_city,
                customer_billing_country: response.customer_billing_country,

                status: response.status,
                id: response.id,
            });
            this.props.history.replace(`/assignment-requests/${response.id}`)
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('saved') }</strong>,
            });
        }).catch(error => {
            console.error(error);
            errorPopper(error, tr('get_error'));
            this.setState({loading: false});
        })
    }

    downloadFile(id, name)
	{
		return api({
			method: 'get',
			url: `${ apiurl }file/id/${ id }`,
			responseType: 'blob',
		}).then(( response ) => {
			const url = window.URL.createObjectURL( new Blob([ response ]) );
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', name );
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			this.setState({ loading: false, showImageModal: false });
		}).catch( ( error ) => {
			errorPopper(error, tr('file_download_error'));
			this.setState({ loading: false, showImageModal: false });
		});
	}

    removeFile(item) {
        const files = this.state.files.filter(file => file.id !== item.id);
        this.setState({files})
    }

    renderFilesList() {
        const filesList = this.state.files.map( ( i, index ) => {
            return <div key={ i.id } className="files listStack">
                <ApInputStack gap="0">
                    <ApAddon>
                        <div
                            onClick={ () => {this.setState({
                                selectedImage: i,
                            })}}
                            className="pointer"
                        >
                            <SvgIcon 
                                className="showImageBtn pointer"
                                icon="file" 
                                type="solid"
                            />
                            { i.original_name }
                        </div>
                    </ApAddon>

                    {this.state.status !=='approved' &&
                    <ApAddon noLeftBorder width="50px">
                        <ApTooltip block text={ tr('delete') }>
                                <SvgIcon 
                                    className="removeFileBtn pointer"
                                    icon="trash" 
                                    type="solid" 
                                    onClick={ () => { this.removeFile( i ) }}
                                />
                        </ApTooltip>
                    </ApAddon>}
                </ApInputStack>
            </div>
        });

        return filesList;
    }

    renderImageModal() {
        return (
            <ApModal 
                show={!!this.state.selectedImage}
                onClose={() => this.setState({selectedImage: null})}
                handleClose={() => this.setState({selectedImage: null})}
                closeFromBg
                className="narrow"
                header={ 
                    <div className="padding">
                        <h3>{ tr('file') }</h3>
                    </div>
                }
                body={
                    <div className={`padding ${styles['pdf-canvas']}`}>
                        {this.state.selectedImage && <File
                            fileId={this.state.selectedImage.id} 
                            name={this.state.selectedImage.original_name} 
                            type={this.state.selectedImage.extension.toLowerCase() === 'pdf' ? 'pdf' : 'image'}
                            style={{ width: "100%", height: "500px", objectFit: "contain" }}
                        />}
                    </div>
                }
                footer={
                    <div className="padding">
                        <ApButton 
                            onClick={() => {this.setState({selectedImage: null})}} 
                            className="cancel"
                        >
                            { tr('cancel') }
                        </ApButton>
                        {/* <ApButton 
                            onClick={() => this.downloadFile(this.state.selectedImage.id, this.state.selectedImageName)}
                            style={{float: "right"}} 
                            className="applyButton" 
                            color="green"
                        >
                            <SvgIcon icon="file" type="solid" />
                            { tr('download') }
                        </ApButton> */}
                    </div>
                }
            />
        )
    }

    renderStatus() {
        let el = null;
        switch (this.state.status) {
            case 'approved':
                el = <p style={{color: '#388e3c'}}>{tr(this.state.status)}</p>
                break;
            case 'rejected':
                el = <p style={{color: '#ff4444'}}>{tr(this.state.status)}</p>
                break;
            case 'pending':
                el = <p style={{color: '#555'}}>{tr('waiting_for_approval1')}</p>
                break;
        
            default:
                break;
        }
        return el;
    }

    renderSelectMenu( type )
    {
        let addresses = this.state.addresses;
        if( !addresses ) return null;
        if( !addresses.length ) return null;

        let actions  = [
            { "divider": `${tr('set_address')} ` },
        ]

        let icons = {
            'general': "globe",
            'mailing': "envelope", 
            'billing': "file-invoice", 
            'shipping': "truck", 
        };

        let addressFound = false;

        addresses.forEach( a => {
            let isSelectedAddress = false;
            if (
                a.street === this.state[`${ type }_street`]
                && a.postal_code === String(this.state[`${ type }_postal_code`])
                && a.city === this.state[`${ type }_city`]
            ) {
                addressFound = true;
                isSelectedAddress = true;
            }
            let label = <div className={`addressRow ${isSelectedAddress ? 'bg-light-green' : ''}`}>
                { a.name }<br />
                { a.street }<br />
                { a.postal_code } { a.city }<br />
                { a.country }
            </div>
            actions.push({
                label: label,
                icon: icons[ a.type ],
                className: isSelectedAddress ? 'bg-light-green' : '',
                action: ( item, closeFunc ) => {
                    this.setState({
                        // [`${ type }_name`]: a.name,
                        [`${ type }_street`]: a.street,
                        [`${ type }_postal_code`]: a.postal_code,
                        [`${ type }_city`]: a.city,
                        [`${ type }_country`]: a.country,
                    });
                    closeFunc();
                },
            });
        })
        return <ApDropdown position="right"
            actions={ actions }
            button={ <div className={`defaultButton ${addressFound ? 'bg-light-green' : ''}`}>
                <SvgIcon icon="book" type="solid" /> 
            </div>}
        />
    }

    handleNewFiles(files) {
        const newFileIds = files.map(file => file.id);
        this.setState({newFileIds});
    }

    handleHideAddress(type) {
        this.setState({[`hide${capitalize(type)}Address`]: !this.state[`hide${capitalize(type)}Address`]}, () => {
            localStorage.setItem(`extranet-hide-${type}-address`, this.state[`hide${capitalize(type)}Address`]);
        })
    }

    render() {
        let isDisabled = false;
        if (this.state.status === 'rejected') isDisabled = true;
        if (this.state.status === 'approved') isDisabled = true;
        return (
            <div id='newAssignmentRequest' className='apBox'>
                <div className="pageBackButton" onClick={ this.props.history.goBack }>
                    <SvgIcon icon="arrow-left" type="solid" size="small" />
                    { tr('back') }
                </div>
                <div className='apBoxHeader'>
                    {this.state.id && 
                    <>
                        <h1>{tr('assignment_request')}</h1>
                        {this.renderStatus()}
                    </>}
                    {!this.state.id &&
                    <>
                        <h1>{tr('new_ext_assignment_request')}</h1>
                        <p>{tr('assignment_request_page_info')}</p>
                    </>}
                </div>
                <div className='padding'>
                    <ApInput
                        loading={this.state.loading}
                        type='text'
                        id='assignment_name'
                        name='assignment_name'
                        label={tr('header')}
                        value={this.state.assignment_name}
                        onChange={(e) => this.setState({assignment_name: e.target.value})}
                        disabled={isDisabled}
                    />
                    <ApInput
                        loading={this.state.loading}
                        type='textarea'
                        id='assignment_description'
                        name='assignment_description'
                        label={tr('description')}
                        value={this.state.assignment_description}
                        onChange={(e) => this.setState({assignment_description: e.target.value})}
                        rows='4'
                        disabled={isDisabled}
                    />

                    <ApInputStack>
                        <ApInput
                            loading={this.state.loading}
                            type='datetime'
                            id='assignment_begin_at'
                            name='assignment_begin_at'
                            label={tr('start_date')}
                            value={this.state.assignment_begin_at}
                            onChange={(e) => this.setState({assignment_begin_at: e.target.value})}
                            rows='4'
                            clearable
                            disabled={isDisabled}
                        />
                        <ApInput
                            loading={this.state.loading}
                            type='datetime'
                            id='assignment_end_at'
                            name='assignment_end_at'
                            label={tr('completion_date')}
                            value={this.state.assignment_end_at}
                            onChange={(e) => this.setState({assignment_end_at: e.target.value})}
                            rows='4'
                            clearable
                            disabled={isDisabled}
                        />
                    </ApInputStack>

                    <div className='clear'>
                        <div className='apColumn w50'>
                            <ApInputStack>
                                <ApSelect
                                    loading={this.state.loading}
                                    label={tr('project')}
                                    value={this.state.project && this.state.project.name}
                                    onChange={project => this.setState({project})}
                                    optionRenderer="project"
                                    valueRenderer="project"
                                    objKeyId="id"
                                    apiUrl='extranet/projects/get/all'
                                    apiData={{limit_to_customer: true}}
                                    clearable
                                    disabled={isDisabled}
                                />
                            </ApInputStack>
                        </div>
                    </div>

                    <div className='justify-space-between' style={{alignItems: 'baseline'}}>
                        <div style={{display: 'flex'}}>
                            {this.renderSelectMenu('assignment')} 
                            <h3 onClick={() => {this.handleHideAddress('assignment')}} style={{cursor: 'pointer'}}> {tr('workplace_address')}</h3>
                        </div>
                        <SvgIcon onClick={() => {this.handleHideAddress('assignment')}} type='solid' icon='chevron-left' className={`${!this.state.hideAssignmentAddress ? 'rotate-anti-clockwise-90' : null}`} style={{transition: '0.3s'}} />
                    </div>
                    <Collapse in={!this.state.hideAssignmentAddress}>
                        <div>
                            <ApInputStack>
                                <ApInput
                                    loading={this.state.loading}
                                    type='text'
                                    id='assignment_street'
                                    name='assignment_street'
                                    label={tr('street_address')}
                                    value={this.state.assignment_street}
                                    onChange={(e) => this.setState({assignment_street: e.target.value})}
                                    disabled={isDisabled}
                                />
                                <ApInput
                                    loading={this.state.loading}
                                    type='text'
                                    id='assignment_postal_code'
                                    name='assignment_postal_code'
                                    label={tr('postal_code')}
                                    value={this.state.assignment_postal_code}
                                    onChange={(e) => this.setState({assignment_postal_code: e.target.value})}
                                    disabled={isDisabled}
                                />
                            </ApInputStack>
                            <div className='clear'>
                                <div className='apColumn w50'>
                                    <ApInputStack>
                                        <ApInput
                                            loading={this.state.loading}
                                            type='text'
                                            id='assignment_city'
                                            name='assignment_city'
                                            label={tr('locality')}
                                            value={this.state.assignment_city}
                                            onChange={(e) => this.setState({assignment_city: e.target.value})}
                                            disabled={isDisabled}
                                        />
                                    </ApInputStack>
                                </div>
                            </div>
                        </div>
                    </Collapse>

                    <div className='justify-space-between' style={{alignItems: 'baseline'}}>
                        <div style={{display: 'flex'}}>
                            {this.renderSelectMenu('customer_billing')} 
                            <h3 onClick={() => {this.handleHideAddress('billing')}} style={{cursor: 'pointer'}}> {tr('billing_address')}</h3>
                        </div>
                        <SvgIcon onClick={() => {this.handleHideAddress('billing')}} type='solid' icon='chevron-left' className={`${!this.state.hideBillingAddress ? 'rotate-anti-clockwise-90' : null}`} style={{transition: '0.3s'}} />
                    </div>
                    <Collapse in={!this.state.hideBillingAddress}>
                        <div>
                            <ApInputStack>
                                <ApInput
                                    loading={this.state.loading}
                                    type='text'
                                    id='customer_billing_street'
                                    name='customer_billing_street'
                                    label={tr('street_address')}
                                    value={this.state.customer_billing_street}
                                    onChange={(e) => this.setState({customer_billing_street: e.target.value})}
                                    disabled={isDisabled}
                                />
                                <ApInput
                                    loading={this.state.loading}
                                    type='text'
                                    id='customer_billing_postal_code'
                                    name='customer_billing_postal_code'
                                    label={tr('postal_code')}
                                    value={this.state.customer_billing_postal_code}
                                    onChange={(e) => this.setState({customer_billing_postal_code: e.target.value})}
                                    disabled={isDisabled}
                                />
                            </ApInputStack>
                            <ApInputStack>
                                <ApInput
                                    loading={this.state.loading}
                                    type='text'
                                    id='customer_billing_city'
                                    name='customer_billing_city'
                                    label={tr('locality')}
                                    value={this.state.customer_billing_city}
                                    onChange={(e) => this.setState({customer_billing_city: e.target.value})}
                                    disabled={isDisabled}
                                />
                                <ApSelect
                                    loading={this.state.loading}
                                    label={tr('billing_method')}
                                    value={this.state.billing_method ? this.state.billing_method.label : null}
                                    onChange={item => this.setState({billing_method: item})}
                                    options={ this.state.billingMethods }
                                    optionRenderer="label"
                                    objKeyId="value"
                                    objKeySearchable="label"
                                    valueRenderer="label"
                                    clearable
                                    disabled={isDisabled}
                                />
                            </ApInputStack>
                        </div>
                    </Collapse>

                    <div className='clear'>
                        <div className='apColumn w50'>
                            <h3>{tr('files')}</h3>
                            {this.renderFilesList()}
                            <ApFileUpload
                                ref={ ref => this.fileUploadRef = ref }
                                files={ this.state.newFiles }
                                onChange={ (files) => this.handleNewFiles(files) }
                                description={ "File for assignment request " + (this.state.id ? this.state.id : 'new') }
                                maxSize={ 1024 * 1024 * 20 } // 20 Mt
                                disabled={ this.state.loading }
                                noFilesLabel={ tr('add_files') }
                                noFilesSubLabel={ tr('add_assignment_files') }
                                addButtonLabel={ `${tr('add_file')}...` }
                            />
                        </div>
                    </div>

                    <div className='justify-end'>
                        {!isDisabled &&
                        <ApButton color='green' onClick={this.sendRequest}>
                            {this.state.id ? tr('save') : tr('send')}
                        </ApButton>
                        }
                    </div>
                </div>
                {this.renderImageModal()}
            </div>
        )
    }
}

export default NewAssignmentRequest;