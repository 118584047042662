import Login from 'modules/Login/Login.js';

const routes = [
	{
		path: '/login',
		title: 'Kirjautuminen',
		component: Login
	}
];

export default routes;
