import React from 'react';
import autoBind from 'react-autobind';

import './Login.css';
import config from 'env.js';
import auth from   'services/Authed/Authed.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';

import { keyExists } from 'services/Helpers/Helpers.js';

import {
    FormGroup,
    FormControl,
} from 'react-bootstrap';

export default class Login extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			username: '',
			password: '',
			loading: false,
			errorMsg: false,
			lang: null,
			resetMode: false,
			resetted: false
		};

		autoBind(this);

	}

	componentDidMount()
	{

		let userId = window.sessionStorage.getItem('tempUserId', true);
		let token  = window.sessionStorage.getItem('temptoken', true);

		console.log(userId);
		console.log(token);

		if (userId !== null && userId !== undefined && token !== null && token !== undefined)
			this.handleLoginFromIntranet(userId, token);

	}

	handleLogin()
	{
		
		if (this.state.username.length > 0 && this.state.password.length > 0)
		{

			this.setState({
				errorMsg: false,
				loading: true,
				resetted: false,
				resetMode: false
			});

			auth.login(
				this.state.username,
				this.state.password
			).then((response) => {

				console.log(response);

				auth.reloadSettings( () => {
					window.location.replace('/');
				});

			}).catch((error) => {
				console.log(error);
				this.setState({
					errorMsg: "Väärä käyttäjätunnus tai salasana!",
					loading: false
				});
			});

		}
		
	}

	handleLoginFromIntranet(userId, token)
	{

		this.setState({
			errorMsg: false,
			loading: true,
			resetted: false,
			resetMode: false
		});

		window.sessionStorage.removeItem('temptoken');
		window.sessionStorage.removeItem('tempUserId');

		auth.loginFromIntranet(
			userId, 
			token
		).then((response) => {

			console.log(response);

			auth.reloadSettings( () => {
				window.location.replace('/');
			});

		}).catch((error) => {
			console.log(error);
			this.setState({
				errorMsg: "Väärä käyttäjätunnus tai salasana!",
				loading: false
			});
		});

	}

	handleChange(e)
	{
		this.setState({ [e.target.name]: e.target.value, errorMsg: false });
	}

	handleKeyPress(e)
	{

		if (e.key === 'Enter')
		{
			if (!this.state.resetMode)
			{
				this.handleLogin();
			}
			else
			{
				this.resetPassword();
			}
		}

	}

	changeLanguage(newLang)
	{
		window.sessionStorage.setItem("langugage", newLang);
		this.getLangugage();
	}

	getLangugage()
	{
		let lang = window.sessionStorage.getItem("langugage");
		if (!lang) lang = "fi";
		this.setState({ lang });
	}

	toggleResetMode()
	{
		this.setState({ resetMode: !this.state.resetMode });
	}

	resetPassword()
	{
		console.log("reset password");
	}

	render()
	{

		const options = [
			{ id: "fi", label: "Suomi",		flag: "/img/flag-fi.jpg" },
			{ id: "en", label: "English", 	flag: "/img/flag-en.jpg" }
		];

		let buttonDisabled = false;
		if (this.state.loading) buttonDisabled = true;
		if (this.state.username.length === 0) buttonDisabled = true;
		if (!this.state.resetMode && this.state.password.length === 0) buttonDisabled = true;

		return(
			
			<div id="login">

				<div className="login-box">
					<div className="padding">
						
						<h3 className="header">
							<SvgIcon icon="lock" type="solid" /> Kirjautuminen
						</h3>

						<ApInputStack gap="0">
							<ApAddon
								noRightBorder
								width="48px"
								labelFor="userName">
								<SvgIcon icon="user" type="solid" />
							</ApAddon>
							<ApInput
								type="text"
								id="username"
								name="username"
								label="Käyttäjätunnus"
								onChange={this.handleChange}
								onKeyPress={this.handleKeyPress}
								value={this.state.username}
								error={this.state.errorMsg ? true : false}
							/>
						</ApInputStack>

						{!this.state.resetMode &&
							<div>

							<ApInputStack gap="0">
								<ApAddon
									noRightBorder
									width="48px"
									labelFor="password">
									<SvgIcon icon="key" type="solid" />
								</ApAddon>
								<ApInput
									type="password"
									id="password"
									name="password"
									label="Salasana"
									onChange={this.handleChange}
									onKeyPress={this.handleKeyPress}
									value={this.state.password}
									error={this.state.errorMsg ? true : false}
								/>
							</ApInputStack>

							<p className="apInfo small">
								<SvgIcon icon="info-circle" type="solid" />
								Kirjaudu sisään palveluun syöttämällä käyttäjätunnuksesi sekä salasanasi yllä oleviin kenttiin.
							</p>

							{this.state.resetted &&
								<div className="apSuccessMsg"> Ohjeet salasanan uusimiseen lähetetty sähköpostiisi! </div>
							}

							{this.state.errorMsg &&
								<div className="apErrorMsg"> {this.state.errorMsg} </div>
							}

							<ApButton
								fullWidth
								color="blue"
								disabled={buttonDisabled}
								loading={this.state.loading}
								onClick={this.handleLogin}
							>
								<SvgIcon icon="key" type="solid" />
								<span> Kirjaudu sisään </span>
							</ApButton>

							<ApButton
								ghost
								size="small"
								fullWidth
								onClick={this.toggleResetMode}>
								Salasana unohtunut?
							</ApButton>

							</div>
						}

						{this.state.resetMode &&
							<div>
								<p className="apInfo small">
									<SvgIcon icon="info-circle" type="solid" />
									Syötä käyttäjätunnuksesi, niin lähetämme sähköpostilla sinulle ohjeet salasanan uusimiseen.
								</p>
								<ApButton
									fullWidth
									color="green"
									disabled={buttonDisabled}
									loading={this.state.loading}
									onClick={this.resetPassword}
								>
									<SvgIcon icon="paper-plane" type="solid" />
									<span> Lähetä salasana </span>
								</ApButton>

								<ApButton
									ghost 
									size="small" 
									fullWidth
									onClick={this.toggleResetMode}
								>
									Peruuta
								</ApButton>
							</div>
						}

					</div>
				</div>

                <div className="footer">
                    Easy-Pro - Copyright © { new Date().getFullYear() } <a href="https://www.amiproservice.fi/" target="_blank" rel="noopener noreferrer">AmiPro Service Oy</a>
                </div>

			</div>

		);

	}

}