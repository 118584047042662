
import React 			from 'react';
import autoBind 		from 'react-autobind';
import api 				from 'services/Api/Api.js';

import ApFormPage 		from 'common/ApFormPage/ApFormPage.js';
import { ApInput,
		 ApInputStack,
		 ApAddon } 		from 'common/ApInput/ApInput.js';

export default class UserPage extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			loading: true,
			valid: true,
			errorMsg: null,
			unsavedChanges: false,
			data: []
		}

		autoBind(this);

	}

	componentDidMount()
	{

		this.getUserInfo();

	}

	getUserInfo()
	{

		api({
			method: 'get',
			url: 'extranet/user/userInfo'
		}).then((response) => {
			let data = response;
			data.newpassword1 = null;
			data.newpassword2 = null;
			this.setState({ data, loading: false });
		}).catch((error) => {
			console.log(error);
		});

	}

	saveUserInfo()
	{

		this.setState({ loading: true });
		console.log(this.state.data.password1);

		api({
			method: 'post',
			url: 'extranet/user/saveInfo',
			data: {
				phone1: this.state.data.phone1,
				phone2: this.state.data.phone2,
				email:  this.state.data.email,
				title:  this.state.data.title,
				password: this.state.data.newpassword1
			}
		}).then((response) => {
			this.setState({ loading: false, unsavedChanges: false });
		}).catch((error) => {
			console.log(error);
		});

	}

	checkValidationStatus()
	{

		if (this.state.data.newpassword1 === null)
		{
			this.setState({ valid: true, errorMsg: null });
		}
		else if (this.state.data.newpassword1 !== null && this.state.data.newpassword1.length >= 8 && this.state.data.newpassword1 === this.state.data.newpassword2)
		{
			this.setState({ valid: true, errorMsg: null });
		}
		else if (this.state.data.newpassword1.length < 8)
		{
			this.setState({ valid: false, errorMsg: "Syötä salasanaksi vähintään 8 merkkiä" });
		}
		else if (this.state.data.newpassword1 !== this.state.data.newpassword2)
		{
			this.setState({ valid: false, errorMsg: "Salasanat eivät täsmää" });
		}
			
	}

	handleChange(e)
	{

		let data = this.state.data;
		data[e.target.name] = e.target.value;

		this.setState({ data, unsavedChanges: true }, () => this.checkValidationStatus());

	}

	render()
	{

		return(

			<ApFormPage
				onSave={ this.saveUserInfo }
				loading={ this.state.loading }
				unsaved={ this.state.unsavedChanges }
				error={ this.state.errorMsg }
				valid={ this.state.valid }
			>

				<div className="apBoxHeader">
					<h1> Omat sivut </h1>
					<p> Tarkastele ja muokkaa omia tietojasi </p>
				</div>

				<div className="clear">

					<div className="apColumn w50 padding">

						<ApInputStack gap="0">
							<ApAddon width="150px" labelFor="phone1" noRightBorder >
								<span> Puhelin #1 </span>
							</ApAddon>
							<ApInput
								id="phone1"
								name="phone1"
								value={this.state.data.phone1}
								loading={this.state.loading}
								disabled={this.state.loading}
								onChange={this.handleChange}
							/>
						</ApInputStack>

						<ApInputStack gap="0">
							<ApAddon width="150px" labelFor="email" noRightBorder >
								<span> Sähköposti </span>
							</ApAddon>
							<ApInput
								id="email"
								name="email"
								value={this.state.data.email}
								loading={this.state.loading}
								disabled={this.state.loading}
								onChange={this.handleChange}
							/>
						</ApInputStack>

					</div>

					<div className="apColumn w50 padding">

						<ApInputStack gap="0">
							<ApAddon width="150px" labelFor="phone2" noRightBorder >
								<span> Puhelin #2 </span>
							</ApAddon>
							<ApInput
								id="phone2"
								name="phone2"
								value={this.state.data.phone2}
								loading={this.state.loading}
								disabled={this.state.loading}
								onChange={this.handleChange}
							/>
						</ApInputStack>

						<ApInputStack gap="0">
							<ApAddon width="150px" labelFor="title" noRightBorder >
								<span> Rooli </span>
							</ApAddon>
							<ApInput
								id="title"
								name="title"
								value={this.state.data.title}
								loading={this.state.loading}
								disabled={this.state.loading}
								onChange={this.handleChange}
							/>
						</ApInputStack>

					</div>

					<div className="apColumn w50 padding">

						<ApInputStack gap="0">
							<ApAddon width="180px" labelFor="newpassword1" noRightBorder >
								<span> Uusi salasana </span>
							</ApAddon>							
							<ApInput
								id="newpassword1"
								name="newpassword1"
								value={this.state.data.newpassword1}
								loading={this.state.loading}
								disabled={this.state.loading}
								onChange={this.handleChange}
							/>
						</ApInputStack>

						<ApInputStack gap="0">
							<ApAddon width="180px" labelFor="newpassword2" noRightBorder >
								<span> Salasana uudelleen </span>
							</ApAddon>							
							<ApInput
								id="newpassword2"
								name="newpassword2"
								value={this.state.data.newpassword2}
								loading={this.state.loading}
								disabled={this.state.loading}
								onChange={this.handleChange}
							/>
						</ApInputStack>						

					</div>

				</div>

			</ApFormPage>

		);

	}

}