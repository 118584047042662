import React from 'react';
import PropTypes from 'prop-types';

import './ApTooltip.css';

class ApTooltip extends React.Component {
    render()
    {
        // No tooltip text provided so we just skip the hole tooltip component
        if( !this.props.text || this.props.text.length === 0 ) 
            return this.props.children;


        const triggerMode = ( this.props.atInputHover || this.props.atInputFocus || this.props.atInputActive ? true : false );
    	let classNames = [];
    	let styles = {};

        if( triggerMode ) {
            if( this.props.atInputHover )        classNames.push( "apTooltipHover" );
            else if (this.props.atInputFocus )   classNames.push( "apTooltipFocus" );
            else if (this.props.atInputActive )  classNames.push( "apTooltipActive" );
        }
        else {
            classNames.push( "apTooltip" );
        }

    	if( this.props.color )     classNames.push( "color-" + this.props.color );
        if( this.props.position )  classNames.push( "position-" + this.props.position );
        if( this.props.multiline ) classNames.push( "multiline");
        if( this.props.delayed )   classNames.push( "delayed");

        if( this.props.width ) styles.width = this.props.width + "px";

        return (
        	<div className={ ( !triggerMode ? "apTooltipper" : "apInputTooltipper" ) + ( this.props.block ? " block" : "" ) }>
        		{ this.props.children }
                <div className={ classNames.join(" ") } style={styles}>{ this.props.text }</div>
        	</div>
        );
    }
};


ApTooltip.propTypes = {
    text: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool, PropTypes.element, PropTypes.node ]),
	color: PropTypes.string,
	position: PropTypes.string, // "top" (default), "left", "right", "bottom", "topright", "topleft"
    block: PropTypes.bool,
	multiline: PropTypes.bool,
    width: PropTypes.number,
    delayed: PropTypes.bool,
    atInputHover: PropTypes.bool,
	atInputFocus: PropTypes.bool,
	atInputActive: PropTypes.bool
};

export default ApTooltip;

