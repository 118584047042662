import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import File from 'common/File/File.js';

import ApButton from 'common/ApButton/ApButton.js';
import { ApAddon, ApInputStack } from 'common/ApInput/ApInput.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';



import moment from 'moment';

import { Grid, Row, Col } from 'react-bootstrap';
import ApSelect from 'common/ApSelect/ApSelect';
import { keyExists, tr, roundToDecimals, errorPopper } from 'services/Helpers/Helpers.js';

import UserPermitModal from './UserPermitModal.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js'
//import matchSorter from 'match-sorter';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';

import './Permits.css';

export default class Permits extends React.Component {

    constructor(props) {

        super(props);

        this.state = {

            loading: false,
            showEmptyPermits: false,
            companyHasPermits: false,
            permits: [],
            people: [],
            groups: [],
            selectedGroups: [],
            personFilter: null,
            selectedPermitId: null,
            selectedProject:[],
            selectedGroupName: null,
            arrayData: {},
            projectPeople: [],
            projects:[],
        };

        autoBind(this);

        this.statusColors = {
            notRequired: "rgba(0,0,0,0.1)",
            required: "#6699FF",
            valid: "#65CE50",
            expiring: "#D7EF4F",
            expired: "#F96D6D",
            notAccepted: 'orange'
        };

    }

    UNSAFE_componentWillMount() {

        this.getUserGroups();
        this.getUserWorkPermits();

    }

    getUserGroups() {
       /*
        this.setState({ loading: true })
        api({
            method: 'get',
            url: 'usermanagement/groups/getGroups'
        }).then((response) => {
            this.setState({ groups: response, loading: false });
        }).catch((error) => {
            console.log(error);
            this.setState({ loading: false })
        });*/

    }
    getUserWorkPermits() {

        this.setState({ loading: true })
        api({
            method: 'get',
            url: 'extranet/permits/getPeopleAndPermitsAndTaxNumberAndimageId',
            params: { selectedGroups: this.state.selectedGroups }
        }).then((response) => {

            //console.log(response);
            for (let project of response.projectPeople) {
                //console.log(project);
                
                project.user.fullName = project.user.person_detail.first_name + ' ' + project.user.person_detail.last_name;
                
                
            }
            let people = [];
            for (let project of response.projectPeople) {
                let user = project.user;
                let UserIsNotList = true;
                for (let permit of user.work_permits) {
                    for (let permitName of response.permits) {
                        if (permit.permit_id === permitName.id) {
                            permit.name = permitName.name;
                        }
                    }
                }
                
                for (let man of people) {
                    if (man.id === user.id) {
                        UserIsNotList = false;
                        break;
                    }
                }
                if (UserIsNotList) {
                    people.push(user);
                }


            }
            
            
            
            this.setState({
                projectPeople: response.projectPeople,
                people: people,
                permits: response.permits,
                projects:response.projects,
				//companyHasPermits: companyHasPermits,
				
			});
			//tarvitaan, että saadaan päivitettyä taulukon järjestys datan lataamisen jälkeen!
			this.setState({ loading: false});
            

        }).catch((error) => {
            console.log(error);
            this.setState({ loading: false })
        });

    }

    getPDFPermits(item) {
        this.setState({ loading: true });
        let url = `extranet/permits/getPDFPermits`;
        
        api({
            method: 'post',
            url: url,
            responseType: 'blob',
            data: { rows: item }
        }).then((response) => {

            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;

            
            
            link.setAttribute('download', 'Henkilöiden työluvat ' + moment().format("DD.MM.YYYY") + '.pdf');
            
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            this.setState({ loading: false });

        }).catch((error) => {
            errorPopper(error, 'Tiedoston lataamisessa tapahtui virhe');
            this.setState({ loading: false });
        });

    }

    



    getPermitStatus(permit) {
        if (permit == null) {
            return "notgranted";
        }
        const now = moment();
        const accepted = permit.is_accepted;
        const required = permit.is_required;
        const granted = permit.date_granted === null ? false : true;
        const expires = moment(permit.date_expires);
        const tmp = moment(permit.date_expires);
        const noteBefore = permit.note_before_expiry_dates === null ? 0 : permit.note_before_expiry_dates;
        const warning = now >= tmp.add(-noteBefore, 'days');
        const expired = permit.date_expires ? now >= expires : true;

        //if (required) return "required";
        if (granted && !warning && !expired && accepted) return "valid";
        //if (granted && warning && !expired && accepted) return "expiring";
        if (granted && warning && !expired) return "expiring";
        if (granted && !accepted) return "notaccepted";
        if (granted && expired) return "expired";
        if (granted && expired && required) return "expired";
        if (!granted && expired && required) return "expired";
        if (!granted) return "notgranted";

        return "not available";

    }



    isPermitRequired(permit) {
        if (permit.is_required)
            return true;
        else
            return false;

    }

    getPermitStatusIcon(permit) {

        let element = null;

        switch (this.getPermitStatus(permit)) {

            case "notgranted":
                element = <div className="guide selection">
                    <SvgIcon fill={this.statusColors.notRequired} icon="circle" />
                </div>;
                break;

            case "valid":
                element = <div className="guide">
                    <SvgIcon fill={this.statusColors.valid} icon="check-circle" type="solid" />
                </div>;
                break;

            case "required":
                element = <div className="guide">
                    <SvgIcon fill={this.statusColors.required} icon="exclamation-circle" type="solid" />
                </div>;
                break;

            case "expiring":
                element = <div className="guide">
                    <SvgIcon fill={this.statusColors.expiring} icon="exclamation-circle" type="solid" />
                </div>;
                break;

            case "notaccepted":
                element = <div className="guide">
                    <SvgIcon fill={this.statusColors.notAccepted} icon="question-circle" type="solid" />
                </div>;
                break;

            case "expired":
                element = <div className="guide">
                    <SvgIcon fill={this.statusColors.expired} icon="times-circle" type="solid" />
                </div>;
                break;

            default:
                console.error("Unknown permit status");


        }

        return element;

    }

    clickPerson(event, person, permit) {

        const personId = person !== null ? person.id : null;
        const permitId = permit !== null ? permit.id : null;
        event.stopPropagation();

        if (permitId !== null)
            this.setState({ showPermitEditorModal: true, selectedPersonId: personId, selectedPermitId: permitId });

    }

    closePermitEditor() {

        this.setState({
            showPermitEditorModal: false, selectedPersonId: null, selectedPermitId: null, showUserInfo:null,
        }, () => {
            this.getUserWorkPermits();
        });

    }

    getRequiredStatusColor(permit) {

        let color = "#fafafa";
        if (permit.is_required) color = "#6699ff";

        return color;

    }

    changeFilter(params) {
        this.setState({ selectedGroups: params.id, selectedGroupName: params.name }, () => { this.getUserWorkPermits() });
    }

    changeNameFilter(params) {
        this.setState({
            personFilter: params.id,
            personFilterName: params.full_name
        });
    }
    

    openPermitEditor(personId, permitId) {


        //const permitId = permit ? permit.id : null;
        if (permitId !== null) this.setState({ showPermitEditorModal: true, selectedPersonId: personId, selectedPermitId: permitId, showUserInfo: null });
    }
    openUserInfo(personId,data) {


        //const permitId = permit ? permit.id : null;
        if (personId !== null) this.setState({ showPermitEditorModal: true, showUserInfo: data.user});
    }

    getDaysToExpiry(permit) {
        if (permit == null) return null;
        let now = moment();
        let expires = moment(permit.date_expires);
        let daysToExpiry = 0;

        let diff = roundToDecimals(moment.duration(expires.diff(now)).asDays(), 0);
        //if (diff >= 0) daysToExpiry = diff;
        daysToExpiry = diff;
        return daysToExpiry;
    }


    
    

    
    getColums() {
        //luodaan lupataulukon nimi sarake
        let listHeader = [{
            id: 'userName',
            Header: tr("name"),
            accessor: "user.fullName",
            align: "center",
            width: 180,
            className: "stickyColumn",
            headerClassName: "stickyColumn",
            Cell: (props) => {
                //console.log(props);
                let person = props.original.user.person_detail;
                let tooltip = (
                    <>
                        
                        <div className="tooltip-row">
                            {(person.first_name ? person.first_name : "") + (person.midle_names ? (" " + person.midle_names) : "") + (person.last_name ? (" " + person.last_name) : "")}
                        </div>



                    </>
                );
                return <div>
                    <ApTooltip text={tooltip} style={{ overflow:"visible"}}>
                        {props.original.user.fullName}
                    </ApTooltip>
                    
                    
                </div >
            },
            onClick: (row) => {
                //console.log(row);
                this.openUserInfo(row.id,row);
            },

            filterMethod: (filter, row) => {

                return row.userName.toLowerCase().includes(filter.value.toLowerCase());
            },
            
        },
        {
            id: 'taxNumber',
            Header: tr("tax_number"),
            accessor: "user.contract_detail.taxnumber",
            align: "center",
            width: 150,
            Cell: (props) => {
                
                return<div>
                    <ApTooltip text={<><div>{props.original.user.contract_detail.taxnumber}</div></>} >
                        {props.original.user.contract_detail.taxnumber ? props.original.user.contract_detail.taxnumber:""}
					</ApTooltip>
				</div >
                
            },

            filterMethod: (filter, row) => {
                //console.log(row);
                let number = row.taxNumber ? row.taxNumber : "";
                return number.toLowerCase().includes(filter.value.toLowerCase());
            },
            
        }
        ];
        // luodaan lupataulukon muut sarakkeet
        for (let id in this.state.permits) {
            //haetaan kaikki ei tyhjät rivit
            let peopleNotHavePermits = false;
            if (!this.state.showEmptyPermits) {
                for (let man of this.state.projectPeople) {
                    if (man.user.work_permits) {
                        
                        for (let permit of man.user.work_permits) {
                            if (permit.permit_id === this.state.permits[id].id) {
                                if (permit.date_expires != null || permit.is_required) {
                                    peopleNotHavePermits = true;
                                    break;
                                }
                            }
                        }
                        
                        
                    }

                }
                if (!peopleNotHavePermits) {
                    continue;
                }
            }
            listHeader.push({
                id: this.state.permits[id].id,
                Header: <div title={this.state.permits[id].name} >{this.state.permits[id].name}</div>,
                accessor: (row) => {
                    let permit = row.user.work_permits ? row.user.work_permits.filter(item => item.permit_id === this.state.permits[id].id)[0] : null;
                    return permit ? permit.negativeDaysToExpiry : 9999;
                },
                align: "center",
                width: 150,
                Cell: (props) => {
                    let tooltipText;
                    let tooltipText2;
                    let tooltipText3;
                    let daysToExpiry;
                    let permit = props.original.user.work_permits ? props.original.user.work_permits.filter(item => item.permit_id === this.state.permits[id].id)[0] : null;

                    if (permit) {

                        daysToExpiry = this.getDaysToExpiry(permit);
                        //kerrotaan -1 että saadaan ensin vanhentuva aakkosissa ensin
                        permit.negativeDaysToExpiry = daysToExpiry * -1;
                        if (permit.date_expires != null) {
                            tooltipText = <span>{tr("expiring:") + " " + moment(permit.date_expires).format("DD.MM.YYYY")}</span>
                            if (daysToExpiry >= 0) {
                                tooltipText2 = <span>{tr("permit_remaining:") + " " + daysToExpiry + " " + tr("days")}</span>;
                                if (permit.is_required) {
                                    tooltipText3 = <span>{tr("permit_required")}</span>
                                }
                            }
                            else {
                                tooltipText2 = <span style={{ color: "orange" }}>{tr("permit_is_expired")}</span>;
                                if (permit.is_required) {
                                    tooltipText3 = <span style={{ color: "orange" }}>{tr("permit_required")}</span>
                                }
                            }

                        }
                        else {
                            tooltipText = <span> {tr("permit_is_not_valid")}</span>;
                            if (permit.is_required) {
                                tooltipText3 = <span style={{ color: "orange" }}>{tr("permit_required")}</span>;
                            }
                        }

                    }
                    else {
                        tooltipText = tr("permit_is_not_valid")
                    }

                    let guideTooltip = (
                        <>
                            <div className="tooltip-row">
                                {tooltipText}
                            </div>
                            <div className="tooltip-row" >
                                {tooltipText2}

                            </div>

                            {tooltipText3 &&
                                < div className="tooltip-row" >
                                    {tooltipText3}
                                </div>}


                        </>
                    );
                    //console.log(guideTooltip);
                    let required = false;
                    if (permit != null && permit.is_required) {
                        required = permit.is_required
                    }
                    return <div>
                        <ApTooltip text={guideTooltip} >
                            <div>
                                {this.getPermitStatusIcon(permit)}
                                {required ? <SvgIcon fill={this.statusColors.required} icon="exclamation-circle" type="solid" style={{ height: 13 }} /> : ""}
                            </div>


                        </ApTooltip>


                    </div >
                    //return props.original.permits[id].name;

                },
                onClick: (row) => {
                    //console.log(row);
                    this.openPermitEditor(row.user.id, this.state.permits[id].id);
                },

                filterMethod: (filter, row) => { },
                Filter: ({ filter, onChange }) => { },
            });

        }


        let columns = [
            {
                Header: tr("permits"),
                columns: listHeader,
            }
        ];
        //console.log(this.state);
        return columns;
    }

    handleShowEmptyPermits() {
    this.setState({ showEmptyPermits: !this.state.showEmptyPermits});
    }

    changeProject(project) {
        //console.log(project);
        this.setState({ selectedProject: project})
    }

    getReactableData() {
        let reactTableData = [];

        if (this.state.selectedProject.length > 0) {
            for (let project of this.state.selectedProject) {
                for (let projectUser of this.state.projectPeople) {
                    if (project.id === projectUser.project_id) {
                        reactTableData[projectUser.user_id] = projectUser;
                    }
                }
            }
        }
        else {
            for (let projectUser of this.state.projectPeople) {

                reactTableData[projectUser.user_id] = projectUser;

            }
        }
        //muutetaan array muotoon jolloin ei tule ylimääräisiä sivuja taulukkoon
        reactTableData = Object.values(reactTableData);
        return reactTableData;
    }

    render() {
        //console.log(this.state);
        //console.log(this.getReactableData());
        

        return (
            <div id="PageUserWorkPermits" className="apBox">

                <div className="apBoxHeader">
                    <h1>{tr('personnel_work_permits')}</h1>
                    {/*<p>{tr('personnel_work_permits_info')}</p>*/}
                </div>

                <div className="padding">

                    {this.state.loading &&
                        <div className="apLoader"></div>
                    }
                    <div className="guides">

                        <div className="guide">
                            <SvgIcon fill={this.statusColors.valid} icon="check-circle" type="solid" /> {tr('valid')}
                        </div>

                        <div className="guide">
                            <SvgIcon fill={this.statusColors.expiring} icon="exclamation-circle" type="solid" /> {tr('expiring')}
                        </div>
                        <div className="guide">
                            <SvgIcon fill={this.statusColors.expired} icon="times-circle" type="solid" /> {tr('invalid')}
                        </div>
                        <div className="guide">
                            <SvgIcon fill={this.statusColors.required} icon="exclamation-circle" type="solid" /> {tr('required')}
                        </div>
                        <div className="guide">
                            <SvgIcon fill={this.statusColors.notAccepted} icon="question-circle" type="solid" /> {tr('waiting_for_approval1')}
                        </div>
                    </div>

                    <ApInputStack>
                    

                        <ApAddon width="150px" labelFor="showEmptyPermits" sm={3}>
                            <ApSwitch
                                small
                                inline
                                id="showEmptyPermits"
                                on={this.state.showEmptyPermits}
                                onChange={(e) => this.handleShowEmptyPermits()}
                                disabled={this.state.loading}
                            />
                            <span>{tr("show_empty_columns")}</span>
                        </ApAddon>
                        
                        <ApAddon width="150px" labelFor="selectProject">
                            <ApSelect
                                options={this.state.projects}
                                label={tr("projects")}
                                value={keyExists(this.state, "selectedProject", true, [])}
                                valueRenderer="project"
                                optionRenderer="project"
                                onChange={(users) => { this.changeProject(users) }}
                                objKeyId="id"
                                objKeySearchable="name"
                                multiselect
                                clearable
                            />
                        </ApAddon>
                        
                    </ApInputStack>
                    <ApButton
                        color="gray"
                        onClick={() => this.getPDFPermits(this.getReactableData())}
                        
                    >   
                        {tr("print")} <SvgIcon type="solid" fill={"#000000"} icon="print" style={{ width: "15px"}} />
                    </ApButton>
                    <div className="padding">
                    <div className="userInfo"> 
                        <SvgIcon fill={"#666"} icon="exclamation-circle" type="solid" /> {tr("click_the_person's_name_for_more_information")}
                        </div>
                    </div>
                    <ApReactTable

                        //manual
                        //onFetchData={ this.getUsersApt }
                        //pages={ this.state.pages }

                        loading={this.state.loading}
                        data={this.getReactableData()}
                        columns={this.getColums()}
                        filterable
                        showFiltersInitially
                        defaultSorted={[
                            { id: "userName", desc: false }
                        ]}
                        
                    />
                    



                </div>
                <UserPermitModal
                    show={this.state.showPermitEditorModal}
                    permitId={this.state.selectedPermitId}
                    personId={this.state.selectedPersonId}
                    onClose={this.closePermitEditor}
                    showUserInfo={this.state.showUserInfo}
                />


                
            </div>

            

        );

    }

}
