import axios from 'axios';
import api           	from 'services/Api/Api.js';
import { JWTParser } 	from 'services/Helpers/Helpers.js';
import config 			from 'env.js';

const Authed = {

	isLoggedIn()
	{
        if(window.sessionStorage.getItem('token'))
            return true;
        return false;
	},

	login(username, password)
	{

		return api({
			method: 'post',
            url: '/extranet/login',
			data: {
				username: username,
				password: password
			},
			notoken: true
		}).then((response) => {
			this.saveToken(response.token);
		}).catch((error) => {
			console.log("caught an error in loginhandle");
			throw error;
		});

	},

	loginFromIntranet(userId, token)
	{

		return api({
			method: 'post',
			url: '/extranet/loginWithTempToken',
			data: {
				userId: userId,
				token: token
			},
			notoken: true
		}).then((response) => {
			this.saveToken(response.token);
		}).catch((error) => {
			console.log("caught an error in loginhandle");
		});

	},

	logout(callApi = true)
	{
		if (callApi)
		{
			api({
				method: 'post',
				url: '/logout',
				norefreshing: true
			});
		}

		this.removeToken();

		window.emitter.emit('goTo', {
			pathname: '/login'
		});

	},

	saveToken(token)
	{
		let data = JWTParser(token);
		window.sessionStorage.setItem('token', token);
		window.sessionStorage.setItem('token-data', JSON.stringify(data));
	},

	tokenCheckAndRefresh()
	{

		let refreshing = window.sessionStorage.getItem('refreshing', true);
		if (refreshing) return false;
		
		const tokenData = this.getTokenData();
		if (!tokenData) return false;

		const remaining = tokenData.exp*1000 - Date.now();
		if (remaining < this.refreshBefgore*60*1000)
		{
			this.tokenRefresh();
		}

	},

	getToken()
	{
		return window.sessionStorage.getItem('token');
	},

	getTokenData()
	{
		return JSON.parse(window.sessionStorage.getItem('token-data'));
	},

	removeToken()
	{
		window.sessionStorage.removeItem('token');
		window.sessionStorage.removeItem('token-data');
	},

    getSettings()
    {   
        const settings = window.sessionStorage.getItem('settings');

        // console.log('Settings', settings ? JSON.parse( settings ) : false );

        // There is a problem here with file permissions, should we make files available for extranet users
        // the company logo on


        return settings ? JSON.parse( settings ) : false ;
    },

    getUser()
    {
        const settings = this.getSettings();
        if( settings )
            return settings.user;
    },

    getID()
    {
        const user = this.getUser();
        if( user )
            return user.id;
    },

    getName()
    {
        let user = this.getUser()
        if( user )
            return user.name;
    },

    getEmail()
    {
        let user = this.getUser()
        if( user )
            return user.email;
    },

    /*
	getSettings()
	{
		return {
			company: {
				errors: null,
				layout: {
					top_bar_text: 'Yritys Y:n Extranetsivut',
					top_bar_subtext: 'Easy-Pro Extranet'
				}
			}
		}

	},
    */

	reloadSettings(onSuccess = null, onError = null)
	{
        console.log('Reload settings');

		api({
			method: 'get',
            url: '/extranet/settings'
		}).then((response) => {

            console.log('reload settings', response );

			console.log("i'm in reloadsettings");
			window.sessionStorage.setItem('settings', JSON.stringify(response));

			if (window.siderbarRef) window.sidebarRef.updateBadges();
			if (typeof(onSuccess) == "function") onSuccess();

		}).catch((error) => {
			if (typeof(onError) == "function") onError();
		});


	},

	hasModule()
	{

		return true;

	},

	isGlobalAdmin()
	{

		return true;

	},
	getCompany() {
		const settings = this.getSettings();
		if (settings)
			return settings.company;
	},
	getCurrency() {
		let company = this.getCompany()
		if (company)
			return company.currency;
	},
	getCurrencySign() {
		let company = this.getCompany()
		if (company)
			return company.currency_sign;
	}

};

export default Authed;
