import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';

import Range from 'rc-slider/lib/Range';

import 'rc-slider/assets/index.css';
import './ApRangeSlider.css';


class ApRangeSlider extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            open: false,
        }

        autoBind(this); 
    } 

    customHandle( props )
    {
        let classes = props.className.split(" ");

        if( props.dragging )
            classes.push( "dragging" );

        if( props.disabled )
            classes.push( "disabled" );

        if( this.props.tooltipOnHoverOnly )
            classes.push( "onHoverOnly" );

        const tooltip = ( typeof( this.props.tooltipRenderer ) === "function" ? this.props.tooltipRenderer( props.value ) : props.value );

        return <div 
            key={ props.index }
            className={ classes.join(" ") }
            tabIndex={ props.tabindex }
            role="slider"
            aria-valuemin={ props.min } 
            aria-valuemax={ props.max } 
            aria-valuenow={ props.value }
            aria-disabled={ props.disabled ? "true" : "false" }
            style={{ left: props.offset + "%" }}
        >
            { this.props.tooltip && 
                <div className="handleTooltip">
                    { tooltip }
                </div>
            }
        </div>
    }

    renderSection( section, index )
    {
        const totalLength = this.props.max - this.props.min;
        let left = ( ( section.value[0] - this.props.min ) / totalLength * 100 );
        let width = ( ( section.value[1] - section.value[0] ) / totalLength * 100 );

        // Prevent overflow from left
        if( left < 0 )
        {
            width += left;
            left = 0;
        }

        // Prevent overflow from right
        if( left + width > 100 )
            width = 100 - left;

        const style = {
            left: left + "%",
            width: width + "%",
        };

        return (
            <div key={ index } className="section" style={ style } onClick={ () => this.props.onChange( section.value ) }>
                { section.label || (index + 1) }
            </div>
        );
    }
    
    render()
    {
        return (
        	<div className={ "ApRangeSlider" + ( this.props.sections ? " withSections" : "" ) }>
                { this.props.sections && 
                    <div className="sections">
                        { this.props.sections.map( this.renderSection ) }
                    </div>
                }
                <Range 
                    handle={ ( props ) => this.customHandle( props, this.props.tooltipRenderer ) }
                    { ...this.props }
                 />
        	</div>
        );
    }
};


ApRangeSlider.propTypes = {
    tooltip:            PropTypes.bool,
    tooltipRenderer:    PropTypes.func,
    tooltipOnHoverOnly: PropTypes.bool,
    sections:           PropTypes.array,
};

export default ApRangeSlider;

