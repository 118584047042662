/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import { keyExists } from  'services/Helpers/Helpers.js';
import { entryIcon, statusText } from  'modules/Timetracking/TimetrackingHelpers.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApPath from 'common/ApPath/ApPath.js';

export default class Day extends React.Component {

    constructor(props) 
    {
        super(props);
        autoBind(this); 
    }

    renderProjectTooltip( project )
    {
        let mainProject = project;
        let projectPath = [];
        if( project.ancestors.length > 0 )
        {
            mainProject = project.ancestors[0];
            project.ancestors.forEach( a => {
                projectPath.push( a.name );
            })
        }
        projectPath.push( project.name );

        return (
            <div>
                <ApPath items={ projectPath } />
                { keyExists( mainProject, "customer.name" ) && 
                    <div><strong>{ mainProject.customer.name }</strong></div>
                }
            </div>
        );
    }

    renderApprovedTooltipContent( entry )
    {
        if (entry.status === 'rejected')
        {
            return (
                <div>
                    Hylkääjä: <strong>{ entry.approver.first_name + " " + entry.approver.last_name }</strong>
                    <div>{ entry.approver_comment }</div> 
                </div>
            )
        } 
        else if ((entry.status === 'approved' || entry.status === 'billed') && entry.approver)
        {
            return (
                <div>
                    Hyväksyjä: <strong>{ entry.approver.first_name + " " + entry.approver.last_name }</strong>
                </div> 
            )
        } 
        else
        {
            return null;
        }
        
    }

    render() 
    {
        const entry = this.props.data;

        let classes = [ "entry" ];
        let status = entry.status;
        let comment = entry.approver_comment;
        let statusIcon = "question";
        const hasProject = keyExists( entry, "project.name" );

        let description = entry.description;

        if( entry.type === "absence" && keyExists( entry, "detail.absence_id" ) )
        {
            const absence = keyExists( this.props, "settings.absences", true, [] ).find( a => a.id == entry.detail.absence_id );
            if( absence.name )
                description = description ? ( absence.name + ": " + description ) : absence.name;
        }

        if( this.props.changes && !entry.readOnly ) 
        {
            status = this.props.changes.status;
            comment = this.props.changes.approver_comment;

            if( this.props.changes.status == this.props.statuses[0] )
                statusIcon = "question";

            else if( this.props.changes.status == "approved" )
                statusIcon = "check";

            else if( this.props.changes.status == "confirmed" )
                statusIcon = "check-circle";
            
            else if ( this.props.changes.status === "rejected" )
                statusIcon = "times";

            if( !this.props.changes.approver_comment )
                classes.push( "comment-missing" );
        }

        if( entry.is_misentry )
            classes.push( "misentry" );

        if( this.props.selected )
            classes.push( "selected" );

        if( entry.readOnly )
            classes.push( "readOnly" );

        if( hasProject )
            classes.push( "hasProject" );

        classes.push( status );

        return (
            <React.Fragment>
                <div className={ classes.join(" ") } key={ entry.id }>
                    { entry.type!=='project_installation' && 
                    <SvgIcon className="icon" icon="clock" type="solid" />
                    }
                    { entry.type=='project_installation' && 
                    <SvgIcon className="icon" icon="puzzle-piece" type="solid" />
                    }
                    
                    { !entry.readOnly && 
                        <input 
                            type="checkbox" 
                            checked={ this.props.selected }
                            readOnly
                        />
                    }

                    <div className="description" onClick={ () => { if( !entry.readOnly ) this.props.onEntrySelect( entry.id ); } }>
                        { entry.show_description ? 
                            <div>{entry.entry_description}<br/><small>{description}</small></div>
                            : description
                    }
                    </div>

                    { hasProject &&
                        <div className="project">
                            <ApTooltip text={ this.renderProjectTooltip( entry.project ) } block>
                                <SvgIcon icon="folder" type="solid" />
                                <span className="code">{ entry.project.project_code }</span>
                            </ApTooltip>
                        </div>
                    }

                    { this.props.renderOverview( entry ) }
                    
                    <div className={ "status " + status } onClick={ () => !entry.readOnly ? this.props.onToggleStatus( entry.id ) : false }>
                        <SvgIcon icon={ statusIcon } type="solid" />
                        { status == this.props.statuses[0] ? "Odottaa" : statusText( status ) }
                    </div>
                    
                    { !entry.readOnly &&
                        <div className="comment" onClick={ () => this.props.onEditComment( entry.id, comment ) }>
                            <ApTooltip position="topright" text={ status === "rejected" ? comment || "Lisää kommentti" : false }>
                                <SvgIcon icon={ comment ? "comment-dots" : "comment" } type="solid" />
                            </ApTooltip>
                        </div>
                    }

                    { entry.readOnly &&
                        <div className="comment">
                            <ApTooltip 
                                position="topright" 
                                text={ 
                                    this.renderApprovedTooltipContent( entry ) 
                                }
                            >
                                <SvgIcon icon="lock" type="solid" />
                            </ApTooltip>
                        </div>
                    }

                </div> 
                { !this.props.showReadOnly && entry.readOnly && !this.props.noOwnEntries &&
                    <div className="readOnlyPlaceholder" onClick={ () => this.props.onToggleReadOnly( true ) }></div> 
                }
            </React.Fragment>
        );
    }
}
