import React from 'react';
import './VerticalScroller.css';

import PropTypes from 'prop-types';


class VerticalScroller extends React.Component {
    
    constructor( props )
    {
        super( props );
        this.state = {
            scrollable: false,
            atTop: false,
            atBottom: false,
            disabled: false,
        };
        this.handleScrollShadows = this.handleScrollShadows.bind(this);
        this.node = null;
    }

    componentDidMount()
    {
        // Initial scroll position available
        if( this.props.scrollPosition && !this.props.disabled ) {
            let pos = 0;
            if( typeof( this.props.scrollPosition ) === "number" ) {
                pos = this.props.scrollPosition;
            }
            else {
                switch( this.props.scrollPosition ) {
                    case "top":
                        pos = 0;
                        break;

                    case "bottom":
                        pos = this.node.scrollHeight;
                        break;

                    default:
                        break;
                }
            }
            this.node.scrollTop = pos;
        }

        if( this.props.srcollShadows && !this.props.disabled ) {
            this.node.addEventListener('scroll', this.handleScrollShadows);
            window.addEventListener('resize', this.handleScrollShadows);
            this.handleScrollShadows();
        }
    }

    componentWillUnmount()
    {
        if( this.props.srcollShadows && !this.props.disabled ) {
            this.node.removeEventListener('scroll', this.handleScrollShadows);
            window.removeEventListener('resize', this.handleScrollShadows);
        }
    }
    
    
    componentDidUpdate()
    {
        // Reset node scroll listener when disabled prop changes (also node ref changes)
        // Fixes bug where scroll shadows stop working after sidebar have been minified
        if( this.props.disabled !== this.state.disabled && this.node && this.props.srcollShadows )
        {
            this.setState({ disabled: this.props.disabled });      
            this.node.removeEventListener('scroll', this.handleScrollShadows);
            this.node.addEventListener('scroll', this.handleScrollShadows);
        } 
    }
    

    handleScrollShadows( event )
    {   
        if( !this.props.disabled ) {
            const height = this.node.clientHeight;
            const scrollT = this.node.scrollTop;
            const scrollH = this.node.scrollHeight;
            let newState = {
                scrollable: false,
                atTop: false,
                atBottom: false
            }
            if( height < scrollH ) {
                newState.scrollable = true;
                if( scrollT <= 0 ) {
                    newState.atTop = true;
                }
                else if ( scrollT >= ( scrollH - height ) ) {
                    newState.atBottom = true;
                }
            }
            this.setState( newState );
        }
    }

    render() {

        if( this.props.disabled ) {
            return (
                <div ref={node => this.node = node}>{ this.props.children }</div>
            );
        }

        let classes = [];
        let styles = { 
            wrapper: {},
            shadowTop: {},
            shadowBottom: {}
        };
    
        let offset = 0;
        if( this.props.offsetTop ) offset += this.props.offsetTop; 
        if( this.props.offsetBottom ) offset -= this.props.offsetBottom; 

        // Calc height with possible offsets
        styles.wrapper = { height: 'calc( 100% - ' + offset + 'px' };

        // Scroll shadows enabled 
        if( this.props.srcollShadows ) {
            classes.push( "srcollShadows" );

            // Custom height of the shadows
            if( this.props.shadowHeight ) {
                styles.shadowTop.height = this.props.shadowHeight + 'px';
                styles.shadowBottom.height = this.props.shadowHeight + 'px';
            }
        }

        // Hide scrollbar (currently working only in webkit browsers)
        if( this.props.hideScrollbar ) {
            classes.push( "noScrollBar" );
        }

        return (
            <div className={"VerticalScrollerWrapper " + classes.join(" ") } style={ styles.wrapper }>
                { this.state.scrollable && <div className={"shadowTop" + ( this.state.atTop ? " atTop" : "" )} style={ styles.shadowTop } /> }
                <div ref={node => this.node = node} className="VerticalScroller ">
                    { this.props.children }
                </div>
                { this.state.scrollable && <div className={"shadowBottom" + ( this.state.atBottom ? " atBottom" : "" )} style={ styles.shadowBottom } /> }
            </div>
        );
    }
}

VerticalScroller.propTypes = {
    scrollPosition: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    offsetTop: PropTypes.number,
    offsetBottom: PropTypes.number,
    srcollShadows: PropTypes.bool,
    shadowHeight: PropTypes.number,
    hideScrollbar: PropTypes.bool,
    disabled: PropTypes.bool
};

export default VerticalScroller;
