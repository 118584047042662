import React from 'react';
import './SvgIcon.css';

import faRegular from './fontAwesomeRegular.js';
import faSolid from './fontAwesomeSolid.js';

import PropTypes from 'prop-types';

/**
 * Website to check available icons:
 * https://fontawesome.com/icons?d=gallery&m=free
 *
 */
class SvgIcon extends React.Component {

    render() {
        // By default use regular icons
        //var file = "fa-regular.svg";
        //if( this.props.type === "solid" ) file = "fa-solid.svg";

        const icon = this.props.type === "solid" ? faSolid[this.props.icon] : faRegular[this.props.icon];

        if (!icon) {
            console.error(`Icon "${this.props.icon}" not found (type: "${this.props.type}")`);
            return null;
        }

        const style = this.props.style ? this.props.style : {}

        const path = icon[4];
        const viewBox = "0 0 " + icon[0] + " " + icon[1];

        return (
            <svg className={"SvgIcon " + (this.props.className ? this.props.className : "")} onClick={this.props.onClick} viewBox={viewBox} style={style}>
                {/* <use xlinkHref={ "/img/icons/" + file + "#" + this.props.icon } fill={ this.props.fill ? this.props.fill : "" } />*/}
                <path d={path} fill={this.props.fill ? this.props.fill : ""}></path>
            </svg>
        );
    }
}

SvgIcon.propTypes = {
    icon: PropTypes.string.isRequired,
    type: PropTypes.string,
    fill: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func
};

export default SvgIcon;
