const config = {
	version: "0.0.1",
	api: {
		// URL to API
		url: "https://api.easy-pro.fi",

		refreshtime: 15,
	}
};

export default config;
