/* eslint-disable eqeqeq */
import React from 'react';
import { currentLang, availableLanguages } from 'services/Helpers/Helpers.js';
import './LangMenu.css';

class LangMenu extends React.Component {

	constructor( props )
	{
		super( props );
		this.state = {
			lang: null
		}
		this.changeLanguage = this.changeLanguage.bind(this);
		this.getLanguage = this.getLanguage.bind(this);
	}

	componentDidMount() 
	{
		this.getLanguage();
	}

	deleteLangCookies() 
	{
		document.cookie.split(";").forEach(cookie => {
			const eqPos = cookie.indexOf("=");
			const name = (eqPos > -1 ? cookie.substr(0, eqPos) : cookie).replace(/ /g, '');
			if (name === 'language')
			{
				document.cookie = `language=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
				document.cookie = `language=; path=/; expires=Thu 01 Jan 1970 00:00:00 GMT;`;
				document.cookie = `language=; domain=${document.domain.split('.').reverse().splice(0,2).reverse().join('.')}; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
				document.cookie = `language=; domain=${document.domain.split('.').reverse().splice(0,2).reverse().join('.')}; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
			}
		})
	}

	changeLanguage( lang )
	{
		this.props.toggleModal('language');
		if (lang !== this.state.lang)
		{
			this.deleteLangCookies();
			document.documentElement.lang = lang;
			const cookieDate = new Date;
			cookieDate.setFullYear(cookieDate.getFullYear() + 5);
			document.cookie = `language=${lang}; domain=${document.domain.split('.').reverse().splice(0,2).reverse().join('.')}; path=/; expires=${cookieDate.toUTCString()};`;
			window.location.reload();
		}
	}

	getLanguage() 
	{
		const lang = currentLang();
		this.setState({ lang });
	}

    render()
    {

    	const options = [
    		{ id: "fi", label: "Suomi",   flag: "/img/flag-fi.jpg" },
    		{ id: "en", label: "English", flag: "/img/flag-en.jpg" },
    		{ id: "et", label: "Eesti",   flag: "/img/flag-et.jpg" }
    	];

        return (
            <div className="block languages">
                <div className="button" onClick={() => { this.props.toggleModal("language"); } }>
                    <div className="flag" style={{ backgroundImage: 'url("/img/flag-' + this.state.lang + '.jpg")' }}></div>
                </div>
                <div className={"topModal" + (this.props.open ? " open" : "" ) }>
                    <div className="padding-small">
                    	{
                    		options.map((lang, index) => {
                    			return( 
                    				<button key={index} className={( lang.id == this.state.lang ? "selected" : "")} onClick={ () => { this.changeLanguage( lang.id ) }}>
                    					<div className="flag" style={{ backgroundImage: "url('" + lang.flag + "')"}}></div>
                    					<span className="text">{ lang.label }</span>
                    				</button> 
                    			);
                    		})
                    	}
                    </div>
                </div>
            </div>
        );
    }
};


export default LangMenu;

