/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import api from 'services/Api/Api.js';

import nextId           from 'services/NextId/NextId.js';

import { truncate }    from 'services/Helpers/Helpers.js';

import SvgIcon          from 'common/SvgIcon/SvgIcon.js';
import ApTooltip        from 'common/ApTooltip/ApTooltip.js';

import './ApNestedSelect.css';

/*
* This component should be used with nested tree structure.
*
*   value:      Value should be object that includes list of parents as
*               an array. By default parents are looked behind key 'ancestors',
*               but you can change the key with 'parentsKey' prop.
*
*   apiUrl:     Following object is posted to the given URL automatically:
*               {
*                   search: '',
*                   current: { this.props.value }
*               }
*
*               You can assign more post data with 'apiData' prop (as object).
*
*               Make sure this URL returns array of object that are in the
*               same strucure as the value object. At the backend you propably
*               should use Laravel nestedset trait. Basic backend example:
*
*               function backendExample( Request $request ) {
*
*                   // Include parents (order by depth)
*                   $query = YourNestedSetModel::with( [ 'ancestors' => function( $q ) {
*                       $q->defaultOrder();
*                   }]);
*
*                   // Limit by search phrase
*                   if( !empty( $request['search'] ) )
*                       $query->where( 'name' , 'ILIKE', "%" . $request['search'] . "%" );
*
*                   // Limit by parent depth
*                   if( isset( $request['current']['id'] ) )
*                       $query->where( 'parent_id', $request['current']['id'] );
*                   else
*                       $query->whereNull( 'parent_id' );
*
*                   return $query->get();
*               }
*
*
*
* Minimal example:
*

<ApNestedSelect
    label="Example"
    value={ this.state.something }
    onChange={ ( item ) => this.setState({ something }) }
    apiUrl="example/url/to/nestedset/model"
/>

*
* Example with simple custom renderers:
*

<ApNestedSelect
    label="Example"
    value={ this.state.something }
    onChange={ ( item ) => this.setState({ something }) }
    apiUrl="example/url/to/nestedset/model"
    valueRenderer="name"
    parentRenderer="code"
    parentTooltipRenderer="name"
    optionRenderer={ ( item ) => item.code + ": " + item.name }
/>

*
* Working example from CRM company industry selector:
*

<ApNestedSelect
    label="Toimiala"
    valueRenderer={ item => item.name }
    value={ keyExists( this.state.data, "industry", true ) }
    parentRenderer="code"
    parentTooltipRenderer="name"
    optionRenderer={ (item) => {
        return (
            <div className="industryOption">
                <span className="name">{ item.name }</span>
                <span className="code">{ item.code }</span>
            </div>
        );
    }}
    onChange={ ( item ) => { this.handleSelectChange( "industry", item ) } }
    apiUrl="crm/company/industries"
    loading={ this.state.loading }
    disabled={ this.state.loading }
    readOnly={ !auth.hasModule( "clients.edit" ) }
/>

*/

class ApNestedSelect extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            focused: false,

            disableBlur: false,

            results: null,
            resultsShowing: false,
            resultActive: false,

            inputValue: '',
        }
        autoBind(this);

        this.inputId = nextId('ApNestedSelect');

        // Default debounce rate is 200ms
        this.getResultsDebounced = debounce( typeof( this.props.debounce ) == "number" ? this.props.debounce : 200 , this.getResults );
    }

    componentDidMount()
    {
        document.addEventListener('mousedown', this.clickOutsideHandler);
    }

    componentWillUnmount()
    {
        document.removeEventListener('mousedown', this.clickOutsideHandler);
    }

    clickOutsideHandler(event)
    {
        if( this.parent && this.state.resultsShowing )
            if( !this.parent.contains( event.target ) )
                this.closeResults();
    }

    getResults()
    {
        if( this.props.readOnly || this.props.disabled )
            return false;

        if( this.props.apiUrl )
        {
            let apiData = this.props.apiData ? this.props.apiData : {};

            apiData.search = this.state.inputValue;
            apiData.current = this.props.value;

            this.setState({ loading: true });
            api({
                method: 'post',
                url: this.props.apiUrl,
                data: apiData,
            }).then(( response ) => {

                //console.log('nested results', response );

                this.setState({
                    loading: false,
                    results: response,
                    resultActive: response.length > 0 ? 0 : false,
                });

            }).catch((error) => {
                console.error( 'ERROR: ', error );
                this.setState({ loading: false });
            });
        }
        /*
        else if ( this.props.options ) {
            // MAYBE LATER (if needed to use 'static' options)
        }
        */
    }

    onChange( e )
    {
        this.setState({ inputValue: e.target.value }, this.getResultsDebounced );
    }

    onKeyUp( e )
    {
        if( this.state.resultsShowing )
        {
            if( e.key == "Enter" || ( e.key == "ArrowRight" && this.state.inputValue.length == 0 ) )
                this.onSelect();

            else if ( e.key == "ArrowLeft" && this.state.inputValue.length == 0 )
                this.previousLevel();

            else if ( e.key == "Escape" )
                this.closeResults();

            else if ( e.key == "ArrowUp" || e.key == "ArrowDown" )
            {
                const max = this.state.results.length;
                let resultActive = typeof( this.state.resultActive ) == "number" ? this.state.resultActive : -1;

                resultActive += ( e.key == "ArrowUp" ? -1 : 1 );

                if( resultActive < 0 )
                    resultActive = max - 1;

                if( resultActive >= max )
                    resultActive = 0;

                this.setState({ resultActive }, this.checkResultScrollPosition )
            }
        }
        else if ( e.key == "ArrowDown" )
        {
            this.setState({ resultsShowing: true });
        }
    }

    // Scroll list so the selected item is allways visible (list have max-height and overflow scroll in CSS)
    checkResultScrollPosition()
    {
        if( this.activeResultRef && this.resultsRef )
        {
            const viewTop = this.resultsRef.scrollTop;
            const viewBottom = this.resultsRef.scrollTop + this.resultsRef.clientHeight;
            const itemTop = this.activeResultRef.offsetTop;
            const itemBottom = this.activeResultRef.offsetTop + this.activeResultRef.clientHeight;

            // Item is outside view from top
            if( itemTop < viewTop )
                this.resultsRef.scrollTop = itemTop;

            // Item is outside view from bottom
            if( itemBottom > viewBottom )
                this.resultsRef.scrollTop = itemBottom - this.resultsRef.clientHeight;
        }
    }

    onFocus()
    {
        this.setState({
            focused: true,
            resultsShowing: true,
        }, this.getResults );
    }


    onBlur()
    {
        // We need to disable blur from happening when click selected item from results
        if( this.state.disableBlur )
            return false;

        this.setState({
            focused: false,
        });

        this.closeResults();
    }

    closeResults()
    {
        this.setState({ resultsShowing: false });
    }

    onSelect( item = false )
    {
        if( this.props.readOnly || this.props.disabled )
            return false;

        // Use keyboard selected item
        if( !item && this.state.results && typeof( this.state.resultActive ) == "number" )
            item = this.state.results[ this.state.resultActive ];

        if( !item )
            return false;

        if( typeof( this.props.onChange ) == "function" )
            this.props.onChange( item );

        this.nextLevel();
    }

    onClear()
    {
        if( this.props.readOnly || this.props.disabled )
            return false;

        if( typeof( this.props.onChange ) == "function" )
            this.props.onChange( null );

        this.nextLevel();
    }

    nextLevel()
    {
        this.setState({
            inputValue: '',
            focused: true,
            resultsShowing: true,
            disableBlur: true, // Disable onBlur-function (more info below)
        }, this.getResults );

        // When use selects item with mouse click, we lose focus from the
        // input and onBlur-event is triggered. We can ignore that
        // event with this 'disableBlur' variable. Then we must reset
        // this variable after tiny timeout.
        setTimeout( () => {
            this.setState({ disableBlur: false });

            // Also focus the input again (if not yet focused)
            if( this.inputRef && document.activeElement != this.inputRef )
                this.inputRef.focus();

        }, 100 );
    }

    previousLevel()
    {
        if( !this.props.value )
            return false;

        const parentsKey = this.props.parentsKey || "ancestors";
        if( !( parentsKey in this.props.value ) )
            return false;

        if( this.props.value[ parentsKey ].length > 0 )
        {
            // We need to add parent chain also to new item
            let parents = this.props.value[ parentsKey ].slice();
            let item = parents.pop();
            item[ parentsKey ] = parents;
            this.onSelect( item );
        }
        else
            this.onClear();
    }

    jumpToParent( index, parents )
    {
        // We need to add parent chain also to new item
        const parentsKey = this.props.parentsKey || "ancestors";
        let item = parents[ index ];
        item[ parentsKey ] = [];
        for( let i = 0; i < index; i++ )
            item[ parentsKey ].push( parents[i] );

        this.onSelect( item );
    }

    accessorOrFunc( propName, item )
    {
        if( !item )
            return "";

        const prop = this.props[ propName ];

        // Function given => return function result
        if( typeof( prop ) == "function" )
            return prop( item, this.state.inputValue );

        // String given => use as accessor
        if( typeof( prop ) == "string" && prop in item )
            return item[ prop ];

        // Array given => use as list of accessors
        if( Array.isArray( prop ) )
        {
            let values = [];
            prop.map( key => {
                if( key in item )
                    values.push( item[ key ] );
                return key;
            })
            return values.join(" ");
        }

        if( "code" in item )
            return item['code'];

        console.log( 'WARNING: You should provide valid "' + propName + '" prop for ApNestedSelect. ', prop );

        return "";
    }

    render()
    {
        let classes = ['ApNestedSelect'];
        let parents = [];
        let placeholder = this.props.placeholder || "Valitse...";
        const readOnly = this.props.readOnly || this.props.disabled;
        const endReached = ( this.state.results && this.state.results.length == 0 && this.state.inputValue.length == 0  && !this.state.loading );

        if( this.props.value )
        {
            const parentsKey = this.props.parentsKey || "ancestors";
            if( parentsKey in this.props.value )
                parents = this.props.value[ parentsKey ].slice();

            parents.push( this.props.value );
        }

        if( this.state.loading || this.props.loading )
            classes.push( "loading" );

        if( this.state.disabled )
            classes.push( "disabled" );

        if( this.state.focused )
            classes.push( "focused" );

        if( this.state.resultsShowing )
            classes.push( "resultShowing" );

        if( readOnly )
        {
            classes.push( "readOnly" );
            placeholder = "";
        }
        else
            classes.push( "enabled" );

        if( endReached )
        {
            classes.push( "endReached" );
            placeholder = this.props.placeholderEnd || "-";
        }

        if( ["error", "warning", "loading", "success"].indexOf( this.props.validationState ) != -1 )
            classes.push( "validator-" + this.props.validationState );

        let input = <div>
            <input
                ref={ node => this.inputRef = node }
                type="text"
                value={ this.state.inputValue }
                placeholder={ placeholder }
                readOnly={ readOnly || endReached }
                disabled={ this.props.disabled }
                onChange={ this.onChange }
                onKeyUp={ this.onKeyUp }
                onFocus={ this.onFocus }
                onBlur={ this.onBlur }
                autoComplete="off"
            />
            { ( this.state.loading || this.props.loading ) && <div className="apLoader"></div> }
            <SvgIcon className="arrow" icon="angle-down" type="solid" />
        </div>

        // Special case on component code management, where last part of code can be typed in
        if( this.props.tailInput )
        {
            const tailInput = this.props.tailInput;
            const onTailFocus = () => {
                const func = ( typeof tailInput.onFocus === 'function' ) ? tailInput.onFocus : null;
                this.setState({ focused: true }, func );
            }
            input = <div className="tailInput">
                    <input
                        ref={ node => this.inputRef = node }
                        type="text"
                        value={ tailInput.value ? tailInput.value : '' }
                        placeholder={ tailInput.placeholder }
                        disabled={ this.props.disabled }
                        onChange={ tailInput.onChange }
                        onFocus={ onTailFocus }
                        onBlur={ this.onBlur }
                        autoComplete="off"
                    />
                { ( this.state.loading || this.props.loading ) && <div className="apLoader"></div> }
            </div>
        }

        const label = ( typeof this.props.label === 'function' ) ? this.props.label() : this.props.label;

        return (
            <div className={ classes.join(" ") } ref={ node => this.parent = node }>
                { label &&
                    <label htmlFor={ this.inputId }>
                        { label }
                        { this.props.valueRenderer && this.props.value &&
                            <span>: <span className="value">{ this.accessorOrFunc( "valueRenderer", this.props.value ) }</span></span>
                        }
                    </label>
                }

                <table className="breadcrump">
                    <tbody>
                        <tr>

                            <td className="root" onClick={ this.onClear }>
                                <ApTooltip text={ !readOnly ? "Tyhjennä" : false } block>
                                    <SvgIcon icon="times-circle" type="solid" />
                                </ApTooltip>
                            </td>
                            { parents.map( ( parent, index ) => {
                                let current = ( index == parents.length - 1 );
                                if( this.props.tailInput ) current = false;
                                return (
                                    <td
                                        key={ index }
                                        className={ "level depth-" + ( index + 1) + ( current ? " current" : "" ) }
                                        onClick={ () => { if( !current ) this.jumpToParent( index, parents ) } }
                                    >
                                        <div className="inner">
                                            <ApTooltip text={ this.accessorOrFunc( "parentTooltipRenderer", parent ) } block delayed>
                                                { this.accessorOrFunc( "parentRenderer", parent ) }
                                            </ApTooltip>
                                        </div>
                                    </td>
                                );
                            })}
                            <td className="input">
                                { input }
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className={ "results" + ( this.state.resultsShowing ? " show" : "" ) } ref={ node => this.resultsRef = node }>

                    { this.state.results && this.state.results.map( ( item, index ) => {

                        let itemClasses = ['item'];
                        let ref = undefined;

                        if( this.state.resultActive === index )
                        {
                            itemClasses.push( 'active' );
                            ref = ( node ) => this.activeResultRef = node;
                        }

                        // We can NOT use onClick event here because input blur event will fire before click event
                        // and it will hide results (and disable mouse events from items). Therefore we must
                        // use onMouseDown event (triggers before blur) instead of onClick event here.
                        return (
                            <div key={ "resultItem" + index } ref={ ref } className={ itemClasses.join(" ") } onMouseDown={ () => this.onSelect( item ) }>
                                { this.accessorOrFunc( "optionRenderer", item ) }
                            </div>
                        );
                    })}

                    { this.state.results && this.state.results.length == 0 && !this.state.loading && this.state.inputValue.length > 0 &&
                        <div className="noResults">
                            <SvgIcon className="small-inline" icon="exclamation-triangle" type="solid" />
                            Ei hakutuloksia termille '{ truncate( this.state.inputValue, 30 ) }'
                        </div>
                    }

                </div>

            </div>
        );
    }
}

ApNestedSelect.propTypes = {
    label:              PropTypes.oneOfType([ PropTypes.string, PropTypes.func ]),
    value:              PropTypes.object,
    onChange:           PropTypes.func.isRequired,
    placeholder:        PropTypes.string, // by default "Valitse..."
    placeholderEnd:     PropTypes.string, // Placeholder when end of path reached. by default "-"
    loading:            PropTypes.bool,
    disabled:           PropTypes.bool,
    readOnly:           PropTypes.bool,
    validationState:    PropTypes.string,

    apiUrl:             PropTypes.string.isRequired, // Required for now, until 'static' options are implemented
    apiData:            PropTypes.object,
    //options:          PropTypes.array,

    parentsKey:         PropTypes.string, // by default uses "ancestors" (check Laravels nestedset)
    debounce:           PropTypes.number, // by default 200ms

    // For customizations
    valueRenderer:          PropTypes.oneOfType([ PropTypes.string, PropTypes.array, PropTypes.func ]),
    parentRenderer:         PropTypes.oneOfType([ PropTypes.string, PropTypes.array, PropTypes.func ]),
    parentTooltipRenderer:  PropTypes.oneOfType([ PropTypes.string, PropTypes.array, PropTypes.func ]),
    optionRenderer:         PropTypes.oneOfType([ PropTypes.string, PropTypes.array, PropTypes.func ]),

    // Select dropdown can be replaced with regular input, this is a hacky implementation for editing component code
    // Tail object can contain following fields ( value, placeholder, onChange )
    tailInput:  PropTypes.object,
};

export default ApNestedSelect;
