import React from 'react';
import autoBind from 'react-autobind';

export default class Projects extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {

		};

		autoBind(this);

	}

	UNSAFE_componentWillMount()
	{

	}

	render()
	{

		return(

			<p> Projects </p>

		);

	}

}
