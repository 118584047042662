import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import File from 'common/File/File.js';

import './Promos.css';

export default class Promos extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			promos: []
		};

		autoBind(this);

	}

	UNSAFE_componentWillMount()
	{

		//this.getSampleContent();
		this.getBrochures();

	}

	getBrochures()
	{

		api({
			method: 'get',
			url: 'extranet/client/brochures'
		}).then((response) => {
			this.constructFileList(response);
		}).catch((error) => {
			console.log(error);
		});

	}

	constructFileList(files)
	{

		const promos = [];

		files.map((file, index) => {
			promos.push({
				id: file.id,
				fileName: file.original_name,
				description: 'Sample content',
				extension: file.extension
			});
		});

		this.setState({ promos }, () => { console.log(this.state.promos) });

	}

	getSampleContent()
	{

		const promos = [
			{
				id: 1,
				fileName: "about_us.pdf",
				extension: "PDF",
				description: "Yritysesittely"
			},	
			{
				id: 2,
				fileName: "services.pdf",
				extension: "PDF",
				description: "Palvelukuvaus"
			}
		];

		this.setState({ promos });

	}

	render()
	{

		return(
		
			<div className="apBox" id ="extranetPromos">

				<div className="apBoxHeader">
					<h1> Esitteet </h1>
				</div>

				{this.state.promos.map((doc, index) => {
					return (

						<div className="files documentRow" key={index}>
							<File fileId={doc.id} name={doc.fileName}>
								<ApButton className="fileButton" size="small">
									<SvgIcon icon="download" type="solid" />
								</ApButton>
								<span style={{paddingLeft: '8px'}}>
									Lataa {doc.description} ({doc.fileName})
								</span>
							</File>
						</div>

					)
				})}

			</div>

		);

	}

}
