/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';

import './ApModal.css';

import SvgIcon from '../SvgIcon/SvgIcon.js';

class ApModal extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {

        }

        autoBind(this); 
    } 

    componentDidMount() {
        document.addEventListener('mousedown', this.clickOutsideHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.clickOutsideHandler);
    }

    componentDidUpdate( prevProps ) 
    {
        // When popup opens
        if( this.props.show && !prevProps.show )
        {
            if( typeof( this.props.onOpen ) == "function" )
                this.props.onOpen();
        }
        // When popup closes
        else if ( !this.props.show && prevProps.show )
        {
            if( typeof( this.props.onClose ) == "function" )
                this.props.onClose();
        }
    }

    clickOutsideHandler(event) {

        if( this.props.closeFromBg && this.props.show )
        {
            if( !this.modal.contains( event.target ) )
                this.close();
        }
    }

    close()
    {   
        if ( typeof( this.props.handleClose ) == "function" )
        {
            this.props.handleClose();
        }
    }

    render()
    {
        let classes = ["apModalWrapper"];

        if( this.props.show ) 
            classes.push("show");

        // Close cross available
        const closeCross = this.props.handleClose && !this.props.hideCross ? true : false;

        return (
            <div className={ classes.join(" ") }>
                <div className={ "apModal" + ( this.props.className ? " " + this.props.className : "" ) } ref={ node => this.modal = node }>
                    
                    { closeCross && 
                        <div className="closeCross" onClick={ this.close }>
                            <div className="apSimpleButton">
                                <SvgIcon icon="times" type="solid" />
                            </div>
                        </div>
                    }

                    { this.props.header && 
                        <div className="apModalHeader">{ this.props.header }</div>
                    }

                    <div className="apModalBody">
                        { this.props.body }
                    </div>
                    
                    { this.props.footer && 
                        <div className="apModalFooter">{ this.props.footer }</div>
                    }
                </div>
            </div>
        );
    }
};


ApModal.propTypes = {
    show:           PropTypes.bool.isRequired,
    body:           PropTypes.node.isRequired,
    header:         PropTypes.node,
    footer:         PropTypes.node,
    handleClose:    PropTypes.func,
    hideCross:      PropTypes.bool,
    closeFromBg:    PropTypes.bool,
    className:      PropTypes.string,
    onOpen:         PropTypes.func,
    onClose:        PropTypes.func,
};

export default ApModal;

