/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';

import './ApSteps.css';

import SvgIcon from 'common/SvgIcon/SvgIcon.js'

/*
 *  Basic example:
 * 
 *  <ApSteps steps={[
 *      { icon: "folder", name: "Root", description: "/" },
 *      { name: "Child", description: "/child" },
 *      { name: "Grandchild", description: "/child/grandchild", selected: true },
 *  ]} />
 * 
 *  
 *  Full width example:
 * 
 *  <ApSteps fullWidth flatEnd steps={[
 *      { icon: "star", name: "Start", description: "First phace", onClick: () => this.changePhase( 1 ) },
 *      { icon: "marker", name: "Edit", description: "Second phace", onClick: () => this.changePhase( 2 ) },
 *      { icon: "paper-plane", name: "Send", description: "Final phace", onClick: () => this.changePhase( 3 ) },
 *  ]} />
 * 
 *
 *  Options for each step (in steps prop):
 *      - icon              string, name of the icon 
 *      - name              string, first line (strong)
 *      - description       string, second line (small)
 *      - selected          bool, step marked as selected
 *      - success           bool, step marked as success
 *      - ghost             bool, step marked as ghost (faded)
 *      - onClick           function if step is clickable
 *      - customRenderer    function if you want to customize individual item
 * 
 */

class ApSteps extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {

        }

        autoBind(this);
    }

    defaultStepRenderer( step )
    {
        let classes = ["inner"];
        let icon = false;

        if( step.icon )
        {
            icon = <SvgIcon className="stepIcon" icon={ step.icon } type="solid" />;

            if( !step.name )
                classes.push("onlyIcon");
            else 
                classes.push("withIcon");
        }

        return (
            <div className={ classes.join(" ") }>
                { icon && icon }
                <strong>{ step.name }</strong>
                <small>{ step.description }</small>
            </div>
        );
    }

    render()
    {
        let stepRenderer = ( typeof( this.props.stepRenderer ) == "function" ? this.props.stepRenderer : this.defaultStepRenderer );
        let classes = ["apSteps"];
        let count = this.props.steps.length;

        if( this.props.fullWidth )
            classes.push('fullWidth');

        if( this.props.flatEnd )
            classes.push('flatEnd');

        if( this.props.className )
            classes.push( this.props.className );
        
        if( this.props.customStart )
            count++;

        if( this.props.customEnd )
        {
            count++;

            // Custom end is always flat so let's add flatEnd class if missing
            if( classes.indexOf('flatEnd') == -1 ) classes.push('flatEnd');
        }

        classes.push( 'count-' + count );

        return (
            <ul className={ classes.join(" ") }>

                { this.props.customStart && this.props.customStart }

                { this.props.steps.map( ( step, index ) => {

                    let className = ["step"];
                    let onClick = () => { return false };

                    if( step.selected )
                        className.push("selected");

                    if( step.success )
                        className.push("success");

                    if( step.ghost )
                        className.push("ghost");

                    if( typeof( step.onClick ) == "function" )
                    {
                        className.push("clickable");
                        onClick = (e) => { step.onClick( step, e ) };
                    }

                    if( typeof( step.customRenderer ) == "function" )
                        stepRenderer = step.customRenderer;

                    return (
                        <li key={ index } className={ className.join(" ") } onClick={ onClick }>
                            { stepRenderer( step ) }
                        </li>
                    );
                })}

                { this.props.customEnd && this.props.customEnd }
            </ul>
        );
    }
};


ApSteps.propTypes = {
    steps:              PropTypes.array.isRequired,
    stepRenderer:       PropTypes.func,
    fullWidth:          PropTypes.bool,
    flatEnd:            PropTypes.bool,
    customStart:        PropTypes.node,
    customEnd:          PropTypes.node,
    className:          PropTypes.string,
};

export default ApSteps;

