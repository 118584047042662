import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import moment from 'moment';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
// import File from 'common/File/File.js';
// import FileUpload from 'common/FileUpload/FileUpload.js';
import { roundToDecimals } from 'services/Helpers/Helpers.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import {
	ApInput,
	ApInputStack,
	ApAddon
} from 'common/ApInput/ApInput.js';
import { Grid, Row, Col } from 'react-bootstrap';
import { keyExists, tr } from 'services/Helpers/Helpers.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
//import Files from './Files.js';
import File from 'common/File/File.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import ApModal from 'common/ApModal/ApModal.js';
import './UserWorkPermits.css';

export default class UserPermitModal extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			userPermitId: null,
			permitCode: null,
			dateGranted: null,
			dateExpires: null,
			noteBefore: null,
			daysToExpiry: null,
			hasFile: false,
			permitTypeString: "",
			formValid: false,
			permitIsRequired: false,
			permitIsAccepted: false,

			filesModalShow: false,
			file: null,

			showRemoveConfirmDialog: false,
			showViewerModal: false,
			loading: false,
			modalTitle: null,
			edited: false,
			workPermitDefaultLength:0,

			prevPermitCode: null,
			prevDateGranted: null,
			prevDateExpires: null,
			prevFile: null,
			prevPermitIsRequired: false,
			prevPermitIsAccepted: false,
			showUserInfo:this.props.showUserInfo
		};

		autoBind(this);
		//this.getPermit();

	}

	componentDidUpdate(prevProps) {
		if (this.props.show && !prevProps.show) {
			this.setState({ showUserInfo: this.props.showUserInfo })
			if (this.props.showUserInfo != null) {
				this.setState({
					modalTitle: this.props.showUserInfo.fullName
				});
				
			}else {
					this.getPermit();
			}
			
			
			
		}
	}

	getPermit() {
		const url = this.props.personId ? 'extranet/permits/getPermit' : 'currentUser/getpermitdata';
		const params = this.props.personId ?
			{
				userId: this.props.personId,
				permitId: this.props.permitId
			}
			:
			{
				permitId: this.props.permitId
			}
		this.setState({ loading: true })
		api({
			method: 'get',
			url: url,
			params: params
		}).then((response) => {
			this.setState({
				modalTitle: response.user_name + ": " + response.name,
				userPermitId: response.user_permit_id,
				permitCode: response.permit_code,
				dateGranted: response.date_granted,
				dateExpires: response.date_expires,
				noteBefore: response.note_before_expiry_dates,
				permitIsRequired: response.is_required ? true : false,
				permitIsAccepted: response.is_accepted ? true : false,
				workPermitDefaultLength: response.work_permission_default_length ? response.work_permission_default_length:0,
				file: response.file_id ? {
					id: response.file_id,
					original_name: response.file_name
				} : null,
				loading: false
			}, () => {
				this.initializeData();
			});
		}).catch((error) => {
			console.log(error);
			this.setState({ loading: false })
		});
		
	}

	initializeData() {

		let permitTypeString = "";
		//console.log("state",this.state);
		if (this.state.userPermitId === null) {
			//permitTypeString = tr('new');
			//console.log(this.state);
			this.setState({ dateGranted: null, dateExpires: null });
			//console.log(this.state);
		}
		else {
			//permitTypeString = " - " +tr('edit');
		}

		const now = moment();
		const expires = moment(this.state.dateExpires);

		let daysToExpiry = null;

		let diff = roundToDecimals(moment.duration(expires.diff(now)).asDays(), 0);
		if (diff >= 0) daysToExpiry = diff;

		this.setValidationState();

		this.setState({
			modalTitle: this.state.modalTitle + permitTypeString,
			daysToExpiry: daysToExpiry,
			edited: false,
			prevPermitCode: this.state.permitCode,
			prevDateGranted: this.state.dateGranted,
			prevDateExpires: this.state.dateExpires,
			prevFile: this.state.file,
			prevPermitIsRequired: this.state.permitIsRequired,
			prevPermitIsAccepted: this.state.permitIsAccepted,
		});
		
	}
	isEdited() {
		if (this.state.prevPermitCode === this.state.permitCode && this.state.prevDateGranted === this.state.dateGranted &&
			this.state.prevDateExpires === this.state.dateExpires && this.state.prevFile === this.state.file &&
			this.state.prevPermitIsRequired === this.state.permitIsRequired && this.state.prevPermitIsAccepted === this.state.permitIsAccepted) {
			return false;
		}
		else {
			return true;
		}
		
	}

	savePermit() {
		if (this.isEdited()) {
			const url = this.props.personId ? 'usermanagement/permits/savePermit' : 'currentUser/savepermit';
			const params = this.props.personId ?
				{
					userWorkPermitId: this.state.userPermitId,
					permitId: this.props.permitId,
					userId: this.props.personId,
					permitCode: this.state.permitCode,
					dateGranted: this.state.dateGranted,
					dateExpires: this.state.dateExpires,
					isRequired: this.state.permitIsRequired,
					isAccepted: this.state.userPermitId ? this.state.permitIsAccepted : true
				}
				:
				{
					userWorkPermitId: this.state.userPermitId,
					permitId: this.props.permitId,
					permitCode: this.state.permitCode,
					dateGranted: this.state.dateGranted,
					dateExpires: this.state.dateExpires,
				}



			api({
				method: 'post',
				url: url,
				data: params
			}).then((response) => {
				if (this.state.file && this.state.file.new) {
					this.saveFile(response.id);
				}
				this.props.onClose();
				this.setState({ file: null })
			}).catch((error) => {
				console.log(error);
				this.setState({ file: null })
			});
		}
		else {
			//console.log("Työluvan ei havaittu muutoksia ja ei tallennettu");
			this.clickCancelButton();
		}
		

	}

	deletePermit() {
		const url = this.props.personId ? 'usermanagement/permits/deletePermit' : 'currentUser/deletepermit';
		api({
			method: 'delete',
			url: url,
			params: {
				userWorkPermitId: this.state.userPermitId
			}
		}).then((response) => {
			if (this.state.file && this.state.file.new) {
				this.removeFile(this.state.file.id)
			}
			this.props.onClose();
		}).catch((error) => {
			console.log(error);
		});

	}

	saveFile(permitId) {
		const url = this.props.personId ? 'usermanagement/permits/saveFile' : 'currentUser/savepermitfile';
		const params = this.props.personId ?
			{
				file: this.state.file,
				permitId: permitId,
				personId: this.props.personId
			}
			:
			{
				file: this.state.file,
				permitId: permitId,
			}
		this.setState({ loading: true });
		api({
			method: 'post',
			url: url,
			data: params
		}).then((response) => {
			window.emitter.emit('popper', {
				type: 'success',
				content: <strong>{tr('saved')}</strong>,
			});
			this.setState({ loading: false });
		}).catch((error) => {
			this.setState({ loading: false });
		});
	}

	removeFile(fileId) {
		const url = this.props.personId ? 'usermanagement/permits/deletePermitFile' : 'currentUser/deletepermitfile';
		this.setState({ loading: true });
		api({
			method: 'delete',
			url: url,
			data: {
				id: fileId
			}
		}).then((response) => {
			this.setState({ loading: false });
		}).catch((error) => {
			this.setState({ loading: false });
		});
	}

	clickCancelButton() {
		this.props.onClose();
		this.setState({ file: null});
		if (this.state.file && this.state.file.new) {
			this.removeFile(this.state.file.id)
		}
	}

	clickSaveButton() {
		this.savePermit();
		
	}

	onFileModalClose() {
		this.setState({ filesModalShow: false });
	}

	clickRemoveFile(fileId) {
		this.removeFile(fileId);
		this.setState({ file: null });
	}

	onFileUpload(file) {
		if (this.state.file && this.state.file.new) {
			this.removeFile(this.state.file.id)
		}
		Object.assign(file, { new: true });
		this.setState({ filesModalShow: false, file: file, fileId: file.id, fileName: file.original_name, edited:true });
		
	}

	handleChange(e) {
		if (e.target.name === "dateGranted" && this.state.workPermitDefaultLength>0) {
			this.setState({ [e.target.name]: e.target.value, dateExpires: moment(e.target.value).add(this.state.workPermitDefaultLength,"years") }, () => {
				this.setValidationState();
			});
		}
		else {
			this.setState({ [e.target.name]: e.target.value }, () => {
				this.setValidationState();
			});
		}
		
		
	}

	handleIsRequiredChange() {
		this.setState({ permitIsRequired: !this.state.permitIsRequired, permitIsAccepted: false }, () => {
			this.setValidationState();
		});
	}

	handleIsAcceptedChange() {
		this.setState({ permitIsAccepted: !this.state.permitIsAccepted }, () => {
			this.setValidationState();
		});
	}

	renderForm() {
		const form =
			<div className="padding">
				<ApInputStack>
					<ApInput
						type="text"
						id="permitCode"
						name="permitCode"
						label={tr('permit_code')}
						autoComplete="off"
						onChange={this.handleChange}
						value={this.state.permitCode}
						loading={this.state.loading}
						disabled={true}
					/>
					<ApInput
						type="dateSafe"
						id="dateGranted"
						name="dateGranted"
						label={tr('granted')}
						autoComplete="off"
						onChange={this.handleChange}
						value={this.state.dateGranted}
						loading={this.state.loading}
						disabled={true}
					/>
					<ApInput
						type="dateSafe"
						id="dateExpires"
						name="dateExpires"
						label={tr('expires')}
						autoComplete="off"
						onChange={this.handleChange}
						value={this.state.dateExpires}
						loading={this.state.loading}
						disabled={true}
					/>
				</ApInputStack>
				
					{/*
					<ApInputStack>
					{this.props.personId &&
					
							<ApAddon width="150px" labelFor="permitIsRequired">
								<ApSwitch
									small
									inline
									id="permitIsRequired"
									on={keyExists(this.state, "permitIsRequired", true)}
									onChange={(e) => this.handleIsRequiredChange()}
									disabled={this.state.loading}
								/>
								<span>{tr('required')}</span>
							</ApAddon>
						}
					{this.props.personId && this.state.userPermitId &&
						
							
								<ApAddon width="150px" labelFor="permitIsAccepted">
									<ApSwitch
										small
										inline
										id="permitIsAccepted"
										on={keyExists(this.state, "permitIsAccepted", true)}
										onChange={(e) => this.handleIsAcceptedChange()}
										disabled={this.state.loading}
									/>
									<span>{tr('accepted')}</span>
								</ApAddon>
							
						
					}
					</ApInputStack>
				*/}
				
				
			</div>;

		return form;
	}

	setValidationState() {
		let state = false;
		if ((this.props.personId) || (this.state.permitCode && this.state.dateGranted && this.state.dateExpires))
			state = true;

		this.setState({ formValid: state });
	}
//<SvgIcon icon="file" type="solid" /> {this.state.file.name ? this.state.file.name : this.state.file.original_name}
	render() {
		const form = this.renderForm();
		const file = this.state.file ?
			<table>
				<tbody>
				<tr>
				
				<td>
				<File fileId={this.state.file.id} name={this.state.file.original_name} type="image" style={{ width: "500px",height: "300px", objectFit: "contain" }} >
						
					
					</File>
				</td>
				<td className="smallButton">
					
					
				</td>
				</tr>
				<tr>
					<td>
					<SvgIcon icon="file" type="solid" /> {this.state.file.name ? this.state.file.name : this.state.file.original_name}
					</td>
						<td>
							{/*
					<ApTooltip block text="Poista">
						<div className="apSimpleButton" onClick={() => { this.clickRemoveFile(this.state.file.id) }}>
							<SvgIcon icon="trash" type="solid" />
						</div>
					</ApTooltip>
					*/}
					</td>
				</tr>
				</tbody>
			</table>
			
				
			
			:
			null
		
		if (this.state.showUserInfo != null) {
			let data = this.state.showUserInfo;
			let permitRows = [];
			for (let permit of data.work_permits) {
				permitRows.push(
					<Row style={{ borderStyle: "solid", margin: "4px"}}>
						<Col sm={3} >
							<strong>{tr("work_permit_name")+": "+permit.name}</strong><br />
							<strong>{tr("permit_code")+": " +permit.permit_code}</strong><br />
							<strong>{tr("expiring:")+" " +moment(permit.date_expires).format("DD.MM.YYYY")}</strong><br />
							<strong>{permit.is_required ? (tr("permit_required")+"!"):null}</strong><br />
						</Col>
						<Col sm={6}>
							{permit.file &&
								<File fileId={permit.file.file_id} 
									type="image" style={{ width: "150px", height: "150px", objectFit: "contain" }} >


								</File>}
						</Col>
					</Row>
					
				);
				
			}
			return (

				<div id="userOverallDetails">
					<ApModal
						show={this.props.show}
						className="medium"
						handleClose={this.clickCancelButton}
						header={
							<div className="padding-small">
								<h4>{this.state.modalTitle}</h4>

							</div>
						}
						body={
							<div id="permitList">
								<Grid fluid={true} className="padding">
									
									<Row>
										<Col sm={6}>
											{data.person_detail.image &&
												<File fileId={data.person_detail.image.id} name={data.person_detail.image.original_name}
													type="image" style={{ width: "250px", height: "250px", objectFit: "contain"  }} >


												</File>}
										</Col>
										<Col sm={3}>
											<strong>{tr("name")+": " + (data.person_detail.first_name ? data.person_detail.first_name : "") +
												(data.person_detail.midle_names ? (" " + data.person_detail.midle_names) : "") + (data.person_detail.last_name ? (" " + data.person_detail.last_name) : "")}</strong><br />
											<strong>{tr("tax_number")+": " + (data.contract_detail.taxnumber ? data.contract_detail.taxnumber:"--")}</strong><br />
											
										</Col>
									</Row>
									{permitRows}
								</Grid>
								
							</div>
						}
						footer={
							<div className="padding" style={{ display: 'flex', flexDirection: 'row' }}>
								<ApButton className="cancel" onClick={() => { this.clickCancelButton(null) }}>
									<SvgIcon icon="times" type="solid" />
									{tr('back')}
								</ApButton>
								
							</div>
						}
					/>
					{/* 
				<Files
					show={this.state.filesModalShow}
					onClose={this.onFileModalClose}
					onUpload={this.onFileUpload}
				/>
			*/}
					<ApConfirm
						show={this.state.showRemoveConfirmDialog}
						header={tr('confirm_delete')}
						body={`${tr('delete_sure')}?`}
						onConfirm={() => { this.deletePermit() }}
						onClose={() => { this.setState({ showRemoveConfirmDialog: false }) }}
					/>

				</div>
			);
		}
		else {
			return (

				<div id="userPermitModal">
					<ApModal
						show={this.props.show}
						className="medium"
						handleClose={this.clickCancelButton}
						header={
							<div className="padding-small">
								<h4>{this.state.modalTitle}</h4>

							</div>
						}
						body={
							<div>
								{form}
								<div>
									{/* 
								<small style={{ marginLeft: '16px', fontSize: '1em' }}> {tr('add_replace_permit_file')} </small>
								<Row>
									<Col sm={2}>
										<div
											className="fileButtonContainer"
											style={{ marginLeft: '16px' }}
											onClick={() => { this.setState({ filesModalShow: true }) }}>
											<ApTooltip text={tr('permit_file')}>
												<ApButton className="fileButton" color="white">
													<SvgIcon icon="file-alt" type="solid" />
												</ApButton>
											</ApTooltip>
										</div>
									</Col>
								</Row>
								*/}
									<div className="padding">
										<table className="filesTable">
											<tbody>
												{file}
											</tbody>
										</table>
									</div>
									{this.state.daysToExpiry !== null &&
										<Row>
											<div className="apInfo small" style={{ marginLeft: '32px', marginTop: '16px' }}>
												<SvgIcon icon="info-circle" type="solid" />
												{tr('permit_is_valid_for_days', [this.state.daysToExpiry])}
											</div>
										</Row>
									}

								</div>
							</div>
						}
						footer={
							<div className="padding" style={{ display: 'flex', flexDirection: 'row' }}>
								<ApButton className="cancel" onClick={() => { this.clickCancelButton(null) }}>
									<SvgIcon icon="times" type="solid" />
									{tr('back')}
								</ApButton>
								{/*
							{(this.state.userPermitId && (this.props.personId || !this.state.permitIsRequired)) &&
								<ApButton className="remove" color="red" style={{ marginLeft: 20 }} onClick={() => { this.setState({ showRemoveConfirmDialog: true }) }}>
									<SvgIcon icon="trash" type="solid" />
									{tr('delete')}
								</ApButton>
							}

							<ApButton
								className={"save" + (this.state.formValid && !this.state.loading && this.isEdited()? " highlight" : "")}
								color="blue"
								onClick={this.clickSaveButton}
								loading={this.state.loading}
								disabled={!this.state.formValid}
								style={{ marginLeft: 'auto' }}
							>
								<SvgIcon icon="save" type="solid" />
								{tr('save')}
							</ApButton>
							 */}
							</div>
						}
					/>
					{/* 
				<Files
					show={this.state.filesModalShow}
					onClose={this.onFileModalClose}
					onUpload={this.onFileUpload}
				/>
			*/}
					<ApConfirm
						show={this.state.showRemoveConfirmDialog}
						header={tr('confirm_delete')}
						body={`${tr('delete_sure')}?`}
						onConfirm={() => { this.deletePermit() }}
						onClose={() => { this.setState({ showRemoveConfirmDialog: false }) }}
					/>

				</div>
			);
		}
		

	}

}
